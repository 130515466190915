import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./App.css";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import trash from "./images/trash.svg";
import moment from "moment";
import TemplateView from "./components/TemplateView_new";
import TemplateEditView from "./components/TemplateEditView_new";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import { RadioGroup, Radio } from "react-radio-group";
import Spinner from "./spinner";
import { fileExtension } from "./components/TemplateEditView_new";
import back from "./images/back-ico.svg";
import {
  setTemplateQuestionAnswer,
  getTemplateQuestionAnswer,
  updateTemplateQuestionAnswer,
  TemplateFileUploadAction,
  RemoveFileAction,
} from "./util/actions/allactions";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Close from "@material-ui/icons/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";
import CreatableSelect from "react-select/creatable";
import swal from "sweetalert";

const CreateTemplate = () => {
  const navigate = useNavigate();
  toast.configure();
  var i = 1;
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const [values, setValues] = useState({ name: "", answers: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({ name: "" });
  const [show, setShow] = useState(false);
  const [file, setfile] = useState("");
  const [questionarr, setQuestionarr] = useState([]);
  const [visible, setVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // const [text, settext] = useState([
  //   {
  //     question: "string",
  //     type: "text",
  //     id: 1,
  //     value: "",
  //   },
  // ]);
  // const [radio, setradio] = useState([
  //   {
  //     type: "radio",
  //     id: 1,
  //     value: "",
  //   },
  // ]);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const clearform = () => {
  //   setQuestionarr([
  //     ...questionarr,
  //     { name: "", content: { question: " ", type: "" } },
  //   ]);
  // };
  // console.log("arroutside", questionarr);
  const [QuestionsShow, setQuestionShow] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showMutiselect, setShowMultiselect] = useState(false);
  const [showDropDown, setshowDropDown] = useState(false);
  const [showRadio, setshowRadio] = useState(false);
  const [question, setQuestion] = useState("");
  const [multiselecterOption, setMultiselecterOption] = useState([]);
  const [dropdownOption, setDropDownOption] = useState([]);
  const [radioOptions, setradioOptions] = useState([]);
  const [questionAnswerArray, setQuestionAnswerArray] = useState([]);
  const [statusChange, setStatusChange] = useState(false);
  const [textAnswer, setTextAnswer] = useState("");
  const [iseditingField, setIseditingField] = useState(false);
  const [editableValue, setEditableValue] = useState({});
  const [templatePreview, setTemplatePreview] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [error, setError] = useState({ sectionRequiredError: "", heading: "" });
  const [selectedSection, setSelectedSection] = useState({
    value: -1,
    label: "Please Select Section",
  });
  const [showSubHeading, setShowSubHeading] = useState(false);
  const [issubheadingEdit, setIssubheadingEdit] = useState(false);
  const [subheading, setSubheading] = useState("");
  const [showAddHeading, setshowAddHeading] = useState(false);
  const [heading, setheading] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [subheadingList, setSubheadingList] = useState([]);
  const [isSectionEditing, setIsSectionEditing] = useState(false);
  const [selectedSubheading, setSelectedSubheading] = useState({
    value: "",
    label: "Please Select Subheading",
  });
  const [editableFIeldsIndex, setEditableFIeldsIndex] = useState({
    parentIndex: -1,
    subheadingIndex: -1,
    itemIndex: -1,
  });
  const [data, setData] = useState({
    storeDate: {
      type: "date",
      answers: [
        {
          value: moment(new Date()).format("YYYY/MM/DD"),
          label: moment(new Date()).format("YYYY/MM/DD"),
        },
      ],
    },

    multiSelectData: {
      type: "multiselect",
      answers: [],
      options: [],
    },
    dropDownData: {
      type: "dropdown",
      answers: [{ label: "", value: "" }],
      options: [],
    },
    radioData: {
      type: "radio-button",
      answers: [{ label: "", value: "" }],
      options: [],
    },
    fileData: {
      type: "file-input",
      answers: [{ label: "", value: "" }],
      options: [],
    },
  });

  const [editorState, setEditorState] = useState("");
  const [attachFileLink, setAttachFileLink] = useState("");
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [confirmIsDelete, setConfirmIsDelete] = useState(false);
  const [confirmModelMessage, setConfirmModelMessage] = useState("");
  const { id } = useParams();
  const { fundId } = useParams();
  useEffect(async () => {
    try {
      if (id) {
        setIsLoading(true);
        const templateData = await getTemplateQuestionAnswer(id);
        let data = templateData?.data?.data?.template?.content;
        if (data) {
          data && setQuestionAnswerArray(data?.QuestionAns);
          setSectionList(data?.sectionList);
          setSubheadingList(data?.subheadingList);
          setValues({
            ...values,
            name: templateData?.data?.data?.template?.name,
          });
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
    }
  }, []);
  const selectDate = () => {
    setShowDate(!showDate);
  };
  console.log("attachmentlink", attachFileLink?.path);
  const removefile = async (e) => {
    try {
      setIsLoading(true);
      let response = await RemoveFileAction({
        attachment_path: attachFileLink?.path,
      });
      if (response.status == "success") {
        setVisible(false);
        setIsLoading(false);

        // console.log("Attachment link after response",attachFileLink);
        setAttachFileLink("");
        setData({
          ...data,
          fileData: {
            type: "file-input",
            answers: [{ label: response, value: response }],
            options: [],
          },
        });
      }
    } catch (e) {
      setIsLoading(false);
      // console.log("catchhhhhhhh")
    }
    //  return e.target.value = null;
  };

  // File upload is panding
  const setFileData = async (e) => {
    try {
      const data = new FormData();
      const imagedata = e.target.files[0];
      data.append("attachment", imagedata);
      setIsLoading(true);

      let fileUploadRes = await TemplateFileUploadAction(data);
      if (fileUploadRes) {
        setVisible(true);
        setIsLoading(false);
        setData({
          ...data,
          fileData: {
            type: "file-input",
            answers: [{ label: fileUploadRes, value: fileUploadRes }],
            options: [],
          },
        });
        setfile(data?.path);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message?.attachment[0]);
    }
  };

  const radioOptionChange = (value) => {
    setData({
      ...data,
      radioData: {
        type: "radio-button",
        answers: [{ value: value, label: value }],
        options: data?.radioData?.options,
      },
    });
  };

  const UpdatedQuestionAnsArray = () => {
    const qAArray = {
      QuestionAns: questionAnswerArray,
      sectionList: sectionList,
      subheadingList: subheadingList,
    };
    // questionAnswerArray.push({sectionList:sectionList})
    // questionAnswerArray.push({subheadingList:subheadingList})
    // const UpdateQuestionAndAnswer=questionAnswerArray
    return qAArray;
  };

  const saveFormData = async () => {
    try {
      if (values.name.trim() == "") {
        setErrors({ ...errors, name: "Please Enter Template name" });
        return false;
      }
      setIsLoading(true);
      const formDataUpdate = {
        status: 0,
        name: values.name,
        // content: questionAnswerArray,
        content: UpdatedQuestionAnsArray(),
      };
      const createTempRes = await setTemplateQuestionAnswer(
        formDataUpdate,
        fundId
      );
      if (createTempRes) {
        setIsLoading(false);
        toast.success("Template Created Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        !fundId
          ? navigate("/templates")
          : navigate(`/fund-management-details/${fundId}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err, "create template error");
    }
  };

  const PublishTemplate = async (e) => {
    //  setButtonDisabled(true);
    try {
      if (values.name.trim() == "") {
        setErrors({ ...errors, name: "Please Enter Template name" });
        return false;
      }
      setIsLoading(true);

      const formDataUpdate = {
        status: 1,
        name: values.name,
        // content: questionAnswerArray,
        content: UpdatedQuestionAnsArray(),
      };
      const createTempRes = await setTemplateQuestionAnswer(
        formDataUpdate,
        fundId
      );
      if (createTempRes) {
        setIsLoading(false);

        toast.success("Template Published Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        !fundId
          ? navigate("/templates")
          : navigate(`/fund-management-details/${fundId}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err, "create template error");
    }
  };
  const UpdateFormData = async () => {
    try {
      if (values.name.trim() == "") {
        setErrors({ ...errors, name: "Please Enter Template name" });
        return false;
      }
      setIsLoading(true);
      const formDataUpdate = {
        status: 0,
        name: values.name,
        // content: questionAnswerArray,
        content: UpdatedQuestionAnsArray(),
      };
      const createTempRes = await updateTemplateQuestionAnswer(
        formDataUpdate,
        id
      );
      if (createTempRes) {
        setIsLoading(false);
        toast.success("Template Updated Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        !fundId
          ? navigate("/templates")
          : navigate(`/fund-management-details/${fundId}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err, "create template error");
    }
  };

  const PublishUpdatedTemplate = async () => {
    try {
      if (values.name.trim() == "") {
        setErrors({ ...errors, name: "Please Enter Template name" });
        return false;
      }
      setIsLoading(true);
      const formDataUpdate = {
        status: 1,
        name: values.name,
        // content: questionAnswerArray,
        content: UpdatedQuestionAnsArray(),
      };
      const createTempRes = await updateTemplateQuestionAnswer(
        formDataUpdate,
        id
      );
      if (createTempRes) {
        setIsLoading(false);
        toast.success("Template Published Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        !fundId
          ? navigate("/templates")
          : navigate(`/fund-management-details/${fundId}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err, "create template error");
    }
  };

  const resetFieldsValue = () => {
    setQuestion("");
    setStatusChange(!statusChange);
    setQuestionShow(false);
    setShowDate(false);
    setShowMultiselect(false);
    setshowDropDown(false);
    setshowRadio(false);
    setMultiselecterOption([]);
    setDropDownOption([]);
    setradioOptions([]);
    setTextAnswer("");
    setAttachment(false);
    setshowAddHeading(false);
    setShowSubHeading(false);
    setheading("");
    setIssubheadingEdit(false);
    setShowSubHeading(false);
    setSubheading("");
    setEditableValue({});
    setIseditingField(false);
    setIsSectionEditing(false);
    setAttachFileLink("");
    setEditorState("");
    setSelectedSubheading({
      value: "",
      label: "Please Select Subheading",
    });
    setSelectedSection({ value: -1, label: "Please Select Section" });
    setError({ sectionRequiredError: "" });
    setData({
      storeDate: {
        type: "date",
        answers: [{ value: new Date(), label: new Date() }],
      },
      multiSelectData: {
        type: "multiselect",
        answers: [],
        options: [],
      },
      dropDownData: {
        type: "dropdown",
        answers: [{ label: "", value: "" }],
        options: [],
      },
      radioData: {
        type: "radio-button",
        answers: [{ label: "", value: "" }],
        options: [],
      },
      fileData: {
        type: "file-input",
        answers: [{ label: "", value: "" }],
        options: [],
      },
    });

    setEditableFIeldsIndex({
      parentIndex: -1,
      subheadingIndex: -1,
      itemIndex: -1,
    });
  };
  const createQuestionFunction = (selectedAnswer, resetFieldsValuecallBack) => {
    if (selectedSection?.value == -1) {
      setError({ ...error, sectionRequiredError: "Please select Section" });
      return false;
    }

    // Update values
    if (
      iseditingField &&
      editableValue !== {} &&
      editableFIeldsIndex.parentIndex !== -1
    ) {
      let findEditedSection = questionAnswerArray.findIndex(
        (item) => item.section == selectedSection.label
      );
      let modalData = {
        question: question,
        has_attachment_field: true,
        show: true,
        ...selectedAnswer,
      };
      let editedItemPreviousSection =
        questionAnswerArray[editableFIeldsIndex.parentIndex].section;
      let editedItemPreviousSubheading =
        questionAnswerArray[editableFIeldsIndex.parentIndex].data[
          editableFIeldsIndex.subheadingIndex
        ].sub_heading;
      // if section and subheading is same

      console.log(
        editedItemPreviousSubheading,
        selectedSubheading,
        "selectedSubheadingselectedSubheadingselectedSubheading"
      );

      if (
        editedItemPreviousSection == selectedSection.value &&
        editedItemPreviousSubheading.value == selectedSubheading.value
      ) {
        questionAnswerArray[editableFIeldsIndex.parentIndex].data[
          editableFIeldsIndex.subheadingIndex
        ].questions[editableFIeldsIndex.itemIndex] = {
          ...editableValue,
          ...selectedAnswer,
          question: question,
        };

        resetFieldsValue();
        setButtonDisabled(false);
        // return true
      } else {
        if (findEditedSection == -1) {
          // if not find any matching section

          questionAnswerArray.push({
            parentIndex: questionAnswerArray.length,
            section: selectedSection.value,
            data: [
              {
                sub_heading: selectedSubheading,
                questions: [modalData],
              },
            ],
          });

          setButtonDisabled(false);
        } else {
          // if find matching section

          let findsubheading = questionAnswerArray[
            findEditedSection
          ].data.findIndex(
            (item) => item.sub_heading.value == selectedSubheading.value
          );

          if (findsubheading != -1) {
            questionAnswerArray[findEditedSection].data[
              findsubheading
            ].questions.push(modalData);
            setButtonDisabled(false);
          } else {
            // if not fing maching subheading
            questionAnswerArray[findEditedSection].data.push({
              sub_heading: selectedSubheading,
              questions: [modalData],
            });
            setButtonDisabled(false);
          }
        }
        // remove Question from old section
        questionAnswerArray[editableFIeldsIndex.parentIndex].data[
          editableFIeldsIndex.subheadingIndex
        ].questions.splice(editableFIeldsIndex.itemIndex, 1);
        resetFieldsValue();
      }
    } else {
      // create Q & A

      let findHeading = questionAnswerArray.find(
        (item) =>
          item?.section == selectedSection.value || selectedSection.value == ""
      );

      let modalData = {
        question: question,
        has_attachment_field: true,
        show: true,
        ...selectedAnswer,
      };

      if (!findHeading) {
        questionAnswerArray.push({
          parentIndex: questionAnswerArray.length,
          section: selectedSection.value,
          data: [
            {
              sub_heading: selectedSubheading,
              questions: [modalData],
            },
          ],
        });
      } else {
        // add Q & A on section based
        var findHeadingIndex =
          questionAnswerArray.length > 0 &&
          questionAnswerArray.findIndex(
            (item) => item?.section == selectedSection.value
          );
        let findSubheading = questionAnswerArray[
          findHeadingIndex
        ]?.data.findIndex(
          (item) => item.sub_heading.value == selectedSubheading.value
        );

        if (findSubheading >= 0) {
          questionAnswerArray[findHeadingIndex]?.data[
            findSubheading
          ]?.questions?.push(modalData);
        } else {
          questionAnswerArray[findHeadingIndex]?.data?.push({
            sub_heading: selectedSubheading,
            questions: [modalData],
          });
        }

        setStatusChange(!statusChange);
      }
    }
    resetFieldsValuecallBack();
  };

  const deleteQuestion = (parentIndex, subheadingIndex, itemIndex, item) => {
    let deleteOrNot = window.confirm("Are you sure want to delete");
    if (deleteOrNot) {
      questionAnswerArray[parentIndex].data[subheadingIndex].questions.splice(
        itemIndex,
        1
      );
      resetFieldsValue();
    }
  };

  const EditQuestionAnswer = (editItem, sectionName, subheadingName) => {
    // setButtonDisabled(false);
    console.log("editItem", editItem);
    setEditableValue(editItem);
    setIseditingField(true);
    if (editItem.type == "text") {
      setEditorState(editItem.answers[0]?.label);
      setQuestion(editItem.question);
      setSelectedSection(sectionName);
      setQuestionShow(true);
      setSelectedSubheading(subheadingName);
      setAttachFileLink(editItem?.attachFile);
    }
    if (editItem.type == "date") {
      setQuestion(editItem.question);
      setData({ ...data, storeDate: { ...editItem } });
      setSelectedSection(sectionName);
      setShowDate(true);
      setSelectedSubheading(subheadingName);
    }
    if (editItem.type == "multiselect") {
      setQuestion(editItem.question);
      setMultiselecterOption(editItem.options);
      setData({ ...data, multiSelectData: { ...editItem } });
      setSelectedSection(sectionName);
      setShowMultiselect(true);
      setSelectedSubheading(subheadingName);
    }
    if (editItem.type == "dropdown") {
      setQuestion(editItem.question);
      setDropDownOption(editItem.options);
      setData({ ...data, dropDownData: { ...editItem } });
      setSelectedSection(sectionName);
      setshowDropDown(true);
      setSelectedSubheading(subheadingName);
    }
    if (editItem.type == "radio-button") {
      setQuestion(editItem.question);
      setradioOptions(editItem.options);
      setData({ ...data, radioData: { ...editItem } });
      setSelectedSection(sectionName);
      setshowRadio(true);
      setSelectedSubheading(subheadingName);
    }
    if (editItem.type == "file-input") {
      setQuestion(editItem.question);
      setData({ ...data, fileData: { ...editItem } });
      setSelectedSection(sectionName);
      setAttachment(true);
      setSelectedSubheading(subheadingName);
    }
    // dropdown
    // multiselecterOption
  };

  const OnHideModel = () => {
    setQuestionShow(false);
    setShowDate(false);
    setShowMultiselect(false);
    setshowDropDown(false);
    setshowRadio(false);
    setAttachment(false);
    setShowSubHeading(false);
    setshowAddHeading(false);
    resetFieldsValue();
    setErrors({ ...errors, attachment: null });
  };

  const ShowStatusChange = (
    parentIndex,
    subheadingIndex,
    quesAnsIndex,
    switchStatus
  ) => {
    let selectedQa =
      questionAnswerArray[parentIndex]?.data[subheadingIndex]?.questions[
        quesAnsIndex
      ];

    selectedQa.show = switchStatus;
    setStatusChange(!statusChange);
  };

  /* Enter Press form Submit */
  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      addHeadingFinction();

      e.preventDefault();
    }
  };

  /* Enter Subheading Press form Submit */
  const handleSubheadingKeypress = (e) => {
    if (e.key == "Enter") {
      addSubheadingFunction();
      e.preventDefault();
    }
  };

  const addHeadingFinction = () => {
    if (heading.trim() == "") {
      setError({ ...error, heading: "Please enter Section" });
      return false;
    }
    if (isSectionEditing) {
      setButtonDisabled(false);
      questionAnswerArray[editableFIeldsIndex.parentIndex].section =
        heading.trim();
      let isHeadingExist = sectionList.find(
        (item) => item.label.trim() == heading.trim()
      );
      if (!isHeadingExist) {
        heading.trim() !== "" &&
          sectionList.push({ label: heading.trim(), value: heading.trim() });
      }
      resetFieldsValue();
      return true;
    }
    let isHeadingExist =
      sectionList?.length > 0 &&
      sectionList?.find((item) => item.value == heading.trim());
    if (isHeadingExist) {
      swal({
        title: "Error",
        text: "Section already exist",
        type: "error",
        confirmButtonText: "OK",
      });

      return false;
    }
    sectionList?.push({ label: heading.trim(), value: heading.trim() });
    swal({
      title: "Success",
      text: "Section added successfully",
      type: "success",
      confirmButtonText: "OK",
    });
    resetFieldsValue();
  };

  const addSubheadingFunction = () => {
    let subheadingValue = {
      value: subheading,
      label: subheading,
    };
    subheadingList?.push(subheadingValue);
    swal({
      title: "Success",
      text: "SubHeading added successfully",
      type: "success",
      confirmButtonText: "OK",
    });
    resetFieldsValue();
  };

  const deleteSection = (e, selectedSection, index) => {
    e.preventDefault();
    const isSectionUsing = questionAnswerArray?.findIndex(
      (item, index) => item.section == selectedSection.value
    );

    if (isSectionUsing == -1) {
      if (window.confirm("Are you sure want to delete this section")) {
        sectionList?.splice(index, 1);
        // resetFieldsValue()
        setStatusChange(!statusChange);
        return true;
      }
    }

    alert("You can't delete this section because you are using it");
  };

  const editSubheading = (parentIndex, subheadingIndex, subHeading) => {
    setIssubheadingEdit(true);
    setSubheading(subHeading.value);
    setShowSubHeading(true);
    setEditableFIeldsIndex({
      parentIndex: parentIndex,
      subheadingIndex: subheadingIndex,
    });
  };

  const deleteSubheading = (parentIndex, subheadingIndex) => {
    let sectionLength =
      questionAnswerArray[parentIndex].data[subheadingIndex].questions.length;
    if (sectionLength == 0) {
      if (window.confirm("Are you sure want to delete subheading")) {
        questionAnswerArray[parentIndex].data.splice(subheadingIndex, 1);
        resetFieldsValue();
      }
    }
  };

  const editSection = (oldSection) => {
    // heading is for section
    setshowAddHeading(true);
    setheading(oldSection);
    setIsSectionEditing(true);
  };

  const setEditSubheadingValue = () => {
    let editSubheading =
      questionAnswerArray[editableFIeldsIndex.parentIndex].data[
        editableFIeldsIndex.subheadingIndex
      ];
    editSubheading.sub_heading = { value: subheading, label: subheading };
    // if user edit subheading
    let isSubheadingExist = subheadingList.find(
      (item) => item.value == subheading
    );
    if (!isSubheadingExist) {
      subheadingList.push({ value: subheading, label: subheading });
    }
    setButtonDisabled(false);
    resetFieldsValue();
  };

  const deleteSectionfronForm = (parentIndex, section) => {
    let checkIsSectionDataEmpty = questionAnswerArray[parentIndex].data.length;
    if (checkIsSectionDataEmpty == 0) {
      if (window.confirm("Are you sure want  to delete This Section")) {
        questionAnswerArray.splice(parentIndex, 1);
        alert("Section Removed successfully");
      }
    }

    resetFieldsValue();
  };

  // add attachment with Question and answer
  const AddAttachmentWithQA = async (e) => {
    try {
      setErrors({ ...errors, attachment: null });
      const data = new FormData();
      const imagedata = e.target.files[0];
      data.append("attachment", imagedata);
      setIsLoading(true);

      let fileUploadRes = await TemplateFileUploadAction(data);
      if (fileUploadRes) {
        setAttachFileLink(fileUploadRes);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during login";
      setErrors({
        ...errors,
        attachment: message?.attachment[0],
      });
    }
  };

  const createTemplateBack = (e) => {
    e.preventDefault();
    setConfirmModelMessage(
      "Changes will not be saved. Do you want to continue?"
    );
    setShowConfirmModel(true);
    if (confirmIsDelete) {
      navigate("/templates");
    }
  };
  console.log(questionAnswerArray, "questionAnswerArrayquestionAnswerArray");
  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}
          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content ds-content-two">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar import-temp-top">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
            </div>
            <RightHeader setIsLoading={setIsLoading} />
            <RightMobileHeader setIsLoading={setIsLoading} />
          </div>
          <div className="ds-heading">
            <h2>
              {UserRole == "super-admin" ? (
                <a
                  href="#javascript:void(0)"
                  className="back-btn"
                  onClick={(e) => createTemplateBack(e)}
                >
                  <img src={back} />
                </a>
              ) : (
                <a
                  href="javascript:void(0)"
                  onClick={(e) => navigate(`/fund-management-list`)}
                  className="back-btn"
                >
                  <img src={back} />
                </a>
              )}
              Create New Template
            </h2>
            <div className="buttons-grp">
              <a
                onClick={(e) =>
                  !fundId
                    ? navigate("/templates")
                    : navigate(`/fund-management-details/${fundId}`)
                }
                href="#javascript:void(0)"
                className="discard-btn"
              >
                Cancel
              </a>

              {!id ? (
                <>
                  <a
                    onClick={(e) => saveFormData(e)}
                    href="javascript:void(0)"
                    className="orange-border-btn ml-10"
                  >
                    Save as draft
                  </a>

                  {
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => setTemplatePreview(!templatePreview)}
                      className="blue-border-btn ml-10"
                    >
                      {!templatePreview ? "Public View" : "Edit View"}
                    </a>
                  }
                  <a
                    onClick={(e) => PublishTemplate(e)}
                    href="javascript:void(0)"
                    class="blue-btn-fill ml-10 disabled"
                    role="button"
                    disabled={buttonDisabled}
                  >
                    Publish
                  </a>
                </>
              ) : (
                <>
                  <a
                    onClick={(e) => UpdateFormData(e)}
                    href="javascript:void(0)"
                    className="orange-border-btn ml-10"
                  >
                    Update as draft
                  </a>

                  {
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => setTemplatePreview(!templatePreview)}
                      className="blue-border-btn ml-10"
                    >
                      {!templatePreview ? "Public View" : "Edit Option"}
                    </a>
                  }
                  <a
                    onClick={(e) => PublishUpdatedTemplate(e)}
                    href="javascript:void(0)"
                    class="blue-btn-fill ml-10"
                  >
                    Publish
                  </a>
                  {/* <Button className="blue-btn-fill ml-10" onClick={(e) => PublishUpdatedTemplate(e)}  disabled={buttonDisabled}> Publish New</Button> */}
                </>
              )}
            </div>
          </div>
          <div className="dashboard-box">
            <div className="create-template-bx">
              <div className="create-editor-bar">
                <button
                  className="white-btn"
                  onClick={(e) =>
                    sectionList?.length > 0
                      ? setQuestionShow(true)
                      : setshowAddHeading(true)
                  }
                >
                  Text
                </button>
                <button
                  className="white-btn"
                  onClick={() =>
                    sectionList?.length > 0
                      ? selectDate()
                      : setshowAddHeading(true)
                  }
                >
                  Date
                </button>
                <button
                  className="white-btn"
                  onClick={() =>
                    sectionList?.length > 0
                      ? setShowMultiselect(true)
                      : setshowAddHeading(true)
                  }
                >
                  Multiselect
                </button>
                <button
                  className="white-btn"
                  onClick={() =>
                    sectionList?.length > 0
                      ? setshowDropDown(true)
                      : setshowAddHeading(true)
                  }
                >
                  Dropdown
                </button>
                <button
                  className="white-btn"
                  onClick={() =>
                    sectionList?.length > 0
                      ? setshowRadio(true)
                      : setshowAddHeading(true)
                  }
                >
                  Radio button
                </button>
                <button
                  className="white-btn"
                  onClick={() =>
                    sectionList?.length > 0
                      ? setAttachment(true)
                      : setshowAddHeading(true)
                  }
                >
                  Add attachments
                </button>
                <button
                  className="white-btn"
                  onClick={() =>
                    sectionList?.length > 0
                      ? setshowAddHeading(true)
                      : setshowAddHeading(true)
                  }
                >
                  Add Section
                </button>
                <button
                  className="white-btn"
                  onClick={() => setShowSubHeading(true)}
                >
                  Add Sub Heading
                </button>
              </div>

              <div className="create-box-in template-dv">
                <div className="tamplate-name">
                  <input
                    type="text"
                    className="form-control inp-fl"
                    placeholder="Add Template Name"
                    value={values.name}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        name: null,
                      });
                      setValues({ ...values, name: e.target.value });
                      setButtonDisabled(false);
                    }}
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
                {templatePreview ? (
                  <TemplateView
                    questionAnswerArray={questionAnswerArray}
                    templatePreview={templatePreview}
                    setTemplatePreview={setTemplatePreview}
                  />
                ) : (
                  <TemplateEditView
                    questionAnswerArray={questionAnswerArray}
                    deleteQuestion={deleteQuestion}
                    EditQuestionAnswer={EditQuestionAnswer}
                    ShowStatusChange={ShowStatusChange}
                    setEditableFIeldsIndex={setEditableFIeldsIndex}
                    editableFIeldsIndex={editableFIeldsIndex}
                    editSubheading={editSubheading}
                    deleteSubheading={deleteSubheading}
                    editSection={editSection}
                    deleteSectionfronForm={deleteSectionfronForm}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
      </div>
      <ConfirmModel />
      {/* create Questions Model */}
      {/* working */}
      <Modal
        className="create-bx-modal"
        show={QuestionsShow}
        onHide={() => OnHideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Question
              </label>
              <input
                placeholder="Enter Question"
                type="question"
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Answer
              </label>

              <Editor
                value={editorState}
                onEditorChange={(e) => setEditorState(e)}
                init={{
                  // height: 500,
                  // menubar: 'insert',
                  selector: "textarea",
                  // selector: 'textarea#full-featured',
                  plugins: "image imagetools media",
                  a11y_advanced_options: true,
                  // image_title: true,
                  automatic_uploads: true,
                  file_picker_types: 'file image media',
                  // images_file_types: "jpg,svg,webp",
                  // media_dimensions: false,
                  image_advtab: true,
                  ServerSideOnly: true,
                  toolbar:
                    "media" +
                    "insertfile | undo | redo | link image | code " +
                    "bold | italic | backcolor | alignleft | aligncenter | alignright | alignjustify | cut| copy | bullist | numlist | outdent | indent | " +
                    "removeformat | help |visualaid  | underline |superscript| subscript| styles |" +
                    "strikethrough |selectall|removeformat|remove|redo |print |pastetext |paste |outdent|newdocument|lineheight|language|hr|h6|h5|h4|h3|h2| h1 |forecolor|fontsize|fontfamily|blocks ",
                  //  file_browser_callback_types: 'image',
                  //  media_live_embeds: true,
                  /* and here's our custom image picker*/
                  file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                
                    input.onchange = function () {
                      console.log(this.files[0].size, "filesize");
                      let Size = this.files[0].size;
                      var file = this.files[0];
                      if (Size > 2000000) {
                        toast.error("Image size too large, please select a image less than 2MB");
                        return true;
                      }
                      var reader = new FileReader();
                      reader.onload = function () {
                        var id = "blobid" + new Date().getTime();
                        var blobCache =
                          window.tinymce.activeEditor?.editorUpload?.blobCache;
                        var base64 = reader.result.split(",")[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        /* call the callback and populate the Title field with the file name */
                        cb(blobInfo.blobUri(), { title: file.name });
                      };
                      reader.readAsDataURL(file);
                    };

                    input.click();
                  },
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />

              {/* <input
                placeholder="Enter Question"
                type="question"
                value={textAnswer}
                onChange={(e) => {
                  setTextAnswer(e.target.value);
                }}
              /> */}

              <AttachedFile />

              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Section
              </label>
              <SelectSection />
              <SelectSubheading />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => OnHideModel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            onClick={(e) =>
              createQuestionFunction(
                {
                  type: "text",
                  answers: [{ value: editorState, label: editorState }],
                  options: [],
                  attachFile: attachFileLink,
                },
                resetFieldsValue
              )
            }
          >
            {/* {!iseditingField?"Submit":"Update"} */}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Date selecter model */}

      <Modal
        show={showDate}
        onHide={() => OnHideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Enter Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Question
              </label>
              <input
                placeholder="Enter Question"
                type="question"
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Answer
              </label>

              <DatePicker
                selected={
                  data?.storeDate?.answers[0]?.value
                    ? new Date(data?.storeDate?.answers[0]?.value)
                    : ""
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    storeDate: {
                      type: "date",
                      answers: [{ value: e, label: e }],
                      options: [],
                    },
                  });
                }}
                dateFormat="yyyy/MM/dd"
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Section
              </label>
              <SelectSection />
              <SelectSubheading />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => OnHideModel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            // onClick={(e) => questionAnswerArray.length>0 && questionAnswerArray[0].push(data.storeDate)}
            onClick={(e) =>
              createQuestionFunction(data.storeDate, resetFieldsValue)
            }
            value
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Multiselect */}
      <Modal
        show={showMutiselect}
        onHide={() => OnHideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title> Enter Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Question
              </label>
              <input
                placeholder="Enter Question"
                type="question"
                x
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Create Options
              </label>

              <CreatableSelect
                isMulti
                value={data?.multiSelectData?.options}
                placeholder=" Press enter to save"
                onChange={(value) =>
                  setData({
                    ...data,
                    multiSelectData: {
                      type: "multiselect",
                      answers: data?.multiSelectData?.answers,
                      options: value,
                    },
                  })
                }
              />

              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Answer
              </label>
              <Select
                value={data?.multiSelectData?.answers}
                options={data?.multiSelectData?.options}
                isMulti
                onChange={(value) =>
                  setData({
                    ...data,
                    multiSelectData: {
                      type: "multiselect",
                      answers: value,
                      options: data?.multiSelectData?.options,
                    },
                  })
                }
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Section
              </label>
              <SelectSection />
              <SelectSubheading />
              {/* <RadioGroup name="fruit" selectedValue={this.state.selectedValue} onChange={this.handleChange}> */}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => OnHideModel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            onClick={(e) =>
              createQuestionFunction(data.multiSelectData, resetFieldsValue)
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DropDown */}
      <Modal
        show={showDropDown}
        onHide={() => OnHideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title> Enter Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Question
              </label>
              <input
                placeholder="Enter Question"
                type="question"
                x
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Create Options
              </label>
              {/* // option create */}
              <CreatableSelect
                isMulti
                value={data?.dropDownData?.options}
                placeholder=" Press enter to save"
                onChange={(value) =>
                  setData({
                    ...data,
                    dropDownData: {
                      type: "dropdown",
                      answers: data?.dropDownData?.answers,
                      options: value,
                    },
                  })
                }
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Answer
              </label>
              <Select
                value={data?.dropDownData?.answers}
                options={data?.dropDownData?.options}
                onChange={(value) =>
                  setData({
                    ...data,
                    dropDownData: {
                      type: "dropdown",
                      answers: [value],
                      options: data?.dropDownData?.options,
                    },
                  })
                }
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Section
              </label>
              <SelectSection />
              <SelectSubheading />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => OnHideModel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            // onClick={(e) => createQuestion(e)}
            onClick={(e) =>
              createQuestionFunction(data.dropDownData, resetFieldsValue)
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* RadioButton */}
      <Modal
        show={showRadio}
        onHide={() => OnHideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title> Enter Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Question
              </label>
              <input
                placeholder="Enter Question"
                type="question"
                x
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Create Options
              </label>
              <CreatableSelect
                isMulti
                value={data?.radioData?.options}
                placeholder=" Press enter to save"
                //  options={ra}

                onChange={(value) =>
                  setData({
                    ...data,
                    radioData: {
                      type: "radio-button",
                      answers: data?.radioData?.answers,
                      options: value,
                    },
                  })
                }
              />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Answer
              </label>

              <div className="gen-radio">
                <RadioGroup
                  name="fruit"
                  className="rd-box"
                  onChange={(e) => radioOptionChange(e)}
                >
                  {data?.radioData?.options.length > 0 &&
                    data?.radioData?.options?.map((item, index) => {
                      return (
                        <div className="radio-child">
                          <Radio
                            checked={data?.radioData?.answers?.find(
                              (childitem) => childitem?.value == item?.value
                            )}
                            value={item.value}
                          />
                          {item.value}
                        </div>
                      );
                    })}
                </RadioGroup>
              </div>
            </div>

            <label htmlFor="exampleInputEmail1" className="form-label">
              Select Section
            </label>
            <SelectSection />
            <SelectSubheading />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => OnHideModel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            onClick={(e) =>
              createQuestionFunction(data.radioData, resetFieldsValue)
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* attachment */}
      {/* attachment */}
      <Modal
        show={attachment}
        onHide={() => OnHideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title> Enter Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Question
              </label>
              <input
                placeholder="Enter Question"
                type="question"
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select File
              </label>
              {/* <AttachedFile /> */}
              <input
                type="file"
                onChange={(e) => {
                  setFileData(e);
                }}
                accept="image/*"
              />
              {data.fileData?.answers[0]?.label?.filename ? (
                <span className="at-file ">
                  {" "}
                  {fileExtension(data.fileData?.answers[0]?.label)}
                  <p>{data.fileData?.answers[0]?.label?.filename}</p>{" "}
                </span>
              ) : (
                ""
              )}

              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Section
              </label>
              <SelectSection />

              <SelectSubheading />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => OnHideModel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            onClick={(e) =>
              createQuestionFunction(data.fileData, resetFieldsValue)
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add subheading */}
      <Modal
        show={showSubHeading}
        onHide={() => OnHideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title> Enter Sub Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Sub Heading
              </label>
              <input
                placeholder="Enter sub heading"
                type="question"
                x
                value={subheading}
                onChange={(e) => {
                  setSubheading(e.target.value);
                }}
                onKeyPress={handleSubheadingKeypress}
              />
              <span>
                {error.sectionRequiredError != "" && error.sectionRequiredError}
              </span>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => OnHideModel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            onClick={(e) =>
              issubheadingEdit
                ? setEditSubheadingValue()
                : addSubheadingFunction()
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add heading */}
      <Modal
        show={showAddHeading}
        onHide={() => OnHideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title> Enter Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Section
              </label>
              <input
                placeholder="Enter section name"
                type="question"
                value={heading}
                onChange={(e) => {
                  setheading(e.target.value);
                  setError({ ...error, heading: null });
                }}
                onKeyPress={handleKeypress}
              />
              {error?.heading && (
                <span className="error">{error?.heading}</span>
              )}
              <div>
                {sectionList?.length > 0 &&
                  sectionList?.map((item, index) => {
                    return (
                      <div className="edit-pop-bar">
                        <span> {item.value}</span>
                        <button onClick={(e) => deleteSection(e, item, index)}>
                          <img src={trash} />
                        </button>
                      </div>
                    );
                  })}
              </div>
              <span>
                {error.sectionRequiredError != "" && error.sectionRequiredError}
              </span>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => OnHideModel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            onClick={(e) => addHeadingFinction()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

  function SelectSubheading() {
    return (
      <>
        <label htmlFor="exampleInputEmail1" className="form-label">
          Choose Subheading
        </label>
        <Select
          value={selectedSubheading}
          options={subheadingList}
          onChange={(value) => setSelectedSubheading(value)}
        />
      </>
    );
  }

  function SelectSection() {
    return (
      <>
        <Select
          value={selectedSection}
          options={sectionList}
          onChange={(value) => setSelectedSection(value)}
        />
        <span className="error">
          {error.sectionRequiredError != "" && error.sectionRequiredError}
        </span>
      </>
    );
  }

  function AttachedFile() {
    return (
      <>
        <label htmlFor="exampleInputEmail1" className="form-label">
          Add Attachment
        </label>
        <input
          type="file"
          id="fileIds"
          onChange={(e) => AddAttachmentWithQA(e)}
          accept="image/*"
        />
        {errors.attachment && (
          <span className="error">{errors.attachment}</span>
        )}
        {/* <span onClick={(e) => removefile(e)}>X</span> */}
        {console.log("attachFileLinkattachFileLink", attachFileLink)}
        {attachFileLink != "" && (
          <>
            {attachFileLink?.filename ? (
              <span className="at-file">
                {fileExtension(attachFileLink)}
                <p>{attachFileLink?.filename}</p>{" "}
                <span
                  onClick={(e) => removefile(e)}
                  className="removeattachment"
                >
                  <Close />
                </span>
              </span>
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }

  function ConfirmModel() {
    return (
      <>
        <Modal
          show={showConfirmModel}
          onHide={() => setShowConfirmModel(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="create-popup">
              <div className="mb-3">
                <p>{confirmModelMessage}</p>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="cancel-btn"
              onClick={() => setShowConfirmModel(false)}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              className="save-btn user"
              onClick={(e) => navigate("/templates")}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
};

export const downloadFile = (fileUrl, filename) => {
  window.open(fileUrl);
};
export default CreateTemplate;
