import { Navigate, Outlet } from "react-router-dom";

export const getAuth = () => {
  if (localStorage.getItem("Token")) {
    return true;
  }
  return false;
};
export const getinvestorAuth = () => {
  if (localStorage.getItem("Investor")) {
    return true;
  }
  return false;
};

export const ProtectedRoute = () => {
  console.log(getAuth());
  return getAuth() ? <Outlet /> : <Navigate to="/" />;
};

export const PublicRoute = () => {
  console.log(getAuth());
  return !getAuth() ? <Outlet /> : <Navigate to="/dashboard" />;
};

// export const InvestorRoute = () => {
//   return getAuth() ? <Outlet /> : <Navigate to="/signupinvestor" />;
// };