import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import "./style.scss";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import arrow from "./images/arrow.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import arrowleft from "./images/arrow-left.svg";
import plus from "./images/plus.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";

const FundEdit = () => {
  const [modalShow, setModalShow] = React.useState(false);
  // assign popup
  const [AssignShow, setAssignShow] = React.useState(false);
  const navigate = useNavigate();

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showRight, setRightShow] = React.useState(false);
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);
  return (
    <div className="dashboard-main">
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              <div className="search-box">
                <input type="text" className="sr-bar" placeholder="Search" />
                <button>
                  <img src={search} />
                </button>
              </div>
            </div>
            <div className="top-bar-right small-view">
              <div className="help-ico">
                <a href="#">
                  <img src={help} />
                </a>
              </div>
              <div className="notify-ico">
                <a href="#">
                  <img src={notify} /> <span className="dot-ico"></span>{" "}
                </a>
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span className="ico-drop">
                    <img src={user} />
                  </span>{" "}
                  <span className="drp-name">John Doe</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <span className="ico-drop-in">
                      <img src={settings} />
                    </span>{" "}
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <span className="ico-drop-in">
                      <img src={logout} />
                    </span>{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="mob-right-bar">
                <Button className="ryt-button" onClick={handleRightShow}>
                  <span className="m-line">
                    <img src={arrowleft} />
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <div className="dashboard-box">
            <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" />
              <button>
                <img src={search} />
              </button>
            </div>

            <div className="ds-heading">
              {" "}
              <h2>Fund Template Listing</h2>
              <div className="buttons-grp">
                <a href="#" className="discard-btn">
                  Cancel
                </a>
                <a href="#" className="orange-border-btn ml-10">
                  Save as Draft
                </a>
                <a href="#" className="border-btn ml-10">
                  Public View
                </a>
                <a href="#" className="blue-btn-fill ml-10">
                  Publish
                </a>
              </div>
            </div>

            <div className="fund-listing-main">
              <div className="fund-box fn-details">
                <div className="fund-heading">
                  <h4>Fund A</h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      Mar 04, 2022
                    </div>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
                <div className="fund-inv">
                  <p>
                    Assign to:{" "}
                    <span className="name-circle blue-circle">S</span>
                  </p>
                  <Button
                    variant="primary"
                    className="assign-btn-two"
                    onClick={() => setAssignShow(true)}
                  >
                    <img src={plus} />
                  </Button>
                </div>
              </div>
              <div className="select-edit-bar">
                <button className="white-btn">Text</button>
                <button className="white-btn">Date</button>
                <button className="white-btn">Multiselect</button>
                <button className="white-btn">Dropdown</button>
                <button className="white-btn">Radio button</button>
                <button className="white-btn">Add attachments</button>
              </div>
              <div className="template-edit-bx lp-edit-box"></div>
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
        <div className="right-bar">
          <div className="top-bar-right">
            <div className="help-ico">
              <a href="#">
                <img src={help} />
              </a>
            </div>
            <div className="notify-ico">
              <a href="#">
                <img src={notify} /> <span className="dot-ico"></span>{" "}
              </a>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span className="ico-drop">
                  <img src={user} />
                </span>{" "}
                John Doe
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  <span className="ico-drop-in">
                    <img src={settings} />
                  </span>{" "}
                  Settings
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => navigate("/")} href="#">
                  <span className="ico-drop-in">
                    <img src={logout} />
                  </span>{" "}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="fund-right-list">
            <ul>
              <li>
                {" "}
                <a href="#" className="f-list m-active">
                  Fund A
                </a>
                <ul className="fund-template-list">
                  <li>
                    {" "}
                    <a href="#" Name="sub-active">
                      General Firm Information
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Template detail two</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Template detail three</a>{" "}
                  </li>
                </ul>
              </li>
              <li>
                {" "}
                <a href="#" className="f-list">
                  Fund B
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#" className="f-list">
                  Fund C
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#" className="f-list">
                  Fund D
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* create fund popup */}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input type="name" placeholder="Name" />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Description
              </label>
              <textarea
                className="description"
                placeholder="Add Description"
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => setModalShow(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button variant="primary" className="save-btn">
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create fund popup close */}

      {/* Assign popup */}

      <Modal
        show={AssignShow}
        onHide={() => setAssignShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Assign to</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input type="name" placeholder="Name" />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input type="name" placeholder="Email" />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => setAssignShow(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button variant="primary" className="save-btn">
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Assign popup close */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide */}

      <Offcanvas
        show={showRight}
        className="right-bar-two"
        onHide={handleRightClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="right-bar">
            <div className="fund-right-list">
              <ul>
                <li>
                  {" "}
                  <a href="#" className="f-list m-active">
                    Fund A
                  </a>
                  <ul className="fund-template-list">
                    <li>
                      {" "}
                      <a href="#" Name="sub-active">
                        General Firm Information
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">Template detail two</a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">Template detail three</a>{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  {" "}
                  <a href="#" className="f-list">
                    Fund B
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#" className="f-list">
                    Fund C
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#" className="f-list">
                    Fund D
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide close */}
    </div>
  );
};

export default FundEdit;
