import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const RightCompanies = ({ companies, id , funds ,setUpdateState}) => {
  const navigate = useNavigate();
console.log("companies",companies)
  let Activecompany = [];
  companies?.total > 0 &&
    companies?.companies?.data?.map((item) => {
      if (item._id == id) {
        return Activecompany.unshift(item);
      } else {
        return Activecompany.push(item);
      }
    });
  console.log(Activecompany, "Activecompany");

  return (
    <div className="fund-right-list">
      <ul>
        {Activecompany?.length > 0 &&
          Activecompany?.map((index) => {
            return (
              <>
                {id == index?._id ? (
                  <li key={index?._id} id={index?._id}>
                    {" "}
                    <a
                      onClick={(e) => navigate(`/company-detail/${index?._id}`)}
                      href="javascript:void(0)"
                      className="f-list m-active"
                    >
                      {index?.name}
                    </a>
                    <ul>
                      {funds?.length > 0 &&
                        funds?.map((index) => {
                          return <li className="no-data">{index?.name}</li>;
                        })}
                    </ul>
                    {funds?.length < 0 ? (
                      <div className="no-data">No fund created</div>
                    ) : (
                      ""
                    )}
                  </li>
                ) : (
                  <li key={index?._id}>
                    {" "}
                    <a
                      onClick={(e) => {
                        navigate(`/company-detail/${index?._id}`)
                         setUpdateState(true)
                         }}
                         href="javascript:void(0)"
                      className="f-list"
                    >
                      {index?.name}
                    </a>
                  </li>
                )}
              </>
            );
          })}
      </ul>
    </div>
  );
};

export default RightCompanies;
