import React, { useEffect, useState } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UpdateCompanyAction } from "../util/actions/companiesactions";

const EditCompanyModel = ({
  companyModel,
  setcompanyModel,
  setUpdateState,
  setIsLoading,
  Company,
  setupdatecompany,
  setcompanybyidupdate
}) => {
  const [values, setValues] = useState({
    name: "",
    company_id: "",
  });
  let id = values.company_id;
  useEffect(() => {
    setValues({
      ...values,
      name: Company?.name,
      company_id: Company?._id,
    });
  }, [companyModel]);
  const [errors, setErrors] = useState({
    name: "",
  });

  toast.configure();

  /* Clear Fund form */
  const clearfundform = () => {
    setValues({
      name: "",
    });
  };

  const handlecancel = (e) => {
    setcompanyModel(false);
    clearfundform();
    setErrors({
      ...errors,
      name: null,
    });
  };

  /* For edit funds  */
  const handleSubmit = async (e) => {
    try {
    //  e.preventDefault();
      if (values.name === "") {
        setErrors({
          ...errors,
          name: values?.name === "" ? "Please enter name" : errors.name,
        });
        return false;
      }
      setErrors({
        ...errors,
        name: null,
      });
      setIsLoading(true);
      let response = await UpdateCompanyAction(id, values);
      if (response.status === 200) {
        setIsLoading(false);
        toast.success("Company updated", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setcompanyModel(false);
        clearfundform();
        // setUpdateState(false);
        setcompanybyidupdate(true);
       
      }
      return;
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message?.name[0]
        : "Something went wrong during Company";
      setErrors({
        ...errors,
        name: message,
      });
      console.log("err", err?.message?.name[0]);

      return false;
    }
  };

   /* Enter Press form submit */
   const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };
  return (
    <Modal
      show={companyModel}
      onHide={() => setcompanyModel(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Edit Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="create-popup">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Name
            </label>
            <input
              type="Enter Company Name"
              placeholder="Name"
              value={values.name}
              onChange={(e) => {
                setValues({ ...values, name: e.target.value });
                setErrors({
                  ...errors,
                  name: null,
                });
              }}
              onKeyPress={handleKeypress}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={(e) => handlecancel(e)}
        >
          {" "}
          Cancel{" "}
        </Button>
        <Button variant="primary" className="save-btn" onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditCompanyModel;
