import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import arrow from "./images/arrow.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import download from "./images/download.svg";
import ufund from "./images/user-fund.png";
import usetting from "./images/user-settings.png";
import back from "./images/back-ico.svg";
import edit from "./images/edit-ico.svg";
import archive from "./images/archive.svg";
import duplicate from "./images/duplicate.svg";
import trash from "./images/trash.svg";
import assign from "./images/assign.svg";
import eye from "./images/eye.svg";
import gplus from "./images/grey-plus.svg";
import arrowleft from "./images/arrow-left.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import RightCompanies from "./components/RightCompanies";
import {
  getCompanybyIdAction,
  deleteCompanybyIdAction,
} from "./util/actions/companiesactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import CreateAdminFundModel from "./Model/CreateAdminFundModel";
import { CompanyListContext } from "./company-list";
import UserAssignModel from "./Model/UserAssignModel";
import AssignModel from "./Model/AssignModel";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import AdminFund from "./components/adminFund";
import {
  getFundsbyIdAction,
  getCompaniesAction,
  CompanyFundSearchAction,
} from "./util/actions/companiesactions";
import Spinner from "./spinner";
import EditCompanyModel from "./Model/EditCompanyModel";
import Pagination from "react-js-pagination";

const CompanyDetail = () => {
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const [DeleteShow, setDeleteShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [Company, setCompany] = useState(false);
  const [showRight, setRightShow] = useState(false);
  const [AssignShow, setAssignShow] = useState(false);
  const [UserAssignShow, setUserAssignShow] = useState(false);
  const [show, setShow] = useState(false);
  const [updatestate, setUpdateState] = useState(false);
  const [updatecompany, setupdatecompany] = useState(false);
  const [companybyidupdate, setcompanybyidupdate] = useState(false);
  const [funds, setfunds] = useState("");
  const [fund, setfund] = useState("");
  const [fundid, setfundid] = useState("");
  const [companies, setCompanies] = useState("");
  const [companyModel, setcompanyModel] = useState(false);
 

  useEffect(() => {
    CompanyById();
  }, [companybyidupdate]);

  useEffect(() => {
    getFunds();
  }, [updatestate]);

  useEffect(() => {
    if (UserRole == "super-admin") {
      getCompanies();
    }
  }, [updatecompany,companybyidupdate]);

  /* Set Company Model */
  const setCompanyModel = (index) => {
    console.log(index, "index");
    setCompany(index);
    setcompanyModel(true);
  };

  /* Get Company details Id */
  const { id } = useParams();
  const navigate = useNavigate();
  toast.configure();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);

  const setUserassignModel = (id, index) => {
    setfundid(id);
    setfund(index);
    setUserAssignShow(true);
  };

  /* Get Company Response */
  const CompanyById = async () => {
    try {
      setIsLoading(true);
      let response = await getCompanybyIdAction(id);
      if (!response?.statusText === "OK") {
        throw {
          message: response.message || "Something went wrong with company",
        };
      }
      console.log("companyresponse", response?.data?.data?.company);
      if (response.status == 200) {
        setIsLoading(false);
        setCompany(response?.data?.data);
        // setUpdateState(false);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong with company";
      toast.error(message);
      return false;
    }
  };

  /* For Delete Company */
  const DeleteCompany = async () => {
    try {
      setIsLoading(true);
      let response = await deleteCompanybyIdAction(id);
      if (response.status == 200) {
        setIsLoading(false);
        toast.success("Company Deleted", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      
    //  setUpdateState(true);
      navigate("/company-list");
    } catch (err) {
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong with funds";
      toast.error(message);
      return false;
    }
  };

  /* get Funds */
  const getFunds = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundsbyIdAction(id,e);
      if (response.status == 200) {
        setIsLoading(false);
        setfunds(response?.data?.data?.funds);
      }

    } catch (err) {
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      console.log(message);
      return false;
    }
  };

  /* get Companies */
  const getCompanies = async (e) => {
    try {
      setIsLoading(true);
      let response = await getCompaniesAction();
      if (response.status == 200) {
        setIsLoading(false);
        setCompanies(response?.data?.data);
        setUpdateState(false);
      }
    } catch (err) {
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      toast.error(message);
      return false;
    }
  };
  /* For Search */
  const inputHandler = async (e) => {
    try {
      var searchval = e.target.value;
      let response = await CompanyFundSearchAction(id, searchval);
      if (response.status == 200) {
        setfunds(response?.data?.data?.funds);
      }
    } catch {
      return false;
    }
  };

  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidebar start */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              <div className="search-box">
                <input
                  type="text"
                  className="sr-bar"
                  placeholder="Search"
                  onChange={(e) => inputHandler(e)}
                />
                <button>
                  <img src={search} />
                </button>
              </div>
            </div>
            <RightMobileHeader handleRightShow={handleRightShow} setIsLoading={setIsLoading}/>
          </div>
          <div className="ds-heading">
            {" "}
            <h2>
              <a
                onClick={(e) => navigate("/company-list")}
                href=""
                className="back-btn"
              >
                <img src={back} />
              </a>{" "}
              Company detail
            </h2>
          </div>
          <div className="dashboard-box">
            <div className="search-box srch-mobile">
            <input
                  type="text"
                  className="sr-bar"
                  placeholder="Search"
                  onChange={(e) => inputHandler(e)}
                />
              <button>
                <img src={search} />
              </button>
            </div>

            <div className="company-list-main details-section">
              <div className="c-name-bar">
                <div className="fund-heading">
                  <h4>
                    <a href="javascript:void(0)">{Company?.company?.name}</a>
                  </h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      {moment(Company?.company?.created_at).format("MMMM D, YYYY")}
                    </div>
                    {/* <a href="" className="assign-dt blue-circle ml-10">
                      JD
                    </a> */}
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        <img src={dots} />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => setAssignShow(true)}
                          href="javascript:void(0)"
                        >
                          <span className="ico-drop">
                            <img src={assign} />
                          </span>{" "}
                          New Fund Manager
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setCompanyModel(Company)}
                          href="javascript:void(0)"
                        >
                          <span className="ico-drop">
                            <img src={edit} />
                          </span>{" "}
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="javascript:void(0)"
                          onClick={() => setDeleteShow(true)}
                         // onClick={DeleteCompany}
                        >
                          <span className="ico-drop">
                            <img src={trash} />
                          </span>{" "}
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="c-detail-section">
                <div className="create-box">
                  <a
                    href="#"
                    className="box-in"
                    onClick={() => setModalShow(true)}
                  >
                    <div className="img">
                      <img src={gplus} />
                    </div>
                    <div className="rt-text">
                      <h5>Create Fund</h5>
                      {/* <p>
                        Sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p> */}
                    </div>
                  </a>
                </div>
                <AdminFund
                  setUserAssignShow={setUserAssignShow}
                  UserAssignShow={UserAssignShow}
                  funds={funds}
                  setUpdateState={setUpdateState}
                  id={id}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  getFunds={getFunds}
                />
              </div>
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>

        <div className="right-bar">
          <RightHeader setIsLoading={setIsLoading}/>
          <RightCompanies
            companies={companies}
            id={id}
            funds={funds}
            setUpdateState={setUpdateState}
          />
        </div>
      </div>

      {/* create fund popup */}
      <CreateAdminFundModel
        setModalShow={setModalShow}
        modalShow={modalShow}
        setUpdateState={setUpdateState}
        id={id}
        setIsLoading={setIsLoading}
      />

      <EditCompanyModel
        companyModel={companyModel}
        setcompanyModel={setcompanyModel}
        // setUpdateState={setUpdateState}
        setIsLoading={setIsLoading}
        Company={Company?.company}
        setupdatecompany={setupdatecompany}
        setcompanybyidupdate={setcompanybyidupdate}
      />
      {console.log("comapny",Company?.company)}

      {/* create fund popup close */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide */}

      <Offcanvas
        show={showRight}
        className="right-bar-two"
        onHide={handleRightClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="right-bar">
            <RightHeader setIsLoading={setIsLoading} />
            <RightCompanies
              companies={companies}
              id={id}
              funds={funds}
              setUpdateState={setUpdateState}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide close */}
      {/* User Assign Model */}
      {/* <UserAssignModel
        setUserAssignShow={setUserAssignShow}
        UserAssignShow={UserAssignShow}
        id={id}
        fundid={fundid}
        userfunds={userfund}
        fund={fund}
        setIsLoading={setIsLoading}
      /> */}
      {/* end  Assign Model */}

      {/* Assign popup */}

      <AssignModel
        setAssignShow={setAssignShow}
        AssignShow={AssignShow}
        id={id}
        setIsLoading={setIsLoading}
      />
      

      {/* Assign popup close */}
      {/*Delete Model start */}
      <Modal
          show={DeleteShow}
          onHide={() => setDeleteShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="create-popup">
              <div className="mb-3">
                <p>Are you want to delete</p>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="cancel-btn"
              onClick={() => setDeleteShow(false)}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              variant="primary"
              className="save-btn"
              onClick={(e) => DeleteCompany(e)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delete Model close */}
    </div>
  );
};

export default CompanyDetail;
