import React, { useEffect, useState } from "react";
import clock from "../images/clock.svg";
import eye from "../images/eye.svg";
import dots from "../images/dots.svg";
import archive from "../images/archive.svg";
import edit from "../images/edit-ico.svg";
import duplicate from "../images/duplicate.svg";
import trash from "../images/trash.svg";
import plus from "../images/plus.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Tooltip,
  OverlayTrigger,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  DeleteFundAction,
  getFundsbyIdAction,
  getFundManagerslist,
} from "../util/actions/fundactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModel from "../Model/DeleteModel";
import UserAssignModel from "../Model/UserAssignModel";
import EditFundModel from "../Model/EditFundModel";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import Pagination from "react-js-pagination";
import NewFundsAssignModel from "../Model/NewFundsAssignModel";

const AdminFund = ({
  AssignShow,
  setAssignShow,
  funds,
  setUpdateState,
  UserAssignShow,
  setUserAssignShow,
  setIsLoading,
  isLoading,
  seteditstate,
  getFunds,
}) => {
  toast.configure();
  const navigate = useNavigate();
  const [cid, setid] = useState("");
  const [fundid, setfundid] = useState("");
  const [DeleteShow, setDeleteShow] = useState(false);
  const [editfundModel, seteditfundModel] = useState(false);
  const [fund, setFund] = useState();
  const [userfund, setuserFund] = useState({});
  const [newFundsAssignshow, setNewFundsAssignShow] = useState("");
  const [fundmanager, setfundmanager] = useState("");

  const { id } = useParams();
  let Companyid = id;
  /* Set Delete Model */
  const setDeleteModel = (e, id) => {
    setDeleteShow(true);
    setid(id);
  };

  const setAssignModel = (id) => {
    localStorage.setItem("fundId", id);
    setfundid(id);
    setAssignShow(true);
  };

  const setUserassignModel = (id, index) => {
    setfundid(id);
    setFund(index);
    console.log("myindexxxxxxxx", index);
    setuserFund(index);
    setUserAssignShow(true);
    getFundManagers(index?.company_id);
  };

  const seteditmodel = (id, index) => {
    seteditfundModel(true);
    setfundid(id);
    setFund(index);
  };

  /* For Delete Fund  */
  const DeleteFund = async (e) => {
    try {
      setIsLoading(true);
      let response = await DeleteFundAction(cid);
      // return true;
      if (response.status === 200) {
        setIsLoading(false);
        console.log("fundelete", response);
        setUpdateState(false);
        toast.success("Fund Deleted", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDeleteShow(false);
        setUpdateState(true);
      }

      //   navigate("/");
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong with adminfund";
      toast.error(message);
      return false;
    }
  };

  /* Get Fund Mangers list */
  const getFundManagers = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundManagerslist(e);
      if (response.status === 200) {
        setIsLoading(false);
        setfundmanager(response?.data?.data?.managers);
      }
      return;
    } catch (err) {
      setIsLoading(false);
    }
  };
  // /* Get Funds By Id Response */
  // const FundsById = async () => {
  //   try {
  //     setIsLoading(true);
  //     let response = await getFundsbyIdAction(fundid);
  //     if (response.status == 200) {
  //       setIsLoading(false);
  //       console.log("fundsbyidd", response?.data);
  //       setFund(response?.data?.data?.fund);
  //     }
  //   } catch (err) {
  //     setIsLoading(false);
  //     let message =
  //       err && err.message
  //         ? err.message
  //         : "Something went wrong with addPatrons";
  //     toast.error(message);
  //     return false;
  //   }
  // };

  // useEffect(() => {
  //   if (fundid) {
  //     FundsById();
  //   }
  // }, []);

  return (
    <>
      <div className="fund-listing-main">
        {funds?.total > 0 &&
          funds?.data?.map((index) => {
            return (
              <div className="fund-box" key={index?._id}>
                <div className="fund-heading">
                  <h4
                    onClick={(e) =>
                      navigate(`/fund-management-details/${index?._id}`)
                    }
                    href=""
                  >
                    {index?.name}
                  </h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      {moment(index?.created_at).format("MMMM D, YYYY")}
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        <img src={dots} />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => seteditmodel(index?._id, index)}
                          href="javascript:void(0)"
                        >
                          <span className="ico-drop">
                            <img src={edit} />
                          </span>{" "}
                          Edit
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={archive} />
                          </span>{" "}
                          Archive
                        </Dropdown.Item> */}
                        {/* <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={duplicate} />
                          </span>{" "}
                          Duplicate
                        </Dropdown.Item> */}
                        <Dropdown.Item
                          onClick={(e) => setDeleteModel(e, index?._id)}
                          href="javascript:void(0)"
                        >
                          <span className="ico-drop">
                            <img src={trash} />
                          </span>{" "}
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p>{index?.description}</p>
                <div className="fund-inv">
                  {index?.assigned_managers?.length > 0 && (
                    <p>
                     <span className="fnd-name-bx">Assigned to:{" "}</span>
                      {index?.assigned_managers?.map((item) => (
                        <>
                        
                        
                               <OverlayTrigger
                            placement="top-start"
                            overlay={
                              <Tooltip
                                id="collect-tooltip"
                                data-place="top-center"
                              >
                                {item?.name.charAt(0).toUpperCase() +
                                  item?.name.slice(1)}
                              </Tooltip>
                            }
                          >
                            <span
                              className="name-circle"
                              style={{ background: item?.color }}
                            >
                              {" "}
                              {item?.name?.toUpperCase().charAt(0)}
                              {/* {index?.assigned_managers[0]?.name?.toUpperCase().charAt(0)} */}
                            </span>
                          </OverlayTrigger>
                      
                        </>
                        
                      ))}{" "}
                    </p>
                    
                  )}{" "}
                  <Button
                    variant="primary"
                    className="assign-btn-two"
                    onClick={(e) => setUserassignModel(index?._id, index)}
                  >
                    <img src={plus} />
                  </Button>
                </div>
              </div>
            );
          })}
        {funds.total > 0 ? (
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={funds?.current_page}
            itemsCountPerPage={funds.per_page}
            totalItemsCount={funds.total}
            pageRangeDisplayed={3}
            onChange={(e) => getFunds(e)}
            innerClass="pagination justify-content-end"
            prevPageText="Prev"
            nextPageText="Next"
          />
        ) : (
          ""
        )}
      </div>
      <DeleteModel
        DeleteShow={DeleteShow}
        setDeleteShow={setDeleteShow}
        cid={cid}
        setUpdateState={setUpdateState}
        DeleteFund={DeleteFund}
        Companyid={Companyid}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      {/* Assign popup */}

      <EditFundModel
        editfundModel={editfundModel}
        seteditfundModel={seteditfundModel}
        cid={cid}
        fundid={fundid}
        funds={funds}
        fund={fund}
        setUpdateState={setUpdateState}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        seteditstate={seteditstate}
      />

      <UserAssignModel
        AssignShow={AssignShow}
        setAssignShow={setAssignShow}
        setUserAssignShow={setUserAssignShow}
        UserAssignShow={UserAssignShow}
        fundid={fundid}
        userfunds={userfund}
        fund={fund}
        setIsLoading={setIsLoading}
        setNewFundsAssignShow={setNewFundsAssignShow}
        fundmanager={fundmanager}
        setfundmanager={setfundmanager}
      />
      <NewFundsAssignModel
        fundid={fundid}
        userfunds={userfund}
        fund={fund}
        setIsLoading={setIsLoading}
        newFundsAssignshow={newFundsAssignshow}
        setNewFundsAssignShow={setNewFundsAssignShow}
      />

      {/* Assign popup close */}
    </>
  );
};

export default AdminFund;
