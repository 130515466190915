import axios from "axios";
import jwtDecode from "jwt-decode";
const config = require(`../../environment/development.js`).config;
// const token = JSON.parse(localStorage.getItem("Token"));

// if(token)
// {
//   var headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };
// }

/* Get funds  */
export const getFundsAction = (e) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url =`${config.backEnd}/funds`;
      if (e) {
          url = `${url}?page=${e}`;
      }
      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* Get funds by Id */
export const getFundsbyIdAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/funds/${id}`);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* Get templates by Id */
export const getTemplatesbyIdAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/templates/${id}`);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};
/* For Create Funds for Admin*/
export const CreateFundAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/funds/createNewFund`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Create Funds */
export const CreateFund = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/funds`, payload);
      return resolve(response);
    } catch (error) {
      console.log("error ghfjfgjh kjhkj ", error);
      return reject(error);
    }
  });
};

/* For Create Funds */
export const UpdateFund = (id, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/funds/${id}/update`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error ghfjfgjh kjhkj ", error);
      return reject(error);
    }
  });
};

/* For Delete Funds */
export const DeleteFundAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.delete(`${config.backEnd}/funds/${id}/delete`);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get Fund Manager */
export const getFundManagerlist = (e) => {
  return new Promise(async (resolve, reject) => {
    try {

      let url = `${config.backEnd}/get-total-fund-managers`;

      if (e) {
          url = `${url}?page=${e}`;
      }
      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Funds assign invites  */
export const FundInviteUserAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/user-invites`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get Fund Manager Log  */
export const FundManagerlogAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/users/fund-managers-logs`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get funds  */
export const getFundsTemplateAction = (e,id) => {
  return new Promise(async (resolve, reject) => {
    try {

      let url = `${config.backEnd}/funds/${id}/fund-templates`;

      if (e) {
          url = `${url}?page=${e}`;
      }
      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For Funds Archive Templates */
export const FundsArchiveTemplateAction = (e,id) => {
  return new Promise(async (resolve, reject) => {
    try {

      let url = `${config.backEnd}/funds/${id}/fund-templates?archived=true`;

      if (e) {
          url = `${url}&page=${e}`;
      }
      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For Download Fund Templates */
export const DownloadFundAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/templates/${id}/download`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Copy Templates import funds  */
export const importFundTemplateAction = (id, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/funds/${id}/copy-templates`,
        payload
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For Funds Search */
export const FundsSearchAction = (name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/funds?search=${name}`);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For Fund Manager Search */
export const FundManagerSearchAction = (name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-total-fund-managers?search=${name}`
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* Get funds by company id for useradd dropdwon */
export const getUserAddFundsAction = (company_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-all-funds/${company_id}`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get all published template id for useradd dropdwon */
export const getUserAddTemplateAction = (fund_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-all-published-templates/${fund_id}`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* add fund investor  */
export const addFundInvestorAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/add-fund-investor`,
        payload
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* Assign fund Access  */
export const AssignAccessAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/assign-fund-access`,
        payload
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* Remove fund Access  */
export const RemoveAccessAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/remove-fund-access`,
        payload
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* Get all fund list for assign access */
export const getFundsInfoAction = (id,payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/getAllFunds/${id}`,payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get all fund manager list by company */
export const getFundManagerslist = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-fund-manger-list?company_id=${id}`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Fund Manager invite send  */
export const fundManagerInviteAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/sendFundJoinInvitation`,payload
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};