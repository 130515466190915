import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ValidateCheckAction } from "./util/actions/loginaction";
import { FundTemplateSharableSigninAction } from "./util/actions/allactions";
import Spinner from "./spinner";
import swal from "sweetalert";
import SignupInvestor from "./signupinvestor";
import Signin from "./signin";

const Share = () => {
  var { token } = useParams();
  const UserToken = JSON.parse(localStorage.getItem("Token"));
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const navigate = useNavigate();
  toast.configure();
  const [isLoading, setIsLoading] = useState(false);
  const [showsignup, setshowsignup] = useState(false);
  const [showsignin, setshowsignin] = useState(false);
  const [sharestate, setsharestate] = useState(false);

  useEffect(() => {
    if (UserToken) {
      getsharelink();
    } else {
      setshowsignup(true);
    }
  }, []);

  useEffect(() => {
    if (UserToken && UserRole=="investor") {
      getsharelink();
    }
  }, [sharestate]);

  /* For Sharable url */
  const getsharelink = async () => {
    try {
      setIsLoading(true);
      let response = await FundTemplateSharableSigninAction(token);
      if (response.status == 200) {
        setIsLoading(false);
        swal({
          title: "Congratulations!",
          text: "you have joined successfully.",
          type: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard");
        });
        return;
      }
      console.log("sharablelink", response?.data?.data?.url);
    } catch (err) {
      setIsLoading(false);
      navigate("/dashboard");
      let message =
        err && err.message ? err.message : "Something went wrong with share";
      return false;
    }
  };
  return (
    <>
      {showsignup ? (
        !showsignin ? (
          <SignupInvestor
            setshowsignin={setshowsignin}
            setshowsignup={setshowsignup}
          />
        ) : (
          <Signin setsharestate={setsharestate} />
        )
      ) : (
        <div className="App">
          <div className="splashmain">
            {isLoading ? <Spinner /> : ""}
            <div className="logobx">
              <img src={logo} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Share;
