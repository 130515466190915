import axios from "axios";
import jwtDecode from "jwt-decode";
const config = require(`../../environment/development.js`).config;

let UserRole = localStorage.getItem("UserRole");
if (UserRole) {
  var UserRoleIs = JSON.parse(UserRole);
}
// const token = JSON.parse(localStorage.getItem("Token"));

// if (token) {
//   var headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };
// }

/* Get Total fund managers List */
export const getTemplatesAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-total-fund-managers`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* get fund manager */
export const createTemplateAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-total-fund-managers`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get user Invites List */
export const getInvitationAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/user-invites`);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get recent fund Manager List */
export const getRecentManagerLog = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-recent-fund-managers`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get recent investor log */
export const getRecentInvestorLog = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-recent-fund-investors`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Delete Users */
export const DeleteUserAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.delete(`${config.backEnd}/users/${id}/delete`);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Create Template for superAdmin */
export const CreateTemplateAdmin = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/templates`, payload);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Create Template for FundManager */
export const CreateTemplateFundManger = (payload, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/funds/${id}/fund-templates`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Get All Template List */
export const getallTemplatesAction = (e) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${config.backEnd}/templates`;

      if (e) {
        url = `${url}?page=${e}`;
      }
      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Default All Template List */
export const getdefaultsTemplatesAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/templates/get-default-templates`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Delete Templates */
export const DeleteTemplateAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.delete(
        `${config.backEnd}/templates/${id}/delete`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For get Fund Templates Sharable action */
export const FundTemplateSharableAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/templates/${id}/shareable-url`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get user Log  */
export const userlogAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/users/logs`);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get Template by id  */
export const TemplatebyIdAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/templates/${id}`);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* add template Question Answer */
export const setTemplateQuestionAnswer = (Data, fundId) => {
  return new Promise(async (resolve, reject) => {
    console.log("Data", Data);
    try {
      let response;
      console.log(
        UserRoleIs,
        fundId,
        "yyyyyyyyyyyyyyyyyyyyyfundIdfundIdaction"
      );
      if (UserRoleIs == "fund-manager" && fundId) {
        response = await axios.post(
          `${config.backEnd}/funds/${fundId}/fund-templates`,
          Data
        );
      } else {
        response = await axios.post(`${config.backEnd}/templates`, Data);
      }
      // if(response.status=="success"){
      Data.status == 1 &&
        (await axios.post(
          `${config.backEnd}/templates/${response?.data?.data?.template?._id}/change-status/1`,
          Data
        ));
      // }

      console.log(response, "question answer answer");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* get template Question Answer */
export const getTemplateQuestionAnswer = (Data) => {
  return new Promise(async (resolve, reject) => {
    console.log("Data", Data);
    try {
      let response = await axios.get(`${config.backEnd}/templates/${Data}`);

      console.log(response, "question answer answer");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};
// create Template Question
export const updateTemplateQuestionAnswer = (Data, id) => {
  return new Promise(async (resolve, reject) => {
    console.log("Data", Data);
    try {
      let response = await axios.post(
        `${config.backEnd}/templates/${id}/update`,
        Data
      );
      Data.status == 1 &&
        (await axios.post(
          `${config.backEnd}/templates/${response?.data?.data?.template?._id}/change-status/1`,
          Data
        ));

      console.log(response, "question answer answer");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get User Management Log  */
export const UserManagementAction = (e) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${config.backEnd}/users`;

      if (e) {
        url = `${url}?page=${e}`;
      }

      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Change Password */
export const ChangePasswordAction = (id, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/users/${id}/change-password`,
        payload
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For post Fund Templates Sharable action */
export const FundTemplateSharableSigninAction = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/templates/${token}/shareable-url`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

// Template file upload
export const TemplateFileUploadAction = (file) => {
  console.log(file);
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/store-attachment-file`,
        file
      );
      return resolve(response?.data?.data);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

export const RemoveFileAction = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/remove-attachment-file`,
        file
      );
      return resolve(response?.data);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Users Search */
export const UsersSearchAction = (name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/users?search=${name}`);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For Templates Search */
export const TemplateSearchAction = (val,name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/templates?archived=${val}&search=${name}`
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

export const TemplateArchiveAction = (id, status) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/templates/${id}/${status}`
      );
      return resolve(response?.data);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Templates Archive */
export const ArchiveTemplateAction = (e) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${config.backEnd}/templates?archived=true`;
      if (e) {
        url = `${url}&page=${e}`;
      }
      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};
