import React, { useState } from "react";
import "../App.css";
import "../style.scss";
import help from "../images/help.svg";
import notify from "../images/notify.svg";
import settings from "../images/settings.svg";
import logout from "../images/logout.svg";
import user from "../images/user-img.png";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { logoutAction } from "../util/actions/loginaction";
import arrowleft from "../images/arrow-left.svg";

const RightMobileHeader = ({ handleRightShow, setIsLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const UserName = JSON.parse(localStorage.getItem("UserName"));
  const Avatar = JSON.parse(localStorage.getItem("avatar"));
  const handlelogout = async () => {
    try {
      let response = await logoutAction();
      setIsLoading(true);
      if (response.status == 200) {
        setIsLoading(false);
        localStorage.clear();
        navigate("/");
      }
      return;
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message ? err.message : "Something went wrong during login";
      console.log("error", message);
      return false;
    }
  };
  return (
    <div className="top-bar-right small-view">
      {/* <div className="help-ico">
        <a href="#">
          <img src={help} />
        </a>
      </div>
      <div className="notify-ico">
        <a href="#">
          <img src={notify} /> <span className="dot-ico"></span>{" "}
        </a>
      </div> */}
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <span className="ico-drop">
            {Avatar ? (
              <img src={Avatar} />
            ) : (
              <span className="name-bx-user blue-circle">
                {UserName?.toUpperCase()?.charAt(0)}
              </span>
            )}
          </span>{" "}
          <span className="drp-name">{UserName}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/settings">
            <span className="ico-drop-in">
              <img src={settings} />
            </span>{" "}
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={handlelogout} href="javascript:void(0)">
            <span className="ico-drop-in">
              <img src={logout} />
            </span>{" "}
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {!location.pathname.match("user-management") &&
      !location.pathname.match("templates") &&
      !location.pathname.match("fund-manager-log") &&
      !location.pathname.match("investor-log") ? (
        <div className="mob-right-bar">
          <Button className="ryt-button" onClick={handleRightShow}>
            <span className="m-line">
              <img src={arrowleft} />
            </span>
          </Button>
        </div>
      ) : (
        " "
      )}
    </div>
  );
};

export default RightMobileHeader;
