import React, { useEffect, useState, createRef } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import user from "./images/user-img.png";
import search from "./images/search.svg";
import user2 from "./images/userthumb.png";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import "react-toastify/dist/ReactToastify.css";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import {
  UserloginInfoAction,
  UpdateUserProfile,
} from "./util/actions/loginaction";
import { ChangePasswordAction } from "./util/actions/allactions";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import Spinner from "./spinner";
import { calendarFormat } from "moment";
import { showToast } from "./customtoast";
import { config } from "./environment/production";

const Settings = () => {
  const navigate = useNavigate();
  toast.configure();
  const [isReadOnly, setisReadOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatestate, setupdatestate] = useState(false);
  const [update, setupdate] = useState(false);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(false);
  const [userinfo, setuserinfo] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [file, setfile] = useState("");
  const [preview, setpreview] = useState("");
  const [values, setValues] = useState({
    name: " ",
    email: " ",
    avatar: " ",
    id: " ",
    old_password: "",
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    avatar: "",
    id: "",
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  const clearform = () => {
    setValues({
      ...values,
      old_password: "",
      password: "",
      password_confirmation: "",
    });
  };

  useEffect(() => {
    getUserLog();
  }, [updatestate, update]);

  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
    useDropzone({
      // onDrop: (files) =>
      //   setfile(files[0]) || setErrors({ ...errors, avatar: null }),
      //   accept: "image/png,image/jpeg,image/jpg",
      accept: "image/png,image/jpeg,image/jpg",
      onDrop: (acceptedFiles) => {
        setfile(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );

        setpreview(acceptedFiles.length > 0 && acceptedFiles[0]?.preview);
        setErrors({
          ...errors,
          name: null,
        });
      },
    });

  console.log("filesssssssssssssssss", file);
  /* Get User Log */
  const getUserLog = async (e) => {
    try {
      setIsLoading(true);
      let response = await UserloginInfoAction();
      if (response.status == 200) {
        setIsLoading(false);
        setUser(response?.data?.data?.info);
        setValues({
          ...values,
          name: user?.name,
          email: user?.email,
          id: user?._id,
          avatar: user?.avatar,
        });
        setupdatestate(true);
        setisReadOnly(true);
       // setupdate(false);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      toast.error(message);
      return false;
    }
  };
  /* For Update Profile */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let id = values?.id;
      if (values.name.trim() === "") {
        setErrors({
          ...errors,
          name: values.name.trim() === "" ? "Please enter name" : errors.name,
        });
      }
      const formData = new FormData();
      formData.append("avatar", file[0]? file[0] : '');
      formData.append("name", values?.name);
      setIsLoading(true);
      let response = await UpdateUserProfile(id, formData);
      console.log("registrationres", response);
      if (response.status == 200) {
        setIsLoading(false);
        toast.success("Settings Update Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.setItem(
          "UserName",
          JSON.stringify(response?.data?.data?.info?.name)
        );
        localStorage.setItem(
          "avatar",
          JSON.stringify(response?.data?.data?.info?.avatar)
        );
        setupdatestate(false);
        setupdate(true);
        return;
      }
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during login";
      setErrors({
        ...errors,
        name: message?.name?.length > 0 ? message?.name[0] : " ",
      });
      return false;
    }
  };

  /* Change Password */
  const ChangePassword = async (e) => {
    e.preventDefault();
    try {
      let id = values?.id;

      if (
        values.old_password === "" ||
        values.password === "" ||
        values.password_confirmation !== values.password
      ) {
        setErrors({
          ...errors,
          old_password:
            values.old_password.trim() === ""
              ? "Please enter old password"
              : errors.old_password,
          password:
            values.password.trim() === ""
              ? "Please enter new password"
              : errors?.password_confirmation,
          password_confirmation:
            values.password_confirmation.trim() === ""
              ? "Please enter confirm Password"
              : values.password.trim() !== values.password_confirmation.trim()
              ? "Password does not match"
              : errors.password_confirmation,
        });
        return false;
      }
      setIsLoading(true);
      const formData = new FormData();
      formData.append("old_password", values?.old_password);
      formData.append("password", values?.password);
      formData.append("password_confirmation", values?.password_confirmation);
      let response = await ChangePasswordAction(id, formData);
      if (response.status == 200) {
        setIsLoading(false);
        toast.success("Password Updated Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        clearform();
        return;
      }
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during reset";
      setErrors({
        ...errors,
        old_password:
          message?.old_password?.length > 0 && message?.old_password[0],
        password: message?.password?.length > 0 && message?.password[0],
        password_confirmation:
          message?.password_confirmation?.length > 0 &&
          message?.password_confirmation[0],
      });
      return false;
    }
  };
  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content ds-content-two">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar import-temp-top">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
            </div>

            <RightHeader setIsLoading={setIsLoading} />
            <RightMobileHeader setIsLoading={setIsLoading} />
          </div>
          <div className="ds-heading">
            {" "}
            <h2> Settings</h2>
          </div>

          <div className="dashboard-box">
            <div className="row">
              <div className="col-md-6">
                <div className="settings-main">
                  <form className="settingsfromm">
                    <div
                      className="mb-3"
                      {...getRootProps({ className: "dropzone" })}
                    >
                      <div className="fileUpload">
                        {/* <input
                    //  type="file"
                     // className="upload"
                      //  onChange={(e)=>setfile(e.target.files[0])}
                    /> */}
                        <input {...getInputProps()} />
                        <span>
                          {!values?.avatar ? <img src={user2} /> : " "}
                          {preview ? (
                            <img src={preview} />
                          ) : (
                            <img src={user?.avatar} />
                          )}
                        </span>
                        {errors.avatar && (
                          <span className="error">{errors.avatar}</span>
                        )}
                      </div>
                    </div>
                    {/* <div className="mb-3">
                      <span className="file-name">{file?.name}</span>
                     
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="exampleInputName" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputname"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name "
                        value={values?.name}
                        onChange={(e) => {
                          setErrors({
                            ...errors,
                            name: null,
                          });
                          setValues({ ...values, name: e.target.value });
                        }}
                      />
                      {errors.name && (
                        <span className="error">{errors.name}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter email address"
                        value={values?.email}
                        readOnly={isReadOnly}
                        onChange={(e) => {
                          setErrors({
                            ...errors,
                            email: null,
                          });
                          setValues({ ...values, email: e.target.value });
                        }}
                      />
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="blue-btn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <div className="settings-main">
                  <form className="settingsfromm">
                    <div className="mb-3">
                      <label htmlFor="exampleInputName" className="form-label">
                        Old Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputname"
                        aria-describedby="emailHelp"
                        placeholder="Old Password"
                        value={values?.old_password}
                        onChange={(e) => {
                          setErrors({
                            ...errors,
                            old_password: null,
                          });
                          setValues({
                            ...values,
                            old_password: e.target.value,
                          });
                        }}
                      />
                      {errors.old_password && (
                        <span className="error">{errors.old_password}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputName" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputname"
                        aria-describedby="emailHelp"
                        placeholder="New Password"
                        value={values?.password}
                        onChange={(e) => {
                          setErrors({
                            ...errors,
                            password: null,
                          });
                          setValues({ ...values, password: e.target.value });
                        }}
                      />
                      {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputName" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputname"
                        aria-describedby="emailHelp"
                        placeholder="Confirm Password"
                        value={values?.password_confirmation}
                        onChange={(e) => {
                          setErrors({
                            ...errors,
                            password_confirmation: null,
                          });
                          setValues({
                            ...values,
                            password_confirmation: e.target.value,
                          });
                        }}
                      />
                      {errors.password_confirmation && (
                        <span className="error">
                          {errors.password_confirmation}
                        </span>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="blue-btn"
                      onClick={(e) => ChangePassword(e)}
                    >
                      Update Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
      </div>

      {/* left-sidebar */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* left-sidebar close */}
    </div>
  );
};

export default Settings;
