import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FundDetailContext } from "../fund-management-details";
import { CreateFund, getFundsAction } from "../util/actions/fundactions";

const InvestorSidebarFunds = ({ setIsLoading,setUpdateState,updatestate,template ,seteditstate,funds}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  let ActiveFund = [];
  console.log("activefundssssssss", funds,"id");
  funds?.total > 0 &&
    funds?.data?.map((item) => {
      if (item._id == id) {
         ActiveFund.unshift(item);
      } else {
         ActiveFund.push(item);
      }
    });
  console.log(ActiveFund, "Activecompany");

  return (
    <div className="fund-right-list">
      <ul>
        {ActiveFund?.length > 0 &&
          ActiveFund?.map((index) => {
            return (
              <>
                {id == index?.fund_id ? (
                  <li key={index?._id}>
                    {" "}
                    <a
                      onClick={(e) =>
                        navigate(`/fund-detail/${index?._id}`)
                      }
                      href="javascript:void(0)"
                      className="f-list m-active"
                    >
                      {index?.name}
                    </a>
                    <ul className="fund-template-list">
                     {template?.data?.length>0 && template?.data?.map((item)=> { return( 
                      <li>
                        {" "}
                        <a href="#">{item?.name}</a>{" "}
                      </li>
                      )})}        
                    </ul>
                  </li>
                ) : (
                  <li>
                    {" "}
                    <a
                      onClick={(e) => {
                        navigate(`/fund-management-details/${index?._id}`);
                        setUpdateState(false);
                      }}
                      href="javascript:void(0)"
                      className="f-list"
                    >
                      {index?.name}
                    </a>{" "}
                    <ul className="fund-template-list">
                     {template?.data?.length>0 && template?.data?.map((item)=> { return( 
                      <li>
                        {" "}
                        <a href="#">{item?.name}</a>{" "}
                      </li>
                      )})}        
                    </ul>
                  </li>
                )}
              </>
            );
          })}
      </ul>
    </div>
  );
};

export default InvestorSidebarFunds;
