import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import arrow from "./images/arrow.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
  Tooltip
} from "react-bootstrap";
import Sidebar from "./sidebar";
import AdminFund from "./components/adminFund";
import Fund from "./components/Fund";
import { useNavigate } from "react-router-dom";
import { CreateFund, getFundsAction, FundsSearchAction } from "./util/actions/fundactions";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import CreateFundModel from "./Model/createFundModel";
import RightSidebarFunds from "./components/RightSidebarfunds";
import Spinner from "./spinner";

const FundManagement = () => {
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const [modalShow, setModalShow] = useState(false);
  const [fundmodalShow, setfundModalShow] = useState(false);
  const [showRight, setRightShow] = useState(false);
  const [AssignShow, setAssignShow] = useState(false);
  const [UserAssignShow, setUserAssignShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editfundModel, seteditfundModel] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);
  const [updatestate, setUpdateState] = useState(false);
  const [editstate , seteditstate] = useState(false);
  const [funds, setfunds] = useState("");

  useEffect(() => {
    getFunds();
  }, [updatestate,editstate]);

  /* get Funds */
  const getFunds = async (e) => {
    console.log('abc')
    try {
      setIsLoading(true);
      let response = await getFundsAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setfunds(response?.data?.data?.funds);
        console.log("funds", response);
       // setUpdateState(false);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in funds.";
      console.log(message);
      return false;
    }
  };
  /* For Search */
  const inputHandler = async (e) => {
    try {
    var searchval = e.target.value;
    let response = await FundsSearchAction(searchval);
    if (response.status == 200) {
      setfunds(response?.data?.data?.funds);
    }}
    catch{
      return false;
    }
  }
  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              <div className="search-box">
                <input type="text" className="sr-bar" placeholder="Search" onChange={(e)=>inputHandler(e)}/>
                <button>
                  <img src={search} />
                </button>
              </div>
            </div>
            <RightMobileHeader handleRightShow={handleRightShow} setIsLoading={setIsLoading}/>
          </div>
          <div className="ds-heading">
              {" "}
              <h2>Fund Management</h2>
              {UserRole == "fund-manager" ? (
                <Button
                  variant="primary"
                  className="border-btn"
                  onClick={() => setfundModalShow(true)}
                >
                  Create New Fund
                </Button>
              ) : (
                " "
              )}
            </div>
          <div className="dashboard-box inner-pages-bx">
            <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" onChange={(e)=>inputHandler(e)} />
              <button>
                <img src={search} />
              </button>
            </div>

            <div className="fund-listing-main">
              <AdminFund
                AssignShow={AssignShow}
                setAssignShow={setAssignShow}
                setUserAssignShow={setUserAssignShow}
                UserAssignShow={UserAssignShow}
                funds={funds}
                setUpdateState={setUpdateState}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                seteditstate={seteditstate}
                getFunds={getFunds}
              />
              {/* <FundManagerFund AssignShow={AssignShow}
                setAssignShow={setAssignShow}
                funds={funds}
                setUpdateState={setUpdateState}
                isLoading={isLoading}
                setIsLoading={setIsLoading}/> */}
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
        <div className="right-bar">
          <RightHeader setIsLoading={setIsLoading}/>
          <RightSidebarFunds
            // funds={funds}
            setIsLoading={setIsLoading}
            editstate={seteditstate}
            funds={funds}
            seteditstate={seteditstate}
            // // id={id}
            // setUpdateState={setUpdateState}
          />
        </div>
      </div>

      {/* create fund popup */}

      <CreateFundModel
        fundmodalShow={fundmodalShow}
        setfundModalShow={setfundModalShow}
        setIsLoading={setIsLoading}
        setUpdateState={setUpdateState}
        seteditstate={seteditstate}
        editstate={editstate}
      />

      {/* create fund popup close */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide */}

      <Offcanvas
        show={showRight}
        className="right-bar-two"
        onHide={handleRightClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="right-bar">
            <RightHeader setIsLoading={setIsLoading} />
            <RightSidebarFunds
            setIsLoading={setIsLoading}
            editstate={seteditstate}
            funds={funds}
            seteditstate={seteditstate}
          />
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide close */}
    </div>
  );
};

export default FundManagement;
