import React, { useEffect, useState } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UpdateFund, getFundsbyIdAction } from "../util/actions/fundactions";

const EditFundModel = ({
  seteditfundModel,
  editfundModel,
  setUpdateState,
  id,
  fundid,
  fund,
  funds,
  setIsLoading,
  seteditstate,
}) => {
  const [values, setValues] = useState({
    name: "",
    description: " ",
    fund_id: fundid,
  });

  useEffect(() => {
    // console.log(fund, "editfunddddddddddd");
    setValues({
      ...values,
      name: fund?.name,
      description: fund?.description,
      fund_id: fund?.id,
    });
  }, [editfundModel]);
  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  toast.configure();

  /* Clear Fund form */
  const clearfundform = () => {
    setValues({
      name: "",
      description: "",
    });
  };

  const handlecancel = (e) => {
    seteditfundModel(false);
    clearfundform();
    setErrors({
      ...errors,
      name: null,
    });
  };

  /* For edit funds  */
  const handleSubmit = async (e) => {
    try {
      console.log("handlesubmit")
      //  e.preventDefault();
      if (values.name === "") {
        setErrors({
          ...errors,
          name: values?.name === "" ? "Please enter name" : errors.name,
        });
        return false;
      }
      setErrors({
        ...errors,
        name: null,
      });
      values.fund_id = fundid;
      setIsLoading(true);
      let response = await UpdateFund(fundid, values);
      if (response.status === 200) {
        setIsLoading(false);
        toast.success("fund updated", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        seteditfundModel(false);
        clearfundform();
        setUpdateState(true);
        seteditstate(true);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during login";
      setErrors({
        ...errors,
        name: message?.name?.length>0 && message?.name[0],
        description:message?.description?.length>0 && message?.description[0]
      });
      

      return false;
    }
  };
  /* Enter Press form submit */
  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };
  return (
    <Modal
      show={editfundModel}
      onHide={() => seteditfundModel(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Edit Fund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="create-popup">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Name
            </label>
            <input
              type="name"
              placeholder="Name"
              value={values.name}
              onChange={(e) => {
                setValues({ ...values, name: e.target.value });
                setErrors({
                  ...errors,
                  name: null,
                });
              }}
              onKeyPress={handleKeypress}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Description
            </label>
            <textarea
              className="description"
              placeholder="Add Description"
              value={values?.description}
              onChange={(e) => {
                setValues({ ...values, description: e.target.value });
                setErrors({ ...errors, description: null });
              }}
              onKeyPress={handleKeypress}
              // maxLength="500"
            ></textarea>
            {errors.description && (
              <span className="error">{errors.description}</span>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={(e) => handlecancel(e)}
        >
          {" "}
          Cancel{" "}
        </Button>
        <Button variant="primary" className="save-btn" onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditFundModel;
