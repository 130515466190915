import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import arrow from "./images/arrow.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import back from "./images/back-ico.svg";
import download from "./images/download.svg";
import copy from "./images/copy.svg";
import arrowleft from "./images/arrow-left.svg";
import {
  Dropdown,
  Offcanvas,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import { getFundsbyIdAction } from "./util/actions/fundactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import RightSidebarFunds from "./components/RightSidebarfunds";
import {
  getFundsAction,
  getFundsTemplateAction,
} from "./util/actions/fundactions";
import Spinner from "./spinner";
import InvestorSidebarFunds from "./components/investorsidebarfunds";
import Pagination from "react-js-pagination";

const FundDetails = () => {
  const navigate = useNavigate();
  const [fund, setfund] = useState(false);
  const [updatestate, setUpdateState] = useState(false);
  const [template, settemplates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showRight, setRightShow] = useState(false);
  const [funds, setfunds] = useState("");
  const [show, setShow] = useState(false);
  toast.configure();

  useEffect(() => {
    FundsById();
  }, [updatestate]);

  const handleclick = () => {
    navigate("/fund-listing");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);

  /* Get Company Id */
  const { id } = useParams();

  useEffect(() => {
    FundsById();
    getFundsTemplates();
    getFunds();
  }, []);

  /* Get Funds By Id Response */
  const FundsById = async () => {
    try {
      setIsLoading(true);
      let response = await getFundsbyIdAction(id);
      if (response.status == 200) {
        setIsLoading(false);
        setfund(response?.data?.data?.fund);
      }
    } catch (err) {
      let message =
        err && err.message ? err.message : "Something went wrong with funds";
      toast.error(message);
      return false;
    }
  };

  /* get Funds Templates */
  const getFundsTemplates = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundsTemplateAction(e,id);
      if (response.status == 200) {
        setIsLoading(false);
        setfunds(response?.data?.data?.funds);
        settemplates(response?.data?.data?.templates);
        console.log("fundsTemplate", response);
      }
    } catch (err) {
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      console.log(message);
      return false;
    }
  };
  /* get Funds */
  const getFunds = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundsAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setfunds(response?.data?.data?.funds);
        console.log("funds", response);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message ? err?.message : "Something went wrong in funds.";
      console.log(message);
      return false;
    }
  };
  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              {/* <div className="search-box">
                <input type="text" className="sr-bar" placeholder="Search" />
                <button>
                  <img src={search} />
                </button>
              </div> */}
            </div>
            <RightMobileHeader
              handleRightShow={handleRightShow}
              setIsLoading={setIsLoading}
            />
          </div>
          <div className="ds-heading">
            <h2>
              <a href="" onClick={handleclick} className="back-btn">
                <img src={back} />
              </a>{" "}
              Fund Detail
            </h2>
          </div>
          <div className="dashboard-box">
            <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" />
              <button>
                <img src={search} />
              </button>
            </div>

            <div className="fund-listing-main">
              <div className="fund-box fn-details">
                <div className="fund-heading">
                  <h4> {fund?.name}</h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      {moment(fund?.created_at).format("MMMM D, YYYY")}
                    </div>
                    {/* <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        <img src={dots} />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="">
                          <span className="ico-drop">
                            <img src={download} />
                          </span>{" "}
                          Download
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                </div>
                <p>{fund?.description}</p>
                <div className="fund-inv">
                  <p>
                    invited by:{" "}
                    <OverlayTrigger
                      placement="top-start"
                      overlay={
                        <Tooltip id="collect-tooltip" data-place="top-center">
                          {fund?.invite_sender_name?.charAt(0).toUpperCase() +
                            fund?.invite_sender_name?.slice(1)}
                        </Tooltip>
                      }
                    >
                      <span
                        className="name-circle "
                        style={{ background: fund?.invite_sender_color }}
                      >
                        {fund?.invite_sender_name?.toUpperCase().charAt(0)}
                      </span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>
              <div className="template-edit-bx">
                {/* <div className="tm-heading">
                  General Firm Information{" "}
                  <a href="#" className="qs-download">
                    <img src={download} />
                  </a>{" "}
                </div> */}
                {/* {template?.total > 0 &&
                    template?.data?.map((index) => {
                      return (
                        <div className="template-box-n" key={index?._id}>
                          <h5>
                            <a href={`/fund-template-detail/${index?._id}`}>
                              {" "}
                              {index?.name}{" "}
                            </a>
                          </h5>
                        </div>
                      );
                    })} */}

                <div className="fund-listing-main">
                  {template?.total > 0 &&
                    template?.data?.map((index) => {
                      return (
                        <div
                          className="template-box-n new-temp-box"
                          key={index?._id}
                        >
                          <h5>
                            <div className="fund-heading">
                              <a href={`/fund-template-detail/${index?._id}`}>
                                {" "}
                                {index?.name}{" "}
                                {index?.archive_status == "1"
                                  ? "(Archived)"
                                  : " "}
                              </a>
                            </div>{" "}
                          </h5>
                          <div className="hd-right">
                            <div className="date-bx">
                              <span className="time-ico">
                                <img src={clock} />
                              </span>{" "}
                              {moment(index?.created_at).format(
                                "MMMM DD, YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {template?.total > 0 ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={template?.current_page}
                      itemsCountPerPage={template?.per_page}
                      totalItemsCount={template?.total}
                      pageRangeDisplayed={3}
                      onChange={(e) => getFunds(e)}
                      innerClass="pagination justify-content-end"
                      prevPageText="Prev"
                      nextPageText="Next"
                    />
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      1. Is the venture capital Firm a publicly-held company?
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> YES
                  </div>
                </div>

                <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      2.Does the Firm have any existing businesses that are not
                      related to Funds investment strategy?
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> NO
                  </div>
                </div>

                <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      3. Provide an organizational chart depicting the ownership
                      structure of the Firm including investment advisors and
                      any parent organization.
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> It is a long established fact that a
                    reader will be distracted by the readable content of a page
                    when looking at its layout. The point of using Lorem Ipsum
                    is that it has a more-or-less normal distribution of
                    letters, as opposed to using 'Content here.
                  </div>
                </div>

                <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      4. Provide a description of the Firm’s Fundraising plans
                      over the horizon of the next five years.
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> It is a long established fact that a
                    reader will be distracted by the readable content of a page
                    when looking at its layout. The point of using Lorem Ipsum
                    is that it has a more-or-less normal distribution of
                    letters, as opposed to using 'Content here.
                  </div>
                </div>

                <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      5. Provide a brief overview of the Firms liquidity,
                      pro-forma budget and capitalization.
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> Include any debt service requirements
                    and personal guarantees involved. Mention the personal
                    guarantees and the people. For the budget, provide an
                    annualized pro-forma budget covering the life of the Fund.
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
        <div className="right-bar">
          <RightHeader setIsLoading={setIsLoading} />
          <InvestorSidebarFunds
            funds={funds}
            id={id}
            setUpdateState={setUpdateState}
            setIsLoading={setIsLoading}
            template={template}
          />
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide */}

      <Offcanvas
        show={showRight}
        className="right-bar-two"
        onHide={handleRightClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="right-bar">
            <RightHeader setIsLoading={setIsLoading} />
            <InvestorSidebarFunds
              funds={funds}
              id={id}
              setUpdateState={setUpdateState}
              setIsLoading={setIsLoading}
              template={template}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide close */}
    </div>
  );
};

export default FundDetails;
