import moment from "moment";
import edit from "../images/edit-ico.svg";
import check from "../images/check.svg";
import trash from "../images/trash.svg";
import file from "../images/file.svg";
import doc from "../images/doc.svg";
import pdf from "../images/pdf.svg";
import { Button ,  Tooltip,
  OverlayTrigger,} from "react-bootstrap";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { sectionList } from "../util/commonFunctions";
import { useState } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { downloadFile } from "../create-template";
import { format, parseISO } from 'date-fns' 
const TemplateEditView = (props) => {
  const {
    questionAnswerArray,
    deleteQuestion,
    EditQuestionAnswer,
    ShowStatusChange,
    setEditableFIeldsIndex,
    editableFIeldsIndex,
    editSubheading,
    deleteSubheading,
    editSection,
    deleteSectionfronForm,
  } = props;


  const [startDate, setStartDate] = useState(new Date());
  const selected = moment(startDate).toDate();

  
  const editQuestion = (
    parentIndex,
    subheadingIndex,
    index,
    item,
    sectionName,
    subheadingName
  ) => {
    setEditableFIeldsIndex({
      ...editableFIeldsIndex,
      parentIndex: parentIndex,
      subheadingIndex: subheadingIndex,
      itemIndex: index,
    });
    EditQuestionAnswer(
      item,
      {
        value: sectionName,
        label: sectionName,
      },
      subheadingName
    );
  };

  const editSectionMain = (parentIndex, section) => {
    setEditableFIeldsIndex({
      ...editableFIeldsIndex,
      parentIndex: parentIndex,
    });
    editSection(section);
  };

  return (
    <>
      {questionAnswerArray?.length > 0 &&
        questionAnswerArray?.map((parentItem, parentIndex) => {
          return (
            <div className="template-section">
              <div className="template-cmn-hd">
                <h4 style={{ textAlign: "center" }}>
                  {parentItem?.section}
                  <Button
                    className="ac-icon"
                    onClick={() =>
                      editSectionMain(parentIndex, parentItem.section)
                    }
                  >
                    <img src={edit} />
                  </Button>
                  {questionAnswerArray[parentIndex].data.length == 0 && (
                    <Button
                      className="ac-icon"
                      onClick={() =>
                        deleteSectionfronForm(parentIndex, parentItem.section)
                      }
                    >
                       <img src={trash} />
                    </Button>
                  )}
                </h4>
              </div>

              {parentItem?.data?.length > 0 &&
                parentItem?.data?.map((subheadingItem, subheadingIndex) => {
                  return (
                    <>
                      <h5 className="sub-hd-bx">
                        {subheadingItem?.sub_heading.value}
                        <OverlayTrigger
                            placement="top-start"
                            overlay={
                              <Tooltip
                                id="collect-tooltip"
                                data-place="top-center"
                              >
                               Enter Sub Heading
                              </Tooltip>
                            }
                          >
                        <Button
                          className="ac-icon"
                          onClick={() =>
                            editSubheading(
                              parentIndex,
                              subheadingIndex,
                              subheadingItem?.sub_heading
                            )
                          }
                        >
                          <img src={edit} />
                        </Button>
                        </OverlayTrigger>
                        {subheadingItem?.questions?.length == 0 && (
                          <Button
                            className="ac-icon"
                            onClick={() =>
                              deleteSubheading(parentIndex, subheadingIndex)
                            }
                          >
                            <img src={trash} />
                          </Button>
                        )}
                      </h5>

                      {subheadingItem?.questions?.length > 0 &&
                        subheadingItem?.questions?.map((item, index) => {
                          if (item?.type == "text") {
                            var TextValueUpadate = item?.answers[0]?.label;
                          }

                          return (
                            <>
                              <div className="ques-bar-main">
                                {item?.type == "text" && (
                                  <div className="ques-left">
                                    <h5>
                                      {`${index + 1}:`} {item?.question}
                                    </h5>
                                    <div className="sp-btn ans-bx">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: TextValueUpadate,
                                        }}
                                      />
                                    </div>

                                    {item?.attachFile && (
                                      <div className="sp-btn ans-bx">
                                       <a href={item?.attachFile?.path} target="_blank" >  {item?.attachFile && (
                                          <span className="at-file d">
                                            {fileExtension(item?.attachFile)}

                                            <br />
                                            <p>{item?.attachFile?.filename}</p>
                                          </span>
                                        )}
                                        </a>
                                      </div>
                                    )}
                                    <br />
                                  </div>
                                )}
                                    
                                {item?.type == "date" && (
                                  <div className="ques-left">
                                    <h5>
                                      {`${index + 1}:`} {item?.question}
                                    </h5>
                                    <div className="sp-btn ans-bx">
                                      {!item?.answers[0]?.label == ""
                                        ? moment(
                                            item?.answers[0]?.label
                                          )?.format("DD/MM/YYYY")
                                        : ""}
                                         {/* { new Date(item?.answers[0]?.label)} */}
                                       
                                       {/* {console.log("dateeeeeeee",new Date(item?.answers[0]?.label)).toISOString()} */}
                                    </div>
                                  </div>
                                )}

                                {item.type == "multiselect" && (
                                  <div className="ques-left">
                                    <h5>
                                      {`${index + 1}:`} {item?.question}
                                    </h5>

                                    {item?.options?.length > 0 &&
                                      item?.options?.map(
                                        (multiItem, childindex) => {
                                          return (
                                            <>
                                              <div className="sp-btn ans-bx">
                                                {`${childindex + 1}:`}

                                                {multiItem?.label}
                                                {item?.answers?.find(
                                                  (item) =>
                                                    item?.value ==
                                                    multiItem?.value
                                                ) ? (
                                                  <span className="rt-ans">
                                                    <img src={check} />
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                )}

                                {item.type == "dropdown" && (
                                  <div className="ques-left">
                                    <h5>
                                      {`${index + 1}:`} {item?.question}
                                    </h5>

                                    {item?.options?.length > 0 &&
                                      item?.options?.map(
                                        (multiItem, childindex) => {
                                          return (
                                            <>
                                              <div className="sp-btn ans-bx">
                                                {`${childindex + 1}:`}

                                                {multiItem?.label}
                                                {item?.answers.find(
                                                  (item) =>
                                                    item?.value ==
                                                    multiItem?.value
                                                ) ? (
                                                  <span className="rt-ans">
                                                    <img src={check} />
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <br />
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                )}

                                {item.type == "radio-button" && (
                                  <div className="ques-left">
                                    <h5>
                                      {`${index + 1}:`} {item?.question}
                                    </h5>
                                    <br />
                                    <></>
                                    <RadioGroup
                                      name={item?.answers[0]?.value + Math.random()}                                     
                                      className="radio-text-holder"
                                    >
                                      {console.log("itemitemitem",item)}
                                      {item?.options?.length > 0 &&
                                        item?.options?.map(
                                          (multiItem, childindex) => {
                                            return (
                                              <div className="radio-txt">
                                                <div className="radio-bx">
                                                  <Radio
                                                    checked={
                                                      item?.answers?.length >
                                                        0 &&
                                                      item?.answers?.find(
                                                        (childitem) =>
                                                           childitem?.value ===
                                                         multiItem?.value
                                                           
                                                      )
                                                      
                                                      
                                                    }
                                                 
                                                  />
                                                </div>
                                                
                                                {console.log("multiItem",multiItem)}
                                                {multiItem?.value}
                                              </div>
                                            );
                                          }
                                        )}
                                    </RadioGroup>
                                  </div>
                                )}

                                {item?.type == "file-input" && (
                                  <div className="ques-left">
                                    <h5>
                                      {`${index + 1}:`} {item?.question}
                                    </h5>

                                    <div className="sp-btn ans-bx">
                                      <br />
                                      <a href={item?.answers[0]?.label?.path} target="_blank" >
                                      {item?.answers?.length > 0 && (
                                        <span className="at-file ">
                                          {fileExtension(
                                            item?.answers[0]?.label
                                          )}

                                          <p>
                                            {item?.answers[0]?.label?.filename}
                                          </p>
                                        </span>
                                      )}
                                      </a>
                                    </div>
                                  </div>
                                )}
                                {/* file-input */}

                                <div className="ques-right">
                                  <div className="tgl-bx">
                                    Hide
                                    <Switch
                                      checked={item?.show}
                                      onChange={(e) =>
                                        ShowStatusChange(
                                          parentIndex,
                                          subheadingIndex,
                                          index,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    Show
                                  </div>

                                  <div className="ac-btns">
                                    <Button
                                      onClick={() =>
                                        deleteQuestion(
                                          parentIndex,
                                          subheadingIndex,
                                          index,
                                          item
                                        )
                                      }
                                      className="ac-icon"
                                    >
                                      <img src={trash} />
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        editQuestion(
                                          parentIndex,
                                          subheadingIndex,
                                          index,
                                          item,
                                          parentItem?.section,
                                          subheadingItem?.sub_heading
                                        )
                                      }
                                      className="ac-icon"
                                    >
                                      <img src={edit} />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </>
                  );
                })}
            </div>
          );
        })}
    </>
  );
};
export const fileExtension = (uploadedFileValue) => {
  let imagesTypes = ["jpg", "jpeg", "png"];
  let fileExtension = uploadedFileValue?.filename?.split(".").pop();
  console.log(
    fileExtension,
    "fileExtensionfileExtensionfileExtensionfileExtension"
  );

  if (uploadedFileValue == "") {
    return "";
  }
  if (fileExtension == "pdf") {
    return (
      <>
      
        <img
          src={pdf}
          onClick={() => downloadFile(uploadedFileValue.path)}
        />
      </>
    );
  } else if (fileExtension == "doc" || fileExtension == "docx") {
    return (
      <>
        <img
          src={doc}
          onClick={() => downloadFile(uploadedFileValue.path)}
        />
      </>
    );
  } else if (imagesTypes.includes(fileExtension)) {
    return (
      <>
        <img className="image"
          src={uploadedFileValue.path}
          onClick={() => downloadFile(uploadedFileValue.path)}
        />
      </>
    );
  } else {
    // return (
    //   <img className="file"
    //     src={file}
    //     onClick={() => downloadFile(uploadedFileValue.path)}
    //   />
    // );
  }
};
export default TemplateEditView;
