import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import arrow from "./images/arrow.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import download from "./images/download.svg";
import ufund from "./images/user-fund.png";
import usetting from "./images/user-settings.png";
import back from "./images/back-ico.svg";
import edit from "./images/edit-ico.svg";
import archive from "./images/archive.svg";
import duplicate from "./images/duplicate.svg";
import trash from "./images/trash.svg";
import template from "./images/template.svg";
import plus from "./images/plus.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";

const NewTemplates = () => {
  const [modalShow, setModalShow] = useState(false);
  const [AssignShow, setAssignShow] = useState(false);

  const navigate = useNavigate();
  const handleclick = () => {
    navigate("/fund-management-details");
  };
  const usermanagement = () => {
    navigate("/user-management");
  };

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="dashboard-main">
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content ds-content-two">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar import-temp-top">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              {/* <div className="search-box">
                <input type="text" className="sr-bar" placeholder="Search" />
                <button>
                  <img src={search} />
                </button>
              </div> */}
            </div>

            <div className="top-bar-right nohide-right">
              <div className="help-ico">
                <a href="#">
                  <img src={help} />
                </a>
              </div>
              <div className="notify-ico">
                <a href="#">
                  <img src={notify} /> <span className="dot-ico"></span>{" "}
                </a>
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span className="ico-drop">
                    <img src={user} />
                  </span>{" "}
                  <span className="drp-name">John Doe</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="javascript:void(0)">
                    <span className="ico-drop-in">
                      <img src={settings} />
                    </span>{" "}
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => navigate("/")}>
                    <span className="ico-drop-in">
                      <img src={logout} />
                    </span>{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="ds-heading">
            {" "}
            <h2>
              <a href="" className="back-btn">
                <img src={back} />
              </a>{" "}
              Templates
            </h2>
            <Button
              variant="primary"
              className="border-btn"
              onClick={handleclick}
            >
              Copy Template
            </Button>
          </div>
          <div className="dashboard-box">
            {/* <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" />
              <button>
                <img src={search} />
              </button>
            </div> */}

            <div className="fund-listing-main">
              <div className="fund-box">
                <div className="fund-heading">
                  <h4>New Template A</h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      Mar 04, 2022
                    </div>
                    <label className="check-box">
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
              </div>

              <div className="fund-box">
                <div className="fund-heading">
                  <h4>New Template B</h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      Mar 04, 2022
                    </div>
                    <label className="check-box">
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
              </div>

              <div className="fund-box">
                <div className="fund-heading">
                  <h4>New Template C</h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      Mar 04, 2022
                    </div>
                    <label className="check-box">
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
              </div>

              <div className="fund-box">
                <div className="fund-heading">
                  <h4>New Template D</h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      Mar 04, 2022
                    </div>
                    <label className="check-box">
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
              </div>
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
      </div>

      {/* create fund popup */}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input type="name" placeholder="Name" />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Description
              </label>
              <textarea
                className="description"
                placeholder="Add Description"
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => setModalShow(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button variant="primary" className="save-btn">
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create fund popup close */}

      {/* Assign popup */}

      <Modal
        show={AssignShow}
        onHide={() => setAssignShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Assign to</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input type="name" placeholder="Name" />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input type="name" placeholder="Email" />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => setAssignShow(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button variant="primary" className="save-btn">
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Assign popup close */}

      {/* left-sidebar */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* left-sidebar close */}
    </div>
  );
};

export default NewTemplates;
