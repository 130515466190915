import axios from "axios";
import jwtDecode from "jwt-decode";
const config = require(`../../environment/development.js`).config;
// const token = JSON.parse(localStorage.getItem("Token"));

// if(token)
// {
//   var headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };
// }

/* Get investor List */
export const getInvestorsAction = (e) => {
  return new Promise(async (resolve, reject) => {
    try {

      let url = `${config.backEnd}/get-total-fund-investors`;

      if (e) {
          url = `${url}?page=${e}`;
      }
      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get Investor Log  */
export const investorlogAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/users/investors-logs`);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For investor registration */
export const InvestorRegistration = (payload, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/register-investor/${token}`,
        payload
      );
      console.log(response, "response");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Archive Fund */
export const Investorarchiveaction = (id, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/funds/${id}/archive`);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For Un Archive Fund */
export const InvestorUnarchiveaction = (id, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/funds/${id}/unarchive`);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For UnArchive Fund */
export const Investorunarchiveaction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/funds/${id}/unarchive`
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For Investor Search */
export const InvestorSearchAction = (name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/get-total-fund-investors?search=${name}`
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};
