import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FundDetailContext } from "../fund-management-details";
import { CreateFund, getFundsAction } from "../util/actions/fundactions";

const RightSidebarFunds = ({
  setIsLoading,
  setUpdateState,
  updatestate,
  template,
  seteditstate,
  funds,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  let ActiveFund = [];
  funds?.total > 0 &&
    funds?.data?.map((item) => {
      if (item._id == id) {
        ActiveFund.unshift(item);
      } else {
        ActiveFund.push(item);
      }
    });

  return (
    <div className="fund-right-list">
      <ul>
        {ActiveFund?.length > 0 &&
          ActiveFund?.map((index) => {
            return (
              <>
                {id == index?._id ? (
                  <li key={index?._id}>
                    {" "}
                    <a
                      onClick={(e) =>
                        navigate(`/fund-management-detail/${index?._id}`)
                      }
                      href="javascript:void(0)"
                      className="f-list m-active"
                    >
                      {index?.name}
                    </a>
                    <ul className="fund-template-list">
                      {template?.total > 0 &&
                        template?.data?.map((item) => {
                          return (
                            <li>
                              {" "}
                              <a href="javascript:void(0)">{item?.name}</a>{" "}
                            </li>
                          );
                        })}
                      {template?.total == 0 ? (
                        <li>
                          <a href="javascript:void(0)">No Template found</a>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                  </li>
                ) : (
                  <li>
                    {" "}
                    <a
                      onClick={(e) => {
                        navigate(`/fund-management-details/${index?._id}`);
                        setUpdateState(false);
                      }}
                      href="javascript:void(0)"
                      className="f-list"
                    >
                      {index?.name}
                    </a>{" "}
                  </li>
                )}
              </>
            );
          })}
      </ul>
    </div>
  );
};

export default RightSidebarFunds;
