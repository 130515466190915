 import React, { useState, useEffect, createContext } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import "./style.scss";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import trash from "./images/trash.svg";
import assign from "./images/assign.svg";
import eye from "./images/eye.svg";
import arrowleft from "./images/arrow-left.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";
import Company from "./components/company.js";
import CreateCompany from "./Model/CreateCompany";
import {
  getCompaniesAction,
  CompanySearchAction,
} from "./util/actions/companiesactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RightCompanies from "./components/RightCompanies";
import AssignModel from "./Model/AssignModel";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";

import Spinner from "./spinner";

const CompanyListContext = createContext();

const CompanyList = () => {
  const navigate = useNavigate();
  toast.configure();
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [companies, setCompanies] = useState("");
  const [showRight, setRightShow] = useState(false);
  const [companybyidupdate, setcompanybyidupdate] = useState(false);
  const [updatestate, setUpdateState] = useState(true);
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 

  useEffect(() => {
    if (UserRole == "super-admin") {
      getCompanies();
    }
  }, [companybyidupdate,updatestate]);

  /* get Companies */
  const getCompanies = async (e) => {
    try {
      setIsLoading(true);
      let response = await getCompaniesAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setCompanies(response?.data?.data);
        console.log("responsecompany",response?.data?.data)
        setUpdateState(true);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      toast.error(message);
      return false;
    }
  };
  /* For Search */
  const inputHandler = async (e) => {
    try {
      var searchval = e.target.value;
      let response = await CompanySearchAction(searchval);
      if (response.status == 200) {
        setCompanies(response?.data?.data);
       
      }
    } catch {
      return false;
    }
  };
  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <CompanyListContext.Provider>
        <div className="dash-container">
          <div className="left-bar">
            {/* sidee bar */}

            <Sidebar></Sidebar>

            {/* sidebar end */}
          </div>
          <div className="dashboard-content">
            <div className="left-top">
              <img src={lefttop} />
            </div>
            <div className="left-bottom">
              <img src={leftbottom} />
            </div>
            <div className="search-bar">
              <div className="sr-lft">
                <Button className="left-menu-btn" onClick={handleShow}>
                  <span className="m-line"></span>
                </Button>
                <div className="search-box">
                  <input
                    type="text"
                    className="sr-bar"
                    placeholder="Search"
                    onChange={(e) => inputHandler(e)}
                  />
                  <button>
                    <img src={search} />
                  </button>
                </div>
              </div>
              <RightMobileHeader handleRightShow={handleRightShow} setIsLoading={setIsLoading}/>
            </div>
            <div className="ds-heading">
              {" "}
              <h2>Company List</h2>
              <div className="buttons-grp">
                <a
                  href="javascript:void(0)"
                  className="border-btn"
                  onClick={() => setModalShow(true)}
                >
                  Create New Company
                </a>
              </div>
            </div>
            {UserRole == "super-admin" ? (
              <div className="dashboard-box inner-pages-bx">
                <div className="search-box srch-mobile">
                  <input
                    type="text"
                    className="sr-bar"
                    placeholder="Search"
                    onChange={(e) => inputHandler(e)}
                  />
                  <button>
                    <img src={search} />
                  </button>
                </div>

                {/* Start List all Companies */}
                <Company
                  companies={companies}
                  setUpdateState={setUpdateState}
                  setIsLoading={setIsLoading}
                  setcompanybyidupdate={setcompanybyidupdate}
                  getCompanies={getCompanies}
                />
                {/* end List all Companies */}
              </div>
            ) : (
              ""
            )}
            <div className="right-top">
              <img src={rttop} />
            </div>
            <div className="right-bottom">
              <img src={rtbtm} />
            </div>
          </div>

          <div className="right-bar">
            <RightHeader setIsLoading={setIsLoading}/>
            <RightCompanies companies={companies} />
          </div>
        </div>

        {/* create company */}
        <CreateCompany
          modalShow={modalShow}
          setModalShow={setModalShow}
          setUpdateState={setUpdateState}
          setIsLoading={setIsLoading}
        />
        {/* create company close */}

        {/* Edit company Model close */}

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            {/* sidee bar */}

            <Sidebar></Sidebar>

            {/* sidebar end */}
          </Offcanvas.Body>
        </Offcanvas>

        {/* right slide */}

        <Offcanvas
          show={showRight}
          className="right-bar-two"
          onHide={handleRightClose}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <div className="right-bar">
            <RightCompanies companies={companies} />
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* right slide close */}
      </CompanyListContext.Provider>
    </div>
  );
};

export default CompanyList;
export { CompanyListContext };
