import axios from "axios";
import jwtDecode from "jwt-decode";
const config = require(`../../environment/development.js`).config;
// const token = JSON.parse(localStorage.getItem("Token"));

// if(token)
// {
//   var headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };
// }
/* For Login */
export const loginAction = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/login`, payload);
      return resolve(response);
      console.log("data", response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Logout */
export const logoutAction = (Token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/logout`);
      return resolve(response);
      console.log("data", response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Forgot */
export const forgotAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/forgot-password`,
        payload
      );
      return resolve(response);
      console.log("data", response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Reset Password */
export const resetPasswordAction = (payload, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/reset-password/${token}`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For User invite registration */
export const UserInviteRegistration = (payload, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/register/${token}`,
        payload
      );
      console.log(response, "response");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For check validate user */
export const ValidateCheckAction = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/validate/${token}`);
      console.log(response, "response");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For check validate fundmanager */
export const ValidateCheckFundmangerAction = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/validate-fund-manager/${token}`
      );
      console.log(response, "response");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get Logged in user info  */
export const UserloginInfoAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/users/info`);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Update User Profile */
export const UpdateUserProfile = (id, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/users/${id}/update-profile`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For get investor info from token */
export const getTokenInfoAction = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/getTokenInfo/${token}`);
      console.log(response, "response");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Signup investor from invite */
export const AddInvestorInviteAction = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/add-investor/${token}`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};