import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import signupimg from "./images/sign-up_right.svg";
import { useNavigate } from "react-router-dom";
import { loginAction } from "./util/actions/loginaction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./spinner";

const SignIn = ({setsharestate}) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    remember: false,
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  toast.configure();
  const navigate = useNavigate();
  const forgotclick = async () => {
    navigate("/forgot-password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (values.email === "" || values.password === "") {
        setErrors({
          ...errors,
          email:
            values.email === ""
              ? "Please enter username or email"
              : errors.email,
          password:
            values.password === "" ? "Please enter password" : errors.password,
        });
        return false;
      }
      setIsLoading(true);
      let response = await loginAction({ payload: values });
      console.log("loginres", response);

      if (response.status == 200) {
        setIsLoading(false);
        localStorage.setItem(
          "Token",
          JSON.stringify(response?.data?.data?.user?.access_token)
        );
        localStorage.setItem(
          "UserRole",
          JSON.stringify(response?.data?.data?.user?.role)
        );
        localStorage.setItem(
          "UserName",
          JSON.stringify(response?.data?.data?.user?.name)
        );
        localStorage.setItem(
          "Auth-Token",
          JSON.stringify(response?.data?.data?.user?.auth_token)
        );
        localStorage.setItem(
          "avatar",
          JSON.stringify(response?.data?.data?.user?.avatar)
        );
        toast.success("Login Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/dashboard");
        setsharestate(true);
        return;
      }
    } catch (err) {
      setIsLoading(false);
      console.log("messagee", err?.response?.data?.message?.email[0]);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during login";
        setErrors({
          ...errors,
          email:
            message?.email?.length > 0
              ? message?.email.map((index) => index)
              : " ",
          password:
            message?.password?.length > 0
              ? message?.password.map((index) => index)
              : " "
        });
      return false;
    }
  };

  return (
    <div className="splashmain">
      {isLoading ? <Spinner /> : ""}
      <div className="container">
        <div className="row align-items-center h-100">
          <div className="col-md-6">
            <div className="login-formleft">
              <div className="form-heading">
                <span>Sign in</span>
              </div>
              <form className="loginfromm">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email address"
                    value={values.email}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        email: null,
                      });
                      setValues({ ...values, email: e.target.value });
                    }}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter password"
                    value={values.password}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        password: null,
                      });
                      setValues({ ...values, password: e.target.value });
                    }}
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
                <div className="mb-3 form-check">
                  <div className="ps-main-bx">
                    <div className="ps-left">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={values.remember}
                        onChange={(e) => {
                          setValues({ ...values, remember: e.target.checked });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        Remember me
                      </label>
                    </div>
                    <div className="ps-right">
                      <a href="javascript:void(0)" onClick={forgotclick}>
                        Forgot password?
                      </a>
                    </div>
                  </div>
                </div>

                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="blue-btn w-100"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-picbx">
              <img src={signupimg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
