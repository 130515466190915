import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import trash from "./images/trash.svg";
import assign from "./images/assign.svg";
import eye from "./images/eye.svg";
import edit from "./images/edit-ico.svg";
import archive from "./images/archive.svg";
import Pagination from "react-js-pagination";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import RightHeader from "./components/rightHeader";
import {
  getallTemplatesAction,
  TemplateSearchAction,
  TemplateArchiveAction,
  ArchiveTemplateAction,
} from "./util/actions/allactions";
import RightMobileHeader from "./components/rightMobileHeader";
import DeleteTemplateModel from "./Model/DeleteTemplateModel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Spinner from "./spinner";
import Switch from "@mui/material/Switch";

const Templates = () => {
  const navigate = useNavigate();
  toast.configure();
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [AssignShow, setAssignShow] = useState(false);
  const [DeleteShow, setDeleteShow] = useState(false);
  const [show, setShow] = useState(false);
  const [searchvalue, setSearchvalue] = useState(false);
  const [id, setid] = useState(false);
  const [updatestate, setUpdateState] = useState(true);
  const [archivestate, setarchivestate] = useState(true);
  const [template, settemplate] = useState(false);
  const [value, setValue] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getTemplates();
  }, [updatestate, archivestate]);

  /* Set Delete Model */
  const setDeleteModel = (e, id) => {
    setDeleteShow(true);
    setid(id);
  };
  let AllTemplates = template;
  let ArchiveTemplates = [];
  template?.templates?.total > 0 &&
    template?.templates?.data?.filter((item) => {
      if (item.archive_status == 1) {
        return ArchiveTemplates.push(item);
      } else {
        return false;
      }
    });

  /* Show Archive */
  const showarchive = (e) => {
    setSearchvalue(e);
    if (e == false) {
      getTemplates(e);
      // // setUpdateState(true);
      // settemplate(AllTemplates);
      // console.log("alltemplates", AllTemplates);
      // window.location.reload(false);
    } else {
      getArchiveTemplates(e);
      //   let newObject = {
      //     templates: {
      //       data: ArchiveTemplates,
      //     },
      //   };
      //   settemplate(newObject);
      //  console.log("archive",newObject)
      //    window.location.reload(false);
    }
  };

  /* Set Archieve Templates */
  const archivetemplate = async (id) => {
    try {
      setIsLoading(true);
      let response = await TemplateArchiveAction(id, "archive");
      console.log("archive response", response);
      if (response.status == "success") {
        setIsLoading(false);
        setarchivestate(false);
        console.log("Archive Templates", response);
        toast.success("Template Archived Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong with templates";
      toast.error(message);
      return false;
    }
  };

  /* Set UnArchieve Templates */
  const unarchivetemplate = async (id) => {
    try {
      setIsLoading(true);
      let response = await TemplateArchiveAction(id, "unarchive");
      console.log("archive response", response);
      if (response.status == "success") {
        setIsLoading(false);
        setarchivestate(true);
        toast.success("Template Un-archived Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong with templates";
      toast.error(message);
      return false;
    }
  };
  /* Get Templates Response */
  const getTemplates = async (e) => {
    try {
      setIsLoading(true);
      let response = await getallTemplatesAction(e, id);
      if (!response?.statusText === "OK") {
        throw {
          message: response.message || "Something went wrong with company",
        };
      }
      if (response.status == 200) {
        setIsLoading(false);
        console.log("dfgdsg", response?.data?.data?.templates?.data);
        settemplate(response?.data?.data);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong with templates";
      toast.error(message);
      return false;
    }
  };

  /* Get Archive Templates Response */
  const getArchiveTemplates = async (e) => {
    try {
      setIsLoading(true);
      let response = await ArchiveTemplateAction(e);
      if (!response?.statusText === "OK") {
        throw {
          message: response.message || "Something went wrong with company",
        };
      }
      if (response.status == 200) {
        setIsLoading(false);
        settemplate(response?.data?.data);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong with templates";
      toast.error(message);
      return false;
    }
  };

  /* For Search */
  const inputHandler = async (e) => {
    try {
      var searchval = e.target.value;
      let response = await TemplateSearchAction(searchvalue,searchval);
      if (response.status == 200) {
        settemplate(response?.data?.data);
      }
    } catch {
      return false;
    }
  };

  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content ds-content-two">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar import-temp-top">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              <div className="search-box">
                <input
                  type="text"
                  className="sr-bar"
                  placeholder="Search"
                  onChange={(e) => inputHandler(e)}
                />
                <button>
                  <img src={search} />
                </button>
              </div>
            </div>

            <RightHeader setIsLoading={setIsLoading} />
            <RightMobileHeader setIsLoading={setIsLoading} />
          </div>

          <div className="ds-heading">
            {" "}
            <h2>Templates</h2>
            {/* <a
              onClick={(e) => showarchive()}
              variant="primary"
              className="blue-btn-fill"
            >
              Show Archive
            </a> */}
            {/* <Switch checked={hidearchive} onChange={(e) => showarchive()} /> */}
            {/* <Switch checked={template} onChange={(e) => showarchive(e,e.target.checked)}/>
            Show*/}
            <div className="right">
              <span className="switch-btn">
                <Switch
                  defaultChecked={template}
                  //  handleToggle={() => settemplate(ArchiveTemplates)}
                  onChange={(e) => showarchive(e.target.checked)}
                />
                Show Archived
              </span>
              <a
                onClick={(e) => navigate("/create-template")}
                variant="primary"
                className="blue-btn-fill"
              >
                Create new template
              </a>
            </div>
          </div>

          <div className="dashboard-box inner-pages-bx">
            <div className="search-box srch-mobile">
              <input
                type="text"
                className="sr-bar"
                placeholder="Search"
                onChange={(e) => inputHandler(e)}
              />
              <button>
                <img src={search} />
              </button>
            </div>

            <div className="fund-listing-main">
              {console.log("templagk", template)}
              {template?.templates?.data?.length > 0 &&
                template?.templates?.data.map((index) => {
                  return (
                    <div className="fund-box" key={index?._id}>
                      <div className="fund-heading">
                        <Link to={`/admintemplate-detail/${index?._id}`}>
                          <h4>
                            {index?.name}{" "}
                            {/* {index?.archive_status == "1" ? "(Archived)" : " "} */}
                          </h4>
                        </Link>
                        <div className="hd-right">
                          <div className="date-bx">
                            <span className="time-ico">
                              <img src={clock} />
                            </span>{" "}
                            {moment(index?.created_at).format("MMMM DD, YYYY")}
                          </div>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {" "}
                              <img src={dots} />{" "}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                href={`/admintemplate-detail/${index?._id}`}
                              >
                                <span className="ico-drop">
                                  <img src={eye} />
                                </span>{" "}
                                View
                              </Dropdown.Item>
                              <Dropdown.Item
                                href={`/editTemplate-detail/${index?._id}`}
                              >
                                <span className="ico-drop">
                                  <img src={edit} />
                                </span>{" "}
                                Edit
                              </Dropdown.Item>
                              {index?.archive_status == "0" ? (
                                <Dropdown.Item
                                  onClick={(e) => archivetemplate(index?._id)}
                                >
                                  <span className="ico-drop">
                                    <img src={archive} />
                                  </span>{" "}
                                  Archive
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={(e) => unarchivetemplate(index?._id)}
                                >
                                  <span className="ico-drop">
                                    <img src={archive} />
                                  </span>{" "}
                                  Un-archive
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                onClick={(e) => setDeleteModel(e, index?._id)}
                                href="javascript:void(0)"
                              >
                                <span className="ico-drop">
                                  <img src={trash} />
                                </span>{" "}
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      {/* <span className="template-status">Published</span> */}
                      {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book
                      </p> */}
                    </div>
                  );
                })}
              {template?.templates?.data?.length == 0 ? (
                <div className="nofound">No data found</div>
              ) : (
                ""
              )}
              {template?.templates?.data?.length > 0 ? (
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={template?.templates?.current_page}
                  itemsCountPerPage={template?.templates.per_page}
                  totalItemsCount={template?.templates.total}
                  pageRangeDisplayed={3}
                  onChange={(e) => getTemplates(e)}
                  innerClass="pagination justify-content-end"
                  prevPageText="Prev"
                  nextPageText="Next"
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
      </div>

      {/* create fund popup */}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input type="name" placeholder="Name" />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Description
              </label>
              <textarea
                className="description"
                placeholder="Add Description"
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => setModalShow(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button variant="primary" className="save-btn">
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create fund popup close */}
      {/* Delete Model */}
      <DeleteTemplateModel
        DeleteShow={DeleteShow}
        setDeleteShow={setDeleteShow}
        id={id}
        setUpdateState={setUpdateState}
        setIsLoading={setIsLoading}
      />
      {/* Assign popup */}

      <Modal
        show={AssignShow}
        onHide={() => setAssignShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Assign to</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input type="name" placeholder="Name" />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input type="name" placeholder="Email" />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => setAssignShow(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button variant="primary" className="save-btn">
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Assign popup close */}

      {/* left-sidebar */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* left-sidebar close */}
    </div>
  );
};

export default Templates;
