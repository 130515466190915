import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import arrow from "./images/arrow.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import download from "./images/download.svg";
import ufund from "./images/user-fund.png";
import usetting from "./images/user-settings.png";
import back from "./images/back-ico.svg";
import edit from "./images/edit-ico.svg";
import archive from "./images/archive.svg";
import drop from "./images/drop.svg";
import duplicate from "./images/duplicate.svg";
import trash from "./images/trash.svg";
import assign from "./images/assign.svg";
import raccess from "./images/r-access.svg";
import template from "./images/template.svg";
import plus from "./images/plus.svg";
import Pagination from "react-js-pagination";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import {
  getInvestorsAction,
  InvestorSearchAction,
} from "./util/actions/investoractions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import RightHeader from "./components/rightHeader";
import DeleteModel from "./Model/DeleteModel";
import Spinner from "./spinner";
import RightMobileHeader from "./components/rightMobileHeader";

const InvestorLog = () => {
  const navigate = useNavigate();
  toast.configure();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [DeleteShow, setDeleteShow] = useState(false);
  const [investors, setInvestors] = useState("");
  const [updatestate, setUpdateState] = useState(false);
  const [id, setid] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  useEffect(() => {
    if (UserRole == "super-admin" || UserRole == "fund-manager") {
      getInvestors();
    }
  }, [updatestate]);

  /* Set Delete Model */
  const setDeleteModel = (e, id) => {
    setDeleteShow(true);
    setid(id);
  };

  /* Get Investor Log */
  const getInvestors = async (e) => {
    try {
      setIsLoading(true);
      let response = await getInvestorsAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setInvestors(response?.data?.data);
        console.log("investors", response?.data?.data);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      toast.error(message);
      return false;
    }
  };

  /* For Search */
  const inputHandler = async (e) => {
    try {
      var searchval = e.target.value;
      let response = await InvestorSearchAction(searchval);
      if (response.status == 200) {
        setInvestors(response?.data?.data);
      }
    } catch {
      return false;
    }
  };

  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content ds-content-two">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar import-temp-top">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              <div className="search-box">
                <input type="text" className="sr-bar" placeholder="Search" onChange={(e)=>inputHandler(e)} />
                <button>
                  <img src={search} />
                </button>
              </div>
            </div>

            <RightHeader setIsLoading={setIsLoading}/>
            <RightMobileHeader setIsLoading={setIsLoading} />
          </div>
          <div className="ds-heading">
            {" "}
            <h2>
              <a href="/dashboard" className="back-btn">
                <img src={back} />
              </a>{" "}
              Investors List
            </h2>
            {/* <a href='#' className='share-btn' onClick={ ()=> setModalShow(true)}><span><img src={plus}/></span> New Fund Manager</a> */}
          </div>
          <div className="dashboard-box">
            <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" onChange={(e)=>inputHandler(e)}/>
              <button>
                <img src={search} />
              </button>
            </div>

            <div className="fund-listing-main">
              <div className="data-activity">
                <Table responsive="xl">
                  <thead>
                    <tr>
                      <th className="th-one">Profile</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Logged in Time</th>
                      {UserRole == "super-admin" ? (
                        <th className="th-two text-center">Action</th>
                      ) : (
                        " "
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {investors?.total > 0 &&
                      investors?.investors?.data?.map((index) => {
                        return (
                          <tr>
                            <td>
                              {index?.avatar ? (
                                <div className="inv-name-image">
                                  <img src={index?.avatar} />
                                </div>
                              ) : (
                                <div className="inv-name">
                                  {" "}
                                  <span
                                    className="name-bx "
                                    style={{ background: index?.color }}
                                  >
                                    {" "}
                                    {index?.name?.charAt(0).toUpperCase()}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>{index?.name?.charAt(0).toUpperCase() + index?.name.slice(1)}</td>
                            <td>{index?.email}</td>
                            <td>
                              {index?.date == ""
                                ? moment(index?.joined_at).format(
                                    "MMMM D, YYYY"
                                  )
                                : moment(index?.date).format("MMMM D, YYYY")}
                            </td>
                            <td>
                              {index?.time == ""
                                ? moment(index?.joined_at).format("hh:mm A")
                                : moment(index?.time).format("hh:mm A")}
                            </td>
                            <td>
                              {index?.logged_in_time == ""
                                ? "Not Login Yet"
                                : index?.logged_in_time}
                            </td>
                            {UserRole == "super-admin" ? (
                              <td className="t-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {" "}
                                    <img src={dots} />{" "}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        setDeleteModel(e, index?._id)
                                      }
                                    >
                                      <span className="ico-drop">
                                        <img src={trash} />
                                      </span>{" "}
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {investors?.total == 0 ? <div className="nofound">No data found</div> : "" }
                {
                    investors.total > 0 ?
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={investors?.current_page}
                        itemsCountPerPage={investors.per_page}
                        totalItemsCount={investors.total}
                        pageRangeDisplayed={3}
                        onChange={(e)=>getInvestors(e)}
                        innerClass="pagination justify-content-end"
                        prevPageText="Prev"
                        nextPageText="Next"
                    /> : ""
                }
              </div>
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
      </div>

      {/* Delete popup */}
      {/* Delete popup */}

      <DeleteModel
        DeleteShow={DeleteShow}
        setDeleteShow={setDeleteShow}
        id={id}
        setUpdateState={setUpdateState}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />

      {/* Delete popup close */}

      {/* Delete popup close */}

      {/* left-sidebar */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* left-sidebar close */}
    </div>
  );
};

export default InvestorLog;
