import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import fetchClient from "./util/axios";
import axios from "axios";
import Spinner from "./spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";
fetchClient(App);

/* For POSt requests */
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 500) {
      toast.configure();
      toast.error(error?.response?.data?.message);
    }
    if (error.response.status === 401) {
      localStorage.clear();
      Navigate("/");
      window.location.reload(true);
    }
    if (error.response.status === 403) {
      toast.configure();
      toast.error(error?.response?.data?.message,{autoClose:200});
    }
    if (error.response.status === 400) {
      toast.configure();
      toast.error(error?.response?.data?.message,{autoClose:1000});
    }
    if (error.response.status === 404) {
      toast.configure();
      toast.error(error?.response?.data?.message,{autoClose:200});
    }
    if (error.response.status === 498) {
      toast.configure();
      toast.error(error?.response?.data?.message,{autoClose:200});
    }
    if (error) {
      toast.configure();
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
