import React, { useEffect, useState, useContext } from "react";
import clock from "../images/clock.svg";
import trash from "../images/trash.svg";
import assign from "../images/assign.svg";
import eye from "../images/eye.svg";
import dots from "../images/dots.svg";
import edit from "../images/edit-ico.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { deleteCompanybyIdAction } from "../util/actions/companiesactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AssignModel from "../Model/AssignModel";
import DeleteModel from "../Model/DeleteModel";
import Pagination from "react-js-pagination";
import { CompanyListContext } from "../company-list";
import EditCompanyModel from "../Model/EditCompanyModel";

const Company = ({
  companies,
  setUpdateState,
  setIsLoading,
  setcompanybyidupdate,
  getCompanies,
}) => {
  toast.configure();
  const listdata = useContext(CompanyListContext);
  const [DeleteShow, setDeleteShow] = useState(false);
  const [id, setid] = useState("");
  const [companyModel, setcompanyModel] = useState(false);
  const [AssignShow, setAssignShow] = useState(false);
  const [Company, setCompany] = useState("");
  const navigate = useNavigate();

  /* Set Company Model */
  const setCompanyModel = (index) => {
    setCompany(index);
    setcompanyModel(true);
  };
  /* Set Delete Model */
  const setDeleteModel = (e, id) => {
    setDeleteShow(true);
    setid(id);
  };
  /* set Assign Model */
  const setAssignModel = (e, id) => {
    setAssignShow(true);
    setid(id);
  };

  /* For Delete Company */
  const DeleteCompany = async (e) => {
    try {
      let response = await deleteCompanybyIdAction(id);
      if (response.status === 200) {
        console.log("companies id", id);
        toast.success("Company Deleted", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUpdateState(false);
        setDeleteShow(false);

        navigate("/company-list");
      }
    } catch (err) {
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong with company delete";
      toast.error(message);
      return false;
    }
  };

  return (
    <>
      <div className="company-list-main">
        {companies?.companies?.total > 0 &&
          companies?.companies?.data?.map((index) => {
            return (
              <div className="c-name-bar mb-two" key={index?._id}>
                <div className="fund-heading">
                  <h4>
                    <a
                      onClick={(e) => navigate(`/company-detail/${index?._id}`)}
                      href="javascript:void(0)"
                    >
                      {index?.name}{" "}
                    </a>
                  </h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      {moment(index?.created_at).format("MMMM DD, YYYY")}
                    </div>
                    {/* <a href="#" className="assign-dt blue-circle ml-10">
                      JD
                    </a> */}
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        <img src={dots} />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => setAssignModel(e, index?._id)}
                          href="javascript:void(0)"
                        >
                          <span className="ico-drop">
                            <img src={assign} />
                          </span>{" "}
                          New Fund Manager
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) =>
                            navigate(`/company-detail/${index?._id}`)
                          }
                        >
                          <span className="ico-drop">
                            <img src={eye} />
                          </span>{" "}
                          View
                        </Dropdown.Item>

                        <Dropdown.Item onClick={(e) => setCompanyModel(index)}>
                          <span className="ico-drop">
                            <img src={edit} />
                          </span>{" "}
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setDeleteModel(e, index?._id)}
                        >
                          <span className="ico-drop">
                            <img src={trash} />
                          </span>{" "}
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            );
          })}
        {companies?.companies?.total > 0 ? (
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={companies?.companies?.current_page}
            itemsCountPerPage={companies?.companies?.per_page}
            totalItemsCount={companies?.companies?.total}
            pageRangeDisplayed={3}
            onChange={(e) => getCompanies(e)}
            innerClass="pagination justify-content-end"
            prevPageText="Prev"
            nextPageText="Next"
          />
        ) : (
          ""
        )}
        {/* Assign popup */}

        <AssignModel
          setAssignShow={setAssignShow}
          AssignShow={AssignShow}
          id={id}
          setIsLoading={setIsLoading}
        />
        {/* Assign popup close */}
        {/* Edit company Model open */}
        <EditCompanyModel
          companyModel={companyModel}
          setcompanyModel={setcompanyModel}
          setUpdateState={setUpdateState}
          setIsLoading={setIsLoading}
          Company={Company}
          setcompanybyidupdate={setcompanybyidupdate}
        />

        {/*Delete Model start */}
        <Modal
          show={DeleteShow}
          onHide={() => setDeleteShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="create-popup">
              <div className="mb-3">
                <p>Are you want to delete</p>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="cancel-btn"
              onClick={() => setDeleteShow(false)}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              variant="primary"
              className="save-btn"
              onClick={(e) => DeleteCompany(e)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delete Model close */}
      </div>
    </>
  );
};

export default Company;
