import React, { useEffect, useState, memo } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FundInviteUserAction,
  getFundManagerslist,
  fundManagerInviteAction,
} from "../util/actions/fundactions";
import Select from "react-select";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import NewFundsAssignModel from "./NewFundsAssignModel";
import plus from "../images/plus.svg";

const UserAssignModel = (props) => {
  const {
    setNewFundsAssignShow,
    AssignShow,
    setAssignShow,
    UserAssignShow,
    setUserAssignShow,
    fundid,
    fund,
    userfunds,
    setIsLoading,
    fundmanager,
    setfundmanager
  } = props;

  const [values, setValues] = useState({
    name: "",
    email: "",
    fund_id: "",
    user_ids: "",
    role: "fund-manager",
  });
  // const [fundmanager, setfundmanager] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    fund_id: fund?._id,
    user_ids:"",
    role: "fund-manager",
  });

  console.log("fundid",fund?._id)
  // useEffect(() => {
  //   getFundManagers();
  // }, []);

  toast.configure();
  useEffect(() => {
    setValues({
      ...values,
      fund_id: fund?._id,
    });
  }, [UserAssignShow, userfunds]);

  let options =
    fundmanager?.length > 0 &&
    fundmanager?.map((item, index) => {
      return { label: item?.email , value: item?._id  };
    });  


  /* Clear Company form */
  const clearModelform = () => {
    setValues({
      name: "",
      email: "",
      role: "fund-manager",
      user_ids:""
    });
  };
  /* For cancel Click */
  const handleCancel = () => {
    setUserAssignShow(false);
    clearModelform();
    setErrors({
      ...errors,
      user_ids: null,
    });
  };
  /* For Assign fund manager mail send  */
  const handleSubmit = async (e) => {
    try {
      // e.preventDefault();
      if (values.name === "" || values.email === "") {
      
        setErrors({
          ...errors,
          name: values?.name === "" ? "Please enter name" : errors.name,
          email: values?.email === "" ? "Please enter email" : errors.email,
        });
        return false;
      }
      setIsLoading(true);
      let response = await FundInviteUserAction(values);

      if (response.status === 200) {
        clearModelform(true);
        setIsLoading(false);
        toast.success("Email sent to user", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUserAssignShow(false);
      }
      return;
    } catch (err) {
      setIsLoading(false);

      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during sent email.";
      setErrors({
        ...errors,
        name:
          message?.name?.length > 0 ? message?.name.map((index) => index) : " ",
        email:
          message?.email?.length > 0
            ? message?.email.map((index) => index)
            : " ",
      });
    }
  };

  /* Get Fund Mangers list */
  const getFundManagers = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundManagerslist(values);
      if (response.status === 200) {
        setIsLoading(false);
        setfundmanager(response?.data?.data?.managers);
        console.log("response", response);
      }
      return;
    } catch (err) {
      setIsLoading(false);
    }
  };

  /* Enter Press form Submit */
  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      // e.preventDefault();
    }
  };

  /* For Assign fund manager mail send  */
  const sendMangerInvite = async (e) => {
    try {
      // e.preventDefault();
      // if (values?.user_ids?.trim() === "") {
        
      //   setErrors({
      //     ...errors,
      //     user_ids: values?.user_ids.trim() === "" ? "Please select fund manager" : errors?.user_ids,
      //   });
      //   return false;
      // }
      setIsLoading(true);
     
      let response = await fundManagerInviteAction(values);
      if (response?.status === 200) {
        clearModelform(true);
        setIsLoading(false);
        toast.success("Invite sent successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUserAssignShow(false);
        setNewFundsAssignShow(false)
      }
      return;
    } catch (err) {
      setIsLoading(false);
      let message = err
      ? err?.response?.data?.message?.user_ids[0]
      : "Something went wrong during sent email.";
      setErrors({
        ...errors,
        user_ids:message
      });
    }
  };

  return (
    <>
      <Modal
        show={UserAssignShow}
        onHide={() => setUserAssignShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Assign Fund to Fund Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <div className="assign-new">
                {/* <div className="right-bx">
                <a
                  className="share-btn"
                  onClick={(e) => {
                    return (
                      setUserAssignShow(false),
                      setNewFundsAssignShow(true)
                    );
                  }}
                  href="javascript:void(0)"
                >
                  <span>
                    <img src={plus} />
                  </span>{" "}
                </a>
              </div> */}
              </div>
              <div className="assign-new">
                <a
                  href="javascript:void(0)"
                  onClick={(e) => {
                    return (
                      setUserAssignShow(false), setNewFundsAssignShow(true)
                    );
                  }}
                >
                  <span>
                    <img src={plus} />
                  </span>{" "}
                  Add Fund Manager
                </a>
              </div>
              {/* <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label> */}
              {/* <input
                type="name"
                placeholder="Name"
                value={values.name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: null,
                  });
                  setValues({ ...values, name: e.target.value });
                }}
                onKeyPress={handleKeypress}
              /> */}
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Please Select Fund Manager
              </label>
              {/* <input
                className="email"
                placeholder="Email"
                value={values.email}
                onChange={(e) => {
                  setValues({ ...values, email: e.target.value });
                  setErrors({ ...errors, email: null });
                }}
                onKeyPress={handleKeypress}
              /> */}
              <Select
                value={values?.user_ids}
                options={options}
                isMulti
                onChange={(e) => {
                  setValues({ ...values, user_ids: e });
                  setErrors({ ...errors, user_ids: null });
                }}
                placeholder="Select Fund Manager"
                onKeyPress={handleKeypress}
              />
              {errors.user_ids && <span className="error">{errors?.user_ids}</span>}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => handleCancel()}
          >
            Cancel{" "}
          </Button>
          <Button
            variant="primary"
            className="save-btn"
            onClick={sendMangerInvite}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserAssignModel;
