import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link} from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import search from "./images/search.svg";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import { Button, Offcanvas } from "react-bootstrap";
import Fund from "./components/Fund";
import { getFundsAction, FundsSearchAction } from "./util/actions/fundactions";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import InvestorSidebarFunds from "./components/investorsidebarfunds";
import RightSidebarFunds from "./components/RightSidebarfunds";
import Spinner from "./spinner";


const FundListing = () => {
  const [showRight, setRightShow] = useState(false);
  const [show, setShow] = useState(false);
  const [updatestate, setUpdateState] = useState(true);
  const [archivestate, setarchivestate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [funds, setfunds] = useState("");
  const navigate = useNavigate();
  const handleclick = () => {
    navigate("/fund-listing");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);

  useEffect(() => {
    getFunds();
  }, [archivestate]);

  /* get Funds */
  const getFunds = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundsAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setfunds(response?.data?.data);
        setUpdateState(true);
        console.log("funds", response?.data?.data?.funds);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      console.log(message);
      return false;
    }
  };
   /* For Search */
   const inputHandler = async (e) => {
    try {
    var searchval = e.target.value;
    let response = await FundsSearchAction(searchval);
    if (response.status == 200) {
      setfunds(response?.data?.data);
    }}
    catch{
      return false;
    }
  }

  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              <div className="search-box">
                <input type="text" className="sr-bar" placeholder="Search" onChange={(e)=>inputHandler(e)}/>
                <button>
                  <img src={search} />
                </button>
              </div>
            </div>
            <RightMobileHeader handleRightShow={handleRightShow} setIsLoading={setIsLoading}/>
          </div>
          <div className="ds-heading">
            <h2>Fund Listing</h2>
          </div>
          <div className="dashboard-box">
            <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" onChange={(e)=>inputHandler(e)}/>
              <button>
                <img src={search} />
              </button>
            </div>

            {/* Start Funds */}
            <Fund
              handleclick={handleclick}
              funds={funds}
              setUpdateState={setUpdateState}
              setIsLoading={setIsLoading}
              setarchivestate={setarchivestate}
              getFunds={getFunds}
            />
            {/* end Funds */}
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
        <div className="right-bar">
          <RightHeader setIsLoading={setIsLoading} />
          <InvestorSidebarFunds funds={funds?.funds}
              setUpdateState={setUpdateState}
              setIsLoading={setIsLoading}/>
          {/* <RightSidebarFunds funds={funds} /> */}
          {/* <div className="fund-right-list">
            <ul>
              {funds?.length > 0 &&
                funds?.map((index) => {
                  return (
                    <li key={funds?.key}>
                      {" "}
                      <a
                        onClick={(e) => navigate(`/fund-details/${index?.id}`)}
                        href="#"
                        className="f-list"
                      >
                        {index?.name}
                      </a>{" "}
                    </li>
                  );
                })}
            </ul>
          </div> */}
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide */}

      <Offcanvas
        show={showRight}
        className="right-bar-two"
        onHide={handleRightClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="right-bar">
            <RightHeader setIsLoading={setIsLoading}/>
            <InvestorSidebarFunds funds={funds?.funds}
              setUpdateState={setUpdateState}
              setIsLoading={setIsLoading} />
            {/* <div className="fund-right-list">
            <ul>
              {funds?.length > 0 &&
                funds?.map((index) => {
                  return (
                    <li key={funds?.key}>
                      {" "}
                      <a
                        onClick={(e) => navigate(`/fund-details/${index?.id}`)}
                        href="#"
                        className="f-list"
                      >
                        {index?.name}
                      </a>{" "}
                    </li>
                  );
                })}
            </ul>
          </div> */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide close */}
    </div>
  );
};

export default FundListing;
