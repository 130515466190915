import React, { useEffect, useState } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InviteSendByID } from "../util/actions/companiesactions";
import {
  AssignAccessAction,
  getFundsInfoAction,
} from "../util/actions/fundactions";

const AssignAccessModel = ({
  AssignShow,
  setAssignShow,
  id,
  setIsLoading,
  funds,
}) => {
  const [values, setValues] = useState({
    fund_id: "",
    user_id: "",
  });
  const [errors, setErrors] = useState({
    fund_id: "",
    user_id: "",
  });
  toast.configure();

  /* Clear Model form */
  const clearModelform = () => {
    setValues({
      fund_id: "",
      user_id: "",
    });
    setErrors({
      ...errors,
      fund_id: values.fund_id === "",
      user_id: values.user_id === "",
    });
  };

  /* For cancel Click */
  const handleCancel = () => {
    setAssignShow(false);
    clearModelform();
  };

  /* For Assign Access  */
  const handleSubmit = async (e) => {
    try {
      // e.preventDefault();
      if (values.fund_id === "") {
        setErrors({
          ...errors,
          fund_id:
            values.fund_id === "" ? "Please select fund" : errors.fund_id,
        });
        return false;
      }
      let response = await AssignAccessAction(values);
      console.log("res", response);
      setIsLoading(true);
      if (response.status === 200) {
        clearModelform(true);
        setIsLoading(false);
        toast.success("Assign Access Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAssignShow(false);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during sent email.";
      setErrors({
        ...errors,
        fund_id: message,
      });

      return false;
    }
  };

  /* For enter Keypress Submit form */
  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };

  return (
    <Modal
      show={AssignShow}
      onHide={() => setAssignShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Assign Access </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="create-popup">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Select Funds
            </label>
            <select
              value={values?.fund_id}
              id={values?.fund_id}
              onChange={(e) => {
                setValues({ ...values, fund_id: e.target.value, user_id: id });
                setErrors({
                  ...errors,
                  fund_id: null,
                });
              }}
              onKeyPress={handleKeypress}
            >
              <option value="select fund">Select Fund</option>
              {funds?.length > 0 &&
                funds?.map((item, index) => {
                  return (
                    <option key={item?._id} value={item?._id} id={item?._id}>
                      {item?.name}{" "}
                    </option>
                  );
                })}
            </select>
            {errors.fund_id && <span className="error">{errors.fund_id}</span>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={(e) => handleCancel(e)}
        >
          {" "}
          Cancel{" "}
        </Button>
        <Button variant="primary" className="save-btn" onClick={handleSubmit}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignAccessModel;
