import React, { useState } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeleteTemplateAction} from "../util/actions/allactions";
import { useParams } from "react-router-dom";

const DeleteTemplateModel = ({
  DeleteShow,
  setDeleteShow,
  id,
  setUpdateState,
  setIsLoading,
  setarchivestate
}) => {
  /* For Delete Fund  */
  const DeleteTemplate = async () => {
    try {
      setIsLoading(true)
      let response = await DeleteTemplateAction(id);
      if (response.status === 200) {
        setIsLoading(false)
        console.log("delete", response);
        toast.success("Template Deleted", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setDeleteShow(false);
      setUpdateState(false);
      setarchivestate(true)
      //   navigate("/");
    } catch (err) {
      setIsLoading(false)
      let message = err
      ? err?.response?.data?.message?.name[0]
      :  "Something went wrong ";
      toast.error(message); 
      return false;
    }
  };
  return (
    <>
      <Modal
        show={DeleteShow}
        onHide={() => setDeleteShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <p>Are you sure want to delete</p>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => setDeleteShow(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
            <Button
              variant="primary"
              className="save-btn"
              onClick={(e) => DeleteTemplate(e)}
            >
              Delete
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteTemplateModel;
