import React, { useState } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CreateFundAction, getFundsAction } from "../util/actions/fundactions";

const CreateAdminFundModel = ({
  setModalShow,
  modalShow,
  setUpdateState,
  id,
  setIsLoading,
}) => {
  const [values, setValues] = useState({
    name: "",
    description: "",
    company_id: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });
  toast.configure();

  /* Clear Company form */
  const clearfundform = () => {
    setValues({
      name: "",
      description: "",
    });
  };
  /* For create funds  */
  const handleSubmit = async (e) => {
    try {
    
      if (values.name === "") {
        setErrors({
          ...errors,
          name: values?.name === "" ? "Please enter name" : errors.name,
        });
        return false;
      }
      setIsLoading(true);
      values.company_id = id;
      let response = await CreateFundAction(values);
      console.log("fundresponse", response);
      if (response.status === 200) {
        clearfundform(true);
        setIsLoading(false);
        toast.success("fund Created", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUpdateState(true);
       
        setModalShow(false);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during login";
      setErrors({
        ...errors,
        name: message?.name?.length>0 && message?.name[0],
        description:message?.description?.length>0 && message?.description[0]
      });
      return false;
    }
  };
  /* Enter Press form submit */
  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Create Fund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="create-popup">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Name
            </label>
            <input
              type="name"
              placeholder="Name"
              value={values.name}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  name: null,
                });
                setValues({ ...values, name: e.target.value });
              }}
              onKeyPress={handleKeypress}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Description
            </label>
            <textarea
              className="description"
              placeholder="Add Description"
              value={values.description}
              onChange={(e) => {
                setValues({ ...values, description: e.target.value });
                setErrors({ ...errors, description: null });
              }}
              onKeyPress={handleKeypress}
              // maxLength="500"
            ></textarea>
            {errors?.description && (
              <span className="error">{errors?.description}</span>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={() => setModalShow(false)}
        >
          {" "}
          Cancel{" "}
        </Button>
        <Button
          variant="primary"
          className="save-btn"
          onClick={(e) => handleSubmit(e)}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CreateAdminFundModel;
