import moment from "moment";
import edit from "../images/edit-ico.svg";
import trash from "../images/trash.svg";
import { Button } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { sectionList } from "../util/commonFunctions";
import { useState, useRef } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import draftToHtml from "draftjs-to-html";
import { fileExtension } from "./TemplateEditView_new";
import { downloadFile } from "../create-template";
const TemplateEditView = (props) => {
  // const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const {
    questionAnswerArray,
   
  } = props;
  const refs = useRef([]);
  const [copySuccess, setCopySuccess] = useState("");
  const [selectedIndex, setselectedIndex] = useState(-1);


  let serialNumber = 1;
  const CopyQuestionAnswer = (index) => {
    console.log(index, "selected copy answer");
    setselectedIndex(index);
    navigator.clipboard.writeText(refs?.current[index]?.innerText);
    console.log("textcopyRef", refs?.current[index]);
    clearTimeout(() => {
      setCopySuccess("");
    });
    if (selectedIndex) {
      setCopySuccess("Copied!");
    }
    setTimeout(() => {
      setCopySuccess("");
      setselectedIndex(-1);
    }, 500);
  };

  let refIndex = 0;

  return (
    <>
      {questionAnswerArray?.length > 0 &&
        questionAnswerArray?.map((parentItem, parentIndex) => {
          return (
            <div className="template-view-holder">
              <h4 style={{ textAlign: "center" }}>{parentItem?.section}</h4>

              {parentItem?.data?.length > 0 &&
                parentItem?.data?.map((subheadingItem, subheadingIndex) => {
                  return (
                    <>
                      <h5>{subheadingItem?.sub_heading.value}</h5>
                        {console.log("parentItem",questionAnswerArray)}
                      {subheadingItem?.questions?.length > 0 &&
                        subheadingItem?.questions?.map((item, index) => {
                          return (
                            <>
                            {console.log("itemitemitemitem",item?.answers)}
                              <div className="ques-bar-main">
                                { item?.type == "text" && item?.show && (
                            
                                  <div className="ques-left">
                                    {`${parentIndex}${subheadingIndex}${index}` ==
                                      selectedIndex && (
                                       
                                      <span className="copysuccess">
                                        {copySuccess}
                                      </span>
                                    )}
                                  { item?.answers[0]?.label !== null ?
                                    <span
                                      className="copyicon s"
                                      onClick={(e) =>
                                        CopyQuestionAnswer(
                                          `${parentIndex}${subheadingIndex}${index}`
                                        )
                                      }
                                    >
                                      <ContentCopyIcon></ContentCopyIcon>
                                    </span>
                                    :  " " }
                                    <h5>
                                      {`${serialNumber}:`} {item?.question}
                                    </h5>
                                    <div className="sp-btn ans-bx">
                                      {" "}
                                      <span
                                        ref={(element) => {
                                          refs.current[
                                            `${parentIndex}${subheadingIndex}${index}`
                                          ] = element;
                                        }}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item?.answers[0]?.label,
                                          }}
                                        />
                                      </span>
                                      {item?.attachFile && (
                                       
                                        <div className="sp-btn ans-bx r">
                                           <a href={item?.attachFile?.path} target="_blank" >
                                          {item?.attachFile && (
                                            <span className="at-file">
                                              {fileExtension(item?.attachFile)}

                                              <p>{item?.attachFile.filename}</p>
                                            </span>
                                          )}
                                           </a>
                                        </div>
                                        
                                      )}
                                    </div>
                                    <br />
                                  </div>
                                )}

                                {item?.type == "date" && item?.show && (
                                  <div className="ques-left">
                                    {selectedIndex != -1 &&
                                      `${parentIndex}${subheadingIndex}${index}` ==
                                        selectedIndex && (
                                        <span className="copysuccess">
                                          {copySuccess}
                                        </span>
                                      )}
                                    <span
                                      className="copyicon"
                                      onClick={(e) =>
                                        CopyQuestionAnswer(
                                          `${parentIndex}${subheadingIndex}${index}`
                                        )
                                      }
                                    >
                                      <ContentCopyIcon></ContentCopyIcon>
                                    </span>
                                    <h5>
                                      {`${serialNumber}:`} {item?.question}
                                    </h5>
                                    <div className="sp-btn ans-bx">
                                      <span
                                        ref={(element) => {
                                          refs.current[
                                            `${parentIndex}${subheadingIndex}${index}`
                                          ] = element;
                                        }}
                                      >
                                        {!item?.answers[0]?.label == ""
                                          ? moment(
                                              item?.answers[0]?.label
                                            )?.format("DD/MM/YYYY")
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {item?.type == "multiselect" && item?.show && (
                                  <div className="ques-left">
                                    {selectedIndex != -1 &&
                                      `${parentIndex}${subheadingIndex}${index}` ==
                                        selectedIndex && (
                                        <span className="copysuccess">
                                          {copySuccess}
                                        </span>
                                      )}
                                    <span
                                      className="copyicon"
                                      onClick={(e) =>
                                        CopyQuestionAnswer(
                                          `${parentIndex}${subheadingIndex}${index}`
                                        )
                                      }
                                    >
                                      <ContentCopyIcon></ContentCopyIcon>
                                    </span>
                                    <h5>
                                      {`${serialNumber}:`} {item?.question}
                                    </h5>

                                    <span
                                      ref={(element) => {
                                        refs.current[
                                          `${parentIndex}${subheadingIndex}${index}`
                                        ] = element;
                                      }}
                                    >
                                      {item?.answers?.length > 0 &&
                                        item?.answers?.map(
                                          (multiItem, childindex) => {
                                            return (
                                              <>
                                                <div className="sp-btn ans-bx">
                                                  {`${childindex + 1}:`}

                                                  {multiItem?.label}
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                    </span>
                                  </div>
                                )}

                                {item?.type == "dropdown" && item?.show && (
                                  <div className="ques-left">
                                    {selectedIndex != -1 &&
                                      `${parentIndex}${subheadingIndex}${index}` ==
                                        selectedIndex && (
                                        <span className="copysuccess">
                                          {copySuccess}
                                        </span>
                                      )}
                                    <span
                                      className="copyicon"
                                      onClick={(e) =>
                                        CopyQuestionAnswer(
                                          `${parentIndex}${subheadingIndex}${index}`
                                        )
                                      }
                                    >
                                      <ContentCopyIcon></ContentCopyIcon>
                                    </span>
                                    <h5>
                                      {`${serialNumber}:`} {item?.question}
                                    </h5>

                                    <span
                                      ref={(element) => {
                                        refs.current[
                                          `${parentIndex}${subheadingIndex}${index}`
                                        ] = element;
                                      }}
                                    >
                                      {item?.answers?.length > 0 &&
                                        item?.answers?.map(
                                          (multiItem, childindex) => {
                                            return (
                                              <>
                                                <div className="sp-btn ans-bx">
                                                  {multiItem?.label}
                                                </div>
                                                <br />
                                              </>
                                            );
                                          }
                                        )}
                                    </span>
                                  </div>
                                )}

                                {item?.type == "radio-button" && item?.show && (
                                  <div className="ques-left">
                                    {selectedIndex != -1 &&
                                      `${parentIndex}${subheadingIndex}${index}` ==
                                        selectedIndex && (
                                        <span className="copysuccess">
                                          {copySuccess}
                                        </span>
                                      )}
                                    <span
                                      className="copyicon"
                                      onClick={(e) =>
                                        CopyQuestionAnswer(
                                          `${parentIndex}${subheadingIndex}${index}`
                                        )
                                      }
                                    >
                                      <ContentCopyIcon></ContentCopyIcon>
                                    </span>
                                    <h5>
                                      {`${serialNumber}:`} {item?.question}
                                    </h5>
                                    <br />
                                    <></>
                                    <span
                                      ref={(element) => {
                                        refs.current[
                                          `${parentIndex}${subheadingIndex}${index}`
                                        ] = element;
                                      }}
                                    >
                                      <RadioGroup
                                         name={item?.answers[0]?.value + Math.random()}
                                        className="radio-text-holder">
                                        {item?.options?.length > 0 &&
                                          item?.options?.map(
                                            (multiItem, childindex) => {
                                              return (
                                                <div className="radio-txt">
                                                  <div className="radio-bx">
                                                    <Radio
                                                      checked={
                                                        item?.answers?.length >
                                                          0 &&
                                                        item?.answers?.find(
                                                          (childitem) =>
                                                            childitem?.value ==
                                                            multiItem?.value
                                                        )
                                                      }
                                                    />{" "}
                                                  </div>
                                                  {multiItem?.value}
                                                </div>
                                              );
                                            }
                                          )}
                                      </RadioGroup>
                                    </span>
                                  </div>
                                )}

                                {item?.type == "file-input" && item?.show && (
                                  <div className="ques-left">
                                    {selectedIndex != -1 &&
                                      `${parentIndex}${subheadingIndex}${index}` ==
                                        selectedIndex && (
                                        <span className="copysuccess">
                                          {copySuccess}
                                        </span>
                                      )}
                                    <span
                                      className="copyicon"
                                      onClick={(e) =>
                                        CopyQuestionAnswer(
                                          `${parentIndex}${subheadingIndex}${index}`
                                        )
                                      }
                                    >
                                      <ContentCopyIcon></ContentCopyIcon>
                                    </span>
                                    <h5>
                                      {`${serialNumber}:`} {item?.question}
                                    </h5>

                                    <div className="sp-btn ans-bx d">
                                      <span
                                        ref={(element) => {
                                          refs.current[
                                            `${parentIndex}${subheadingIndex}${index}`
                                          ] = element;
                                        }}
                                      >
                                        {item?.answers?.length > 0 && (
                                          <a href={item?.answers[0]?.label?.path} target="_blank" >
                                          <span className="at-file">
                                            {fileExtension(
                                              item?.answers[0]?.label
                                            )}
                                            <p>
                                              {item?.answers[0]?.label.filename}
                                            </p>
                                          </span>
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div style={{ display: "none" }}>
                                  {item?.show
                                    ? (serialNumber = serialNumber + 1)
                                    : ""}
                                </div>

                                <div style={{ display: "none" }}>
                                  <h6>{refIndex}</h6>
                                  {item?.show ? (refIndex = refIndex + 1) : ""}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      <div style={{ display: "none" }}>
                        {true ? (serialNumber = 1) : ""}
                      </div>
                    </>
                  );
                })}
            </div>
          );
        })}
        {questionAnswerArray?.length <= 0 && <span className="notemplatefound">No Data Found</span>}
    </>
  );
};

export default TemplateEditView;
