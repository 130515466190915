import React, { useEffect, useState } from "react";
import clock from "../images/clock.svg";
import eye from "../images/eye.svg";
import dots from "../images/dots.svg";
import archive from "../images/archive.svg";
import download from "../images/download.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Investorarchiveaction,
  InvestorUnarchiveaction,
} from "../util/actions/investoractions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "react-js-pagination";

const Fund = ({
  handleclick,
  funds,
  DownloadFund,
  setIsLoading,
  setUpdateState,
  setarchivestate,
  getFunds,
}) => {
  const navigate = useNavigate();
  toast.configure();
  const [fundid, setfundid] = useState("");
  const [values, setvalues] = useState({ archive: "archive" });

  /* For Archive Fund  */
  const ArchiveFund = async (id) => {
    try {
      setIsLoading(true);
      let response = await Investorarchiveaction(id, values);
      if (response.status === 200) {
        setIsLoading(false);
        console.log("fundarchive", response);
        toast.success("Fund Archieved Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUpdateState(true);
        setarchivestate(true);
      }
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during fund archive";
      return false;
    }
  };

  /* For Archive Fund  */
  const UnArchiveFund = async (id) => {
    try {
      setIsLoading(true);
      let response = await InvestorUnarchiveaction(id, values);
      if (response.status === 200) {
        setIsLoading(false);
        console.log("fundarchive", response);
        toast.success("Fund Un-archieved Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUpdateState(true);
        setarchivestate(false);
      }
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during fund archive";
      return false;
    }
  };

  return (
    <>
      <div className="fund-listing-main">
        {funds?.funds?.total > 0 &&
          funds?.funds?.data?.map((index) => {
            return (
              <div className="fund-box unread" key={index?._id}>
                <div className="fund-heading">
                  <h4>
                    <a href={`/fund-details/${index?.fund_id}`}>
                      {index?.name}
                    </a>
                  </h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      {moment(index?.created_at).format("MMMM D, YYYY")}
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        <img src={dots} />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) =>
                            navigate(`/fund-details/${index?.fund_id}`)
                          }
                        >
                          <span className="ico-drop">
                            <img src={eye} />
                          </span>{" "}
                          View
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={(e)=>DownloadFund(index?.id)} href="#/action-1">
                          <span className="ico-drop">
                            <img src={download} />
                          </span>{" "}
                          Download
                        </Dropdown.Item> */}
                        {index?.archived == "0" ? (
                          <Dropdown.Item
                            onClick={(e) => ArchiveFund(index?._id)}
                          >
                            <span className="ico-drop">
                              <img src={archive} />
                            </span>{" "}
                            Archive
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={(e) => UnArchiveFund(index?._id)}
                          >
                            <span className="ico-drop">
                              <img src={archive} />
                            </span>{" "}
                            Unarchive
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p>{index?.description}</p>
                <div className="fund-inv">
                  <p>
                    invited by:{" "}
                    <OverlayTrigger
                      placement="top-start"
                      overlay={
                        <Tooltip id="collect-tooltip" data-place="top-center">
                          {index?.invite_sender_name?.charAt(0).toUpperCase() +
                            index?.invite_sender_name?.slice(1)}
                        </Tooltip>
                      }
                    >
                      <span
                        className="name-circle "
                        style={{ background: index?.invite_sender_color }}
                      >
                        {index?.invite_sender_name?.toUpperCase().charAt(0)}
                      </span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>
            );
          })}

        {funds?.funds?.total > 0 ? (
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={funds?.funds?.current_page}
            itemsCountPerPage={funds?.funds.per_page}
            totalItemsCount={funds?.funds.total}
            pageRangeDisplayed={3}
            onChange={(e) => getFunds(e)}
            innerClass="pagination justify-content-end"
            prevPageText="Prev"
            nextPageText="Next"
          />
        ) : (
          ""
        )}

        {/* <div className="fund-box">
                <div className="fund-heading">
                  <h4>
                    <a onClick={handleclick}>Fund B</a>
                  </h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      Mar 04, 2022
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        <img src={dots} />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={eye} />
                          </span>{" "}
                          View
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={download} />
                          </span>{" "}
                          Download
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={archive} />
                          </span>{" "}
                          Archive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
                <div className="fund-inv">
                  <p>
                    invited by:{" "}
                    <span className="name-circle orange-circle">S</span>
                  </p>
                </div>
              </div> */}

        {/* <div className="fund-box">
                <div className="fund-heading">
                  <h4>
                    <a onClick={handleclick}>Fund C</a>
                  </h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      Mar 04, 2022
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        <img src={dots} />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={eye} />
                          </span>{" "}
                          View
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={download} />
                          </span>{" "}
                          Download
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={archive} />
                          </span>{" "}
                          Archive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
                <div className="fund-inv">
                  <p>
                    invited by:{" "}
                    <span className="name-circle green-circle">S</span>
                  </p>
                </div>
              </div>

              <div className="fund-box">
                <div className="fund-heading">
                  <h4>
                    <a onClick={handleclick}>Fund D</a>
                  </h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      Mar 04, 2022
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        <img src={dots} />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={eye} />
                          </span>{" "}
                          View
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={download} />
                          </span>{" "}
                          Download
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-1">
                          <span className="ico-drop">
                            <img src={archive} />
                          </span>{" "}
                          Archive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
                <div className="fund-inv">
                  <p>
                    invited by:{" "}
                    <span className="name-circle purple-circle">S</span>
                  </p>
                </div>
              </div> */}
      </div>
    </>
  );
};

export default Fund;
