import axios from "axios";

const config = require(`../environment/development.js`).config;

const fetchClient = () => {
    axios.interceptors.request.use(function (config) {
      if (localStorage.getItem("Token")) {
        const token = JSON.parse(localStorage.getItem("Token"));
        const auth_token = JSON.parse(localStorage.getItem("Auth-Token"));
        if(token){
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        config.headers['Auth-Token'] = auth_token;

        }
      }
      return config;
    });
  };
  
  export default fetchClient;
  
