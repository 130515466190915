import React, { useEffect, useState, memo } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FundInviteUserAction } from "../util/actions/fundactions";
import Select from "react-select";

const NewFundsAssignModel = (props) => {
  const {
    newFundsAssignshow,
    setNewFundsAssignShow,
    UserAssignShow,
    setUserAssignShow,
    fundid,
    fund,
    userfunds,
    setIsLoading,
  } = props;

  // useEffect(() => {
  //   setValues({ ...values, fund_id: fundid });
  // }, []);

  const [values, setValues] = useState({
    name: "",
    email: "",
    fund_id: fund?._id,
    role: "fund-manager",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    fund_id: "",
    role: "fund-manager",
  });
  toast.configure();
  useEffect(() => {
    setValues({
      ...values,
      fund_id: fund?._id,
    });
  }, [UserAssignShow, userfunds,newFundsAssignshow]);

  /* Clear Company form */
  const clearModelform = () => {
    setValues({
      name: "",
      email: "",
      role: "fund-manager",
    });
  };
  /* For cancel Click */
  const handleCancel = () => {
    setNewFundsAssignShow(false);
    clearModelform();
  };
  /* For Assign fund manager mail send  */
  const handleSubmit = async (e) => {
    try {
      // e.preventDefault();
      if (values.name === "" || values.email === "") {
        setErrors({
          ...errors,
          name: values?.name === "" ? "Please enter name" : errors.name,
          email: values?.email === "" ? "Please enter email" : errors.email,
        });
        return false;
      }
      setIsLoading(true);
      let response = await FundInviteUserAction(values);

      if (response.status === 200) {
        clearModelform(true);
        setIsLoading(false);
        toast.success("Email sent to user", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNewFundsAssignShow(false);
        setUserAssignShow(false);
        
      }
      return;
    } catch (err) {
      setIsLoading(false);

      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during sent email.";
        setErrors({
          ...errors,
          name:
            message?.name?.length > 0 ? message?.name.map((index) => index) : " ",
          email:
            message?.email?.length > 0
              ? message?.email.map((index) => index)
              : " ",
        });
    }
  };
  
  /* Enter Press form Submit */
  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };

  return (
    <Modal
      show={newFundsAssignshow}
      onHide={() => setNewFundsAssignShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Assign Fund to Fund Manager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="create-popup">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Name
            </label>
            <input
              type="name"
              placeholder="Name"
              value={values.name}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  name: null,
                });
                setValues({ ...values, name: e.target.value });
              }}
              onKeyPress={handleKeypress}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email
            </label>
            <input
              className="email"
              placeholder="Email"
              value={values.email}
              onChange={(e) => {
                setValues({ ...values, email: e.target.value });
                setErrors({ ...errors, email: null });
              }}
              onKeyPress={handleKeypress}
            />
            {/* <Select
                value={values.email}
                options={options}
                isMulti
                onChange={(e) => {
                  setValues({ ...values, email: e });
                  setErrors({ ...errors, email: null });
                }}
              /> */}
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={() => handleCancel()}
        >
          Cancel{" "}
        </Button>
        <Button variant="primary" className="save-btn" onClick={handleSubmit}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewFundsAssignModel;
