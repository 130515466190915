import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import ufund from "./images/user-fund.png";
import usetting from "./images/user-settings.png";
import template from "./images/template.svg";
import company from "./images/company-list.png";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  return (
    <div className="dashboard-main">
      <div className="sidemenu-bar">
        <div className="d-logo">
          {" "}
          <a href="/dashboard">
            <img src={logo} />
          </a>{" "}
        </div>
        <div className="dash-menu">
          <ul>
            {UserRole == "super-admin" ||
            UserRole == "fund-manager" ||
            UserRole == "investor" ? (
              <li>
                {" "}
                <a onClick={(e) => navigate("/dashboard")} href="">
                  {" "}
                  <span className="icon">
                    <img src={dashico} />
                  </span>{" "}
                  <span className="name">Dashboard</span>{" "}
                </a>{" "}
              </li>
            ) : (
              ""
            )}
            {UserRole == "super-admin" || UserRole == "fund-manager" ? (
              <li>
                {" "}
                <a onClick={(e) => navigate("/user-management")} href="">
                  {" "}
                  <span className="icon">
                    <img src={usetting} />
                  </span>{" "}
                  <span className="name">User Management</span>{" "}
                </a>{" "}
              </li>
            ) : (
              ""
            )}
            {UserRole == "super-admin" ? (
              <li>
                {" "}
                <a onClick={(e) => navigate("/company-list")} href="">
                  {" "}
                  <span className="icon">
                    <img src={company} />
                  </span>{" "}
                  <span className="name">Company Listing</span>{" "}
                </a>{" "}
              </li>
            ) : (
              ""
            )}
            {UserRole == "super-admin" || UserRole == "fund-manager" ? (
              <li>
                {" "}
                <a onClick={(e) => navigate("/fund-management-list")} href="">
                  {" "}
                  <span className="icon">
                    <img src={ufund} />
                  </span>{" "}
                  <span className="name">Fund Management</span>{" "}
                </a>{" "}
              </li>
            ) : (
              ""
            )}
            {UserRole == "super-admin" ? (
              <>
                <li>
                  {" "}
                  <a onClick={(e) => navigate("/templates")} href="">
                    {" "}
                    <span className="icon">
                      <img src={template} />
                    </span>{" "}
                    <span className="name">Templates</span>{" "}
                  </a>{" "}
                </li>
              </>
            ) : (
              ""
            )}
            {UserRole == "investor" ? (
              <li>
                {" "}
                <a onClick={(e) => navigate("/fund-listing")} href="">
                  {" "}
                  <span className="icon">
                    <img src={reportico} />
                  </span>{" "}
                  <span className="name">Reports</span>{" "}
                </a>{" "}
              </li>
            ) : (
              " "
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
