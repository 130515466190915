import React, { useState, createContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useParams } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import eye from "./images/eye.svg";
import edit from "./images/edit-ico.svg";
import archive from "./images/archive.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import back from "./images/back-ico.svg";
import download from "./images/download.svg";
import copy from "./images/copy.svg";
import share from "./images/share.svg";
import { Dropdown } from "react-bootstrap";
import plus from "./images/plus.svg";
import arrowleft from "./images/arrow-left.svg";
import {
  Modal,
  Button,
  Offcanvas,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import RightSidebarFunds from "./components/RightSidebarfunds";
import AssignModel from "./Model/AssignModel";
import { getFundsbyIdAction, getFundsAction } from "./util/actions/fundactions";
import {
  FundTemplateSharableAction,
  TemplateArchiveAction,
} from "./util/actions/allactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFundsTemplateAction,
  FundsArchiveTemplateAction,
  getFundManagerslist,
} from "./util/actions/fundactions";
import UserAssignModel from "./Model/UserAssignModel";
import moment from "moment";
import Spinner from "./spinner";
import ChooseTemplate from "./Model/choose-template";
import DeleteTemplateModel from "./Model/DeleteTemplateModel";
import Pagination from "react-js-pagination";
import Switch from "@mui/material/Switch";
// import edit from "./images/edit-ico.svg";
import trash from "./images/trash.svg";
import NewFundsAssignModel from "./Model/NewFundsAssignModel";

// import { Button } from "react-bootstrap";
const FundDetailContext = createContext();

const FundManagementDetails = () => {
  const navigate = useNavigate();
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const [AssignShow, setAssignShow] = useState(false);
  const [DeleteShow, setDeleteShow] = useState(false);
  const [UserAssignShow, setUserAssignShow] = useState(false);
  const [chooseshow, setChooseshow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ShareShow, setShareShow] = useState(false);
  const [template, settemplates] = useState(false);
  const [values, setValues] = useState({ url: "" });
  const [url, setUrl] = useState();
  const [show, setShow] = useState(false);
  const [showRight, setRightShow] = useState(false);
  const [updatestate, setUpdateState] = useState(false);
  const [funds, setfunds] = useState("");
  const [fund, setfund] = useState(false);
  const [archivestate, setarchivestate] = useState(true);
  const [templateid, settemplateid] = useState(false);
  const [importtemplate, setImporttemplate] = useState();
  const [newFundsAssignshow, setNewFundsAssignShow] = useState("");
  const [fundmanager, setfundmanager] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);

  /* get Fund id */
  const { id } = useParams();

  /* Set Delete Model */
  const setDeleteModel = (e, id) => {
    setDeleteShow(true);
    settemplateid(id);
  };

  const setUserassignModel = (item) => {
    setUserAssignShow(true);
    getFundManagers(fund?.company_id);
  };

  /* Set Archieve Templates */
  const archivetemplate = async (id) => {
    try {
      setIsLoading(true);
      let response = await TemplateArchiveAction(id, "archive");
      console.log("archive response", response);
      if (response.status == "success") {
        setIsLoading(false);

        console.log("Archive Templates", response);
        toast.success("Template Archived Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setarchivestate(false);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong with templates";
      toast.error(message);
      return false;
    }
  };

  /* Set Archieve Templates */
  const unarchivetemplate = async (id) => {
    try {
      setIsLoading(true);
      let response = await TemplateArchiveAction(id, "unarchive");
      console.log("archive response", response);
      if (response.status == "success") {
        setIsLoading(false);

        toast.success("Template UnArchived Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setarchivestate(true);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong with templates";
      toast.error(message);
      return false;
    }
  };

  console.log("template", template);
  let AllTemplates = template;
  let ArchiveTemplates = [];
  console.log("Alltemplate", AllTemplates);
  template?.total > 0 &&
    template?.data?.filter((item) => {
      if (item.archive_status == 1) {
        return ArchiveTemplates.push(item);
      } else {
        return false;
      }
    });

  /* Show Archive */
  const showarchive = (e) => {
    if (e == false) {
      getFunds(e);
      // settemplates(AllTemplates);
      // window.location.reload(false);
    } else {
      getArchiveTemplates(e);
      // let newObject = {
      //   data: ArchiveTemplates,
      // };
      // settemplates(newObject);
    }
  };

  /*Copy Share Url */
  const copyshareurl = () => {
    navigator.clipboard.writeText(values.url);
    setValues({ ...values, url: "" });
    setShareShow(false);
  };

  useEffect(() => {
    getFunds();
  }, [updatestate, archivestate]);
  useEffect(() => {
    FundsById();
  }, [updatestate]);

  // useEffect(() => {
  //   getsharelink();
  // }, []);
  /* Get Funds By Id Response */
  const FundsById = async () => {
    try {
      setIsLoading(true);
      let response = await getFundsbyIdAction(id);
      if (!response?.statusText === "OK") {
        throw {
          message: response.message || "Something went wrong with company",
        };
      }
      if (response.status == 200) {
        setIsLoading(false);
        setfund(response?.data?.data?.fund);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message ? err.message : "Something went wrong with funds";
      toast.error(message);
      return false;
    }
  };
  /* get Funds Templates */
  const getFunds = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundsTemplateAction(e, id);
      if (response.status == 200) {
        setIsLoading(false);
        setfunds(response?.data?.data?.funds);
        settemplates(response?.data?.data?.templates);
      }
    } catch (err) {
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      console.log(message);
      return false;
    }
  };

  /* get Archive Templates */
  const getArchiveTemplates = async (e) => {
    try {
      setIsLoading(true);
      let response = await FundsArchiveTemplateAction(e, id);
      if (response.status == 200) {
        setIsLoading(false);

        setfunds(response?.data?.data?.funds);
        settemplates(response?.data?.data?.templates);
      }
    } catch (err) {
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      console.log(message);
      return false;
    }
  };

  useEffect(() => {
    getallFunds();
  }, [updatestate]);

  /* get Funds */
  const getallFunds = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundsAction();
      if (response.status == 200) {
        setIsLoading(false);
        setfunds(response?.data?.data?.funds);
        //   setUpdateState(true);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      console.log(message);
      return false;
    }
  };

  /* Get Fund Mangers list */
  const getFundManagers = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundManagerslist(e);
      if (response.status === 200) {
        setIsLoading(false);
        setfundmanager(response?.data?.data?.managers);
      }
      return;
    } catch (err) {
      setIsLoading(false);
    }
  };

  // /* Get Sharable url */
  // const getsharelink = async () => {
  //   try {
  //     let response = await FundTemplateSharableAction(id);
  //     console.log("sharablelink", response?.data?.data?.url);
  //     setUrl(response?.data?.data?.url);
  //   } catch (err) {
  //     let message =
  //       err && err.message
  //         ? err.message
  //         : "Something went wrong with get share link";
  //     toast.error(message);
  //     return false;
  //   }
  // };

  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <FundDetailContext.Provider value={{ fund, id, funds }}>
        <div className="dash-container">
          <div className="left-bar">
            {/* sidee bar */}

            <Sidebar></Sidebar>

            {/* sidebar end */}
          </div>
          <div className="dashboard-content">
            <div className="left-top">
              <img src={lefttop} />
            </div>
            <div className="left-bottom">
              <img src={leftbottom} />
            </div>

            <div className="search-bar">
              <div className="sr-lft">
                <Button className="left-menu-btn" onClick={handleShow}>
                  <span className="m-line"></span>
                </Button>
                {/* <div className="search-box">
                  <input type="text" className="sr-bar" placeholder="Search" />
                  <button>
                    <img src={search} />
                  </button>
                </div> */}
              </div>
              <RightMobileHeader
                setIsLoading={setIsLoading}
                handleRightShow={handleRightShow}
              />
            </div>
            <div className="ds-heading">
              <h2>
                <a href="/fund-management-list" className="back-btn">
                  <img src={back} />
                </a>{" "}
                Fund-Template Listing
              </h2>
              {/* <a
                  href="#"
                  className="share-btn"
                  onClick={() => setShareShow(true)}
                >
                  <span>
                    <img src={share} />
                  </span>{" "}
                  Share
                </a> */}
            </div>
            <div className="dashboard-box fund-dtl-bx">
              <div className="search-box srch-mobile">
                <input type="text" className="sr-bar" placeholder="Search" />
                <button>
                  <img src={search} />
                </button>
              </div>

              <div className="fund-listing-main">
                <div className="fund-box fn-details">
                  <div className="fund-heading">
                    <h4>{fund?.name}</h4>
                    <div className="hd-right">
                      <div className="date-bx">
                        <span className="time-ico">
                          <img src={clock} />
                        </span>{" "}
                        {moment(fund?.created_at).format("MMMM D, YYYY")}
                      </div>
                      {/* <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {" "}
                          <img src={dots} />{" "}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            <span className="ico-drop">
                              <img src={download} />
                            </span>{" "}
                            Download
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </div>
                  <p>{fund?.description}</p>
                  <div className="fund-inv">
                    {fund?.assigned_managers?.length > 0 ? (
                      <>
                        <p>
                          Assigned:{" "}
                          {fund?.assigned_managers?.map((item) => (
                            <>
                              <OverlayTrigger
                                placement="top-start"
                                overlay={
                                  <Tooltip
                                    id="collect-tooltip"
                                    data-place="top-center"
                                  >
                                    {item?.name.charAt(0).toUpperCase() +
                                      item?.name.slice(1)}
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="name-circle"
                                  style={{ background: item?.color }}
                                >
                                  {" "}
                                  {item?.name?.toUpperCase().charAt(0)}
                                  {/* {index?.assigned_managers[0]?.name?.toUpperCase().charAt(0)} */}
                                </span>
                              </OverlayTrigger>
                             
                            </>
                          ))}{" "}
                        </p>
                      </>
                    ) : (
                      " "
                    )}
                    {/* :""
                    } */}
                     <Button
                                variant="primary"
                                className="assign-btn-two"
                                onClick={(e) => setUserassignModel()}
                              >
                                <img src={plus} />
                              </Button>
                  </div>
                </div>
                <div className="template-edit-bx">
                  {UserRole == "fund-manager" ? (
                    <>
                      <div className="choose-template">
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => setChooseshow(true)}
                        >
                          Choose Template
                        </a>{" "}
                        <a
                          href="javascript:void(0)"
                          onClick={(e) =>
                            navigate(`/fund-management-create-Template/${id}`)
                          }
                        >
                          Create Template
                        </a>{" "}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* {UserRole == "fund-manager" || UserRole == "super-admin" ? (
                    <div className="choose-template">
                      <a href="/create-template" >
                        Create  template
                      </a>{" "}
                    </div>
                  ) : (
                    ""
                  )} */}
                  {template?.data?.length > 0 ? (
                    <div className="template-archive-btn">
                      <div className="right ">
                        <span className="switch-btn">
                          <Switch
                            // defaultChecked={template}
                            onChange={(e) => showarchive(e.target.checked)}
                          />
                          Show Archived
                        </span>
                      </div>
                    </div>
                  ) : (
                    " "
                  )}
                  <div className="fund-listing-main">
                    {template?.data?.length > 0 &&
                      template?.data?.map((index) => {
                        return (
                          <div
                            className="template-box-n new-temp-box"
                            key={index?._id}
                          >
                            <h5>
                              <div className="fund-heading">
                                <a href={`/fund-template-detail/${index?._id}`}>
                                  {" "}
                                  {index?.name}{" "}
                                  {/* {index?.status == "1" ? "(Archived)" : " "} */}
                                </a>
                              </div>{" "}
                              {/* <div className="ac-btns">
                              <Button
                                className="ac-icon"
                                onClick={(e) => setDeleteModel(e, index?.id)}
                              >
                                <img src={trash} />
                              </Button>
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/fund-management-update-Template/${index?.id}/${id}`
                                  )
                                }
                                className="ac-icon"
                              >
                                <img src={edit} />
                              </Button>
                            </div> */}
                              {/* <a href="#">
                              <img src={edit} />
                            </a>{" "} */}
                            </h5>
                            <div className="hd-right">
                              <div className="date-bx">
                                <span className="time-ico">
                                  <img src={clock} />
                                </span>{" "}
                                {moment(index?.created_at).format(
                                  "MMMM DD, YYYY"
                                )}
                              </div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {" "}
                                  <img src={dots} />{" "}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      navigate(
                                        `/fund-management-update-Template/${index?._id}/${id}`
                                      )
                                    }
                                  >
                                    <span className="ico-drop">
                                      <img src={edit} />
                                    </span>{" "}
                                    Edit
                                  </Dropdown.Item>
                                  {index?.archive_status == "0" ? (
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        archivetemplate(index?._id)
                                      }
                                    >
                                      <span className="ico-drop">
                                        <img src={archive} />
                                      </span>{" "}
                                      Archive
                                    </Dropdown.Item>
                                  ) : (
                                    ""
                                  )}
                                  {index?.archive_status == "1" ? (
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        unarchivetemplate(index?._id)
                                      }
                                    >
                                      <span className="ico-drop">
                                        <img src={archive} />
                                      </span>{" "}
                                      Un-archive
                                    </Dropdown.Item>
                                  ) : (
                                    " "
                                  )}
                                  <Dropdown.Item
                                    onClick={(e) =>
                                      setDeleteModel(e, index?._id)
                                    }
                                    href="javascript:void(0)"
                                  >
                                    <span className="ico-drop">
                                      <img src={trash} />
                                    </span>{" "}
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            {/* <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p> */}
                          </div>
                        );
                      })}
                    {template?.total > 0 ? (
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={template?.current_page}
                        itemsCountPerPage={template?.per_page}
                        totalItemsCount={template?.total}
                        pageRangeDisplayed={3}
                        onChange={(e) => getFunds(e)}
                        innerClass="pagination justify-content-end"
                        prevPageText="Prev"
                        nextPageText="Next"
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  {importtemplate?.length > 0 &&
                    importtemplate?.map((index) => {
                      return (
                        <div
                          className="template-box-n fund-box"
                          key={index?.id}
                        >
                          <h5>
                            <div className="fund-heading">
                              <a href={`/fund-template-detail/${index?.id}`}>
                                {" "}
                                {index?.name}{" "}
                                {index?.archive_status == "1"
                                  ? "(Archived)"
                                  : " "}
                              </a>
                            </div>
                            {/* <a href="#">
                              <img src={edit} />
                            </a>{" "} */}
                          </h5>
                          {/* <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p> */}
                        </div>
                      );
                    })}
                  {template?.length == 0 ? (
                    <div className="nofound">No Template found</div>
                  ) : (
                    " "
                  )}
                </div>
              </div>
            </div>

            <div className="right-top">
              <img src={rttop} />
            </div>
            <div className="right-bottom">
              <img src={rtbtm} />
            </div>
          </div>
          <div className="right-bar">
            <RightHeader setIsLoading={setIsLoading} />
            <RightSidebarFunds
              setIsLoading={setIsLoading}
              setUpdateState={setUpdateState}
              updatestate={updatestate}
              template={template}
              funds={funds}
            />
          </div>
        </div>

        {/* Assign popup */}

        <UserAssignModel
          setNewFundsAssignShow={setNewFundsAssignShow}
          newFundsAssignshow={newFundsAssignshow}
          AssignShow={AssignShow}
          setAssignShow={setAssignShow}
          setUserAssignShow={setUserAssignShow}
          UserAssignShow={UserAssignShow}
          fundid={id}
          fund={fund}
          setIsLoading={setIsLoading}
          fundmanager={fundmanager}
          setfundmanager={setfundmanager}
        />
        <NewFundsAssignModel
          fundid={id}
          fund={fund}
          setIsLoading={setIsLoading}
          newFundsAssignshow={newFundsAssignshow}
          setNewFundsAssignShow={setNewFundsAssignShow}
        />
        {/* Assign popup close */}
        {/* Delete Model */}
        <DeleteTemplateModel
          DeleteShow={DeleteShow}
          setDeleteShow={setDeleteShow}
          id={templateid}
          setUpdateState={setUpdateState}
          setIsLoading={setIsLoading}
          setarchivestate={setarchivestate}
        />

        {/* Choose Template popup */}

        <ChooseTemplate
          chooseshow={chooseshow}
          setChooseshow={setChooseshow}
          fundid={id}
          setIsLoading={setIsLoading}
          updatestate={updatestate}
          setUpdateState={setUpdateState}
          importtemplate={importtemplate}
          setImporttemplate={setImporttemplate}
        />

        {/* Choose Template popup close */}

        {/* Share popup */}

        <Modal
          show={ShareShow}
          onHide={() => setShareShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="create-popup">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Url
                </label>
                <input
                  type="name"
                  placeholder="Url"
                  value={values.url}
                  onChange={(e) =>
                    setValues({ ...values, url: e.target.value })
                  }
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="cancel-btn"
              onClick={() => setShareShow(false)}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              variant="primary"
              className="save-btn"
              onClick={(e) => {
                copyshareurl(e);
              }}
            >
              Copy
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Share popup close */}

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            {/* sidee bar */}

            <Sidebar></Sidebar>

            {/* sidebar end */}
          </Offcanvas.Body>
        </Offcanvas>

        {/* right slide */}

        <Offcanvas
          show={showRight}
          className="right-bar-two"
          onHide={handleRightClose}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <div className="right-bar">
              <RightHeader setIsLoading={setIsLoading} />
              <RightSidebarFunds
                setIsLoading={setIsLoading}
                setUpdateState={setUpdateState}
                updatestate={updatestate}
                template={template}
                funds={funds}
              />
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* right slide close */}
      </FundDetailContext.Provider>
    </div>
  );
};

export default FundManagementDetails;
export { FundDetailContext };
