import React, { useState } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { CreateCompanyAction } from "../util/actions/companiesactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateCompany = ({
  setModalShow,
  modalShow,
  setUpdateState,
  setIsLoading,
}) => {
  const [values, setValues] = useState({
    name: "",
  });
  const [errors, setErrors] = useState({
    name: "",
  });

  toast.configure();

  /* Clear Company form */
  const clearcompanyform = () => {
    setValues({
      name: "",
    });
  };


  /* For Company create  */
  const handleSubmit = async (e) => {
    try {
    //  e.preventDefault();
      if (values.name === "") {
        setErrors({
          ...errors,
          name: values?.name === "" ? "Please enter Company name" : errors.name,
        });
        return false;
      }
      setIsLoading(true);
      let response = await CreateCompanyAction(values);
      if (response.status === 200) {
        setIsLoading(false);
        clearcompanyform(true);
        toast.success("Company Created", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setModalShow(false);
        setUpdateState(false);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message?.name[0]
        : "Something went wrong during Create Company";
      setIsLoading(false);
      setErrors({
        ...errors,
        name: message,
      });
      console.log("err", err?.message?.name[0]);
      return false;
    }
  };

/* Enter Press form submit */
  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Create Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="create-popup">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Name
            </label>
            <input
              type="name"
              placeholder="Company Name Here"
              value={values.name}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  name: null,
                });
                setValues({ ...values, name: e.target.value });
              }}
              onKeyPress={handleKeypress}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={() => {
            setModalShow(false);
            setErrors({
              ...errors,
              name: null,
            });
          }}
        >
          {" "}
          Cancel{" "}
        </Button>
        <Button variant="primary" className="save-btn" onClick={handleSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCompany;
