import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import signupimg from "./images/sign-up_right.svg";
import { useNavigate } from "react-router-dom";
import { forgotAction } from "./util/actions/loginaction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./spinner";

const Home = () => {
  const [values, setValues] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  toast.configure();
  const navigate = useNavigate();
  const handleclick = () => {
    navigate("/");
  };
  const handleemaillink = async (e) => {
    try {
      e.preventDefault();
      if (values.email === "") {
        setErrors({
          ...errors,
          email: values.email === "" ? "Please enter  email" : errors.email,
        });
        return false;
      }
      setIsLoading(true);
      let response = await forgotAction(values);
      console.log("regisforgot", response);
      if (response.status == 200) {
        setIsLoading(false);
        toast.success("We have e-mailed your password reset link!", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("Forgot", response);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      console.log("messagee", err?.response?.data?.message?.email[0]);
      let message = err
        ? err?.response?.data?.message?.email[0]
        : "Something went wrong during login";
      setErrors({
        ...errors,
        email: message,
      });
      return;
    }
  };
  return (
    <div className="splashmain">
      {isLoading ? <Spinner /> : ""}
      <div className="container">
        <div className="row align-items-center h-100">
          <div className="col-md-6">
            <div className="login-formleft">
              <div className="form-heading">
                <span>Forgot Password</span>
              </div>
              <form className="loginfromm">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email address"
                    value={values.email}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        email: null,
                      });
                      setValues({ ...values, email: e.target.value });
                    }}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>

                <a
                  onClick={handleemaillink}
                  type="submit"
                  className="blue-btn w-100"
                >
                  Send Link
                </a>
                <a onClick={handleclick} className="light-btn w-100 mt-3">
                  Back
                </a>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-picbx">
              <img src={signupimg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
