import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Link ,useLocation ,useNavigate} from "react-router-dom";
import "../App.css";
import "../style.scss";
import help from "../images/help.svg";
import notify from "../images/notify.svg";
import settings from "../images/settings.svg";
import logout from "../images/logout.svg";
import user from "../images/user-img.png";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { logoutAction } from "../util/actions/loginaction";

const RightHeader = ({ setIsLoading}) => {
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  const UserName = JSON.parse(localStorage.getItem("UserName"));
  const Avatar = JSON.parse(localStorage.getItem("avatar"));
  /* For Logout User */
  const handlelogout = async () => {
    try {
      setIsLoading(true);
      let response = await logoutAction();
      console.log("logoutres", response);
      if (response.status == 200) {
        setIsLoading(false);
        localStorage.clear();
        navigate("/");
      }
      return;
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message ? err.message : "Something went wrong during Logout";
      console.log("error", message);
      return false;
    }
  };
  return (
    <div className="top-bar-right">
      {/* {isLoading ? <Spinner /> : ""} */}
      {/* <div className="help-ico">
        <a href="#">
          <img src={help} />
        </a>
      </div>
      <div className="notify-ico">
        <a href="#">
          <img src={notify} /> <span className="dot-ico"></span>{" "}
        </a>
      </div> */}
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <span className="ico-drop ico-drop-user">
            {Avatar ? (
              <img src={Avatar} />
            ) : (
              <span className="name-bx-user blue-circle">
                {UserName?.toUpperCase()?.charAt(0)}
              </span>
            )}
          </span>{" "}
          <span className="drp-name">{UserName}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/settings">
            <span className="ico-drop-in">
              <img src={settings} />
            </span>{" "}
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={handlelogout} href="#">
            <span className="ico-drop-in">
              <img src={logout} />
            </span>{" "}
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default RightHeader;
