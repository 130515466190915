import React, { useState, createContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Link, useParams } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import edit from "./images/edit-ico.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import back from "./images/back-ico.svg";
import download from "./images/download.svg";
import copy from "./images/copy.svg";
import share from "./images/share.svg";
import { Dropdown } from "react-bootstrap";
import plus from "./images/plus.svg";
import arrowleft from "./images/arrow-left.svg";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import RightSidebarFunds from "./components/RightSidebarfunds";
import AssignModel from "./Model/AssignModel";
import { getFundsbyIdAction } from "./util/actions/fundactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFundsAction,
  DownloadFundAction,
  getTemplatesbyIdAction,
} from "./util/actions/fundactions";
import {
  FundTemplateSharableAction,
  TemplatebyIdAction,
} from "./util/actions/allactions";
import UserAssignModel from "./Model/UserAssignModel";
import moment from "moment";
import Spinner from "./spinner";
import TemplateView from "./components/TemplateView_new";
import axios from "axios";
import fileDownload from "js-file-download";
const FundDetailContext = createContext();

const TemplateDetail = () => {
  const navigate = useNavigate();
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const [AssignShow, setAssignShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ShareShow, setShareShow] = useState(false);
  const [url, setUrl] = useState();
  const [downloadlink, setDownloadlink] = useState("");
  const [embedlink , setembedlink] = useState("")
  const [pdfname, setpdfname] = useState("");
  const [show, setShow] = useState(false);
  const [template, setTemplate] = useState(false);
  const [showRight, setRightShow] = useState(false);
  const [updatestate, setUpdateState] = useState(false);
  const [funds, setfunds] = useState("");
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [fund, setfund] = useState(false);
  const textAreaRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);

  // const state = useNavigationState(state => state);
  // const routes = navigation.getState()?.routes;
  //   var fileDownload = require('js-file-download');
  // fileDownload(downloadlink, 'filename.pdf');

  // function download({url: downloadlink, filename: pdfname}) {
  //   axios.get(url, {
  //     responseType: 'blob',
  //   }).then(res => {
  //     fileDownload(res.downloadlink, pdfname);
  //   });
  // }

  /* get Template id */
  const { id } = useParams();

  const handleclick = () => {
    navigate("/import-templates");
  };
  /*Copy Share Url */
  const copyshareurl = (e) => {
    navigator.clipboard.writeText(url);
    e.target.focus();
    setCopySuccess("Copied!");
    setTimeout(() => {
      setShareShow(false);
    }, 5000);
  };

  const shareClick = () => {
    setShareShow(true);
    getsharelink();
    setCopySuccess("Copy");
  };

  useEffect(() => {
    // if (UserRole == "super-admin" || UserRole == "fund-manager") {
    getTemplatesbyId();
    // }
  }, []);

  useEffect(() => {
    if (UserRole == "super-admin" || UserRole == "fund-manager") {
      FundsById();
    }
  }, []);
  useEffect(() => {
    if (UserRole == "investor" || UserRole == "fund-manager") {
    Downloadtemplatebyid();
    }
  }, []);

  /* Get Funds By Id Response */
  const FundsById = async () => {
    try {
      setIsLoading(true);
      let response = await getTemplatesbyIdAction(id);
      if (!response?.statusText === "OK") {
        throw {
          message: response.message || "Something went wrong with company",
        };
      }
      if (response.status == 200) {
        setIsLoading(false);
        console.log("fundsbyid", response?.data);
        setfund(response?.data?.data?.fund);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message ? err.message : "Something went wrong with funds";
      toast.error(message);
      return false;
    }
  };
  /* get Templates by id */
  const getTemplatesbyId = async (e) => {
    try {
      setIsLoading(true);
      let response = await TemplatebyIdAction(id);
      if (response.status == 200) {
        setIsLoading(false);
        setTemplate(response?.data?.data?.template);
        setfund(response?.data?.data?.fund);
        //   setUpdateState(true);
        console.log("templatesbyid", response);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      console.log(message);
      return false;
    }
  };

  /* Get Sharable url */
  const getsharelink = async () => {
    try {
      let response = await FundTemplateSharableAction(id);
      console.log("sharablelink", response?.data?.data?.url);
      setUrl(response?.data?.data?.url);
    } catch (err) {
      let message =
        err && err.message ? err.message : "Something went wrong with share";
      return false;
    }
  };

  /* For Download Template by id */
  const Downloadtemplatebyid = async (e) => {
    try {
      setIsLoading(true);
      let response = await DownloadFundAction(id);
      if (response.status == 200 && response?.data?.data.file) {
        setembedlink(response?.data?.data?.file);
        //   require("downloadjs")(response?.data?.data.file, "investor.pdf", "application/pdf");
        setDownloadlink(response?.data?.data.file);
        const downloadLink = document.createElement("a");
        downloadLink.href = response?.data?.data.file;
        downloadLink.download =
          template.name.replace(/\s+/g, "-").toLowerCase() + ".pdf";
        downloadLink.click();
        console.log(response?.data?.data?.file,"file")

        const link = response?.data?.data?.file.split(",").pop();
      
       
        // console.log(link,"link")
        // document.body.appendChild(link);
        // link.style.width = "100%";
        // link.style.height = "842pt";
        // link.type = "application/pdf";
        // link.data =
        //   "data:application/pdf;base64," + response?.data?.data.file;
        // document.body.appendChild(link);
        setIsLoading(false);
        //   setUpdateState(true);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message ? err?.message : "Something went with templates.";
      console.log(message);
      return false;
    }
  };

  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              {/* <div className="search-box">
                <input type="text" className="sr-bar" placeholder="Search" />
                <button>
                  <img src={search} />
                </button>
              </div> */}
            </div>
            <RightMobileHeader setIsLoading={setIsLoading} />
          </div>
          <div className="ds-heading">
            <h2>
              <a
                href="javascript:void(0)"
                className="back-btn"
                onClick={() => navigate(-1)}
              >
                <img src={back} />
              </a>{" "}
              Fund-Template Listing
            </h2>
            {UserRole == "fund-manager" ? (
              <a
                href="javascript:void(0)"
                className="share-btn"
                onClick={() => shareClick()}
              >
                <span>
                  <img src={share} />
                </span>{" "}
                Share
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="dashboard-box">
            {/* <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" />
              <button>
                <img src={search} />
              </button>
            </div> */}

            <div className="fund-listing-main">
              <div className="fund-box fn-details">
                <div className="fund-heading">
                  <h4>{template?.name}</h4>
                  <div className="hd-right">
                    <div className="date-bx">
                      <span className="time-ico">
                        <img src={clock} />
                      </span>{" "}
                      {moment(fund?.created_at).format("MMMM D, YYYY")}
                    </div>
                    {true ? (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {" "}
                          <img src={dots} />{" "}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            // onClick={downloadlink}
                            onClick={() => Downloadtemplatebyid()}
                            href={embedlink}
                            //target="_blank"
                           
                          >
                            <span className="ico-drop">
                              <img src={download} />
                              {console.log(embedlink,"download link")}
                             
                            </span>{" "}
                            Download
                          </Dropdown.Item>
                          {UserRole == "fund-manager" ||
                          UserRole == "super-admin" ? (
                            <Dropdown.Item
                              // onClick={downloadlink}
                              //  onClick={() => Downloadtemplatebyid()}
                              href={`/editTemplate-detail/${id}`}
                              // target="_blank"
                            >
                              <span className="ico-drop">
                                <img src={edit} />
                              </span>{" "}
                              Edit
                            </Dropdown.Item>
                          ) : (
                            " "
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <p>
                  {" "}
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book Lorem Ipsum is simply dummy text
                </p> */}
                <div className="fund-inv">
                  {/* {fund?.assigned_managers?.length>0 ?
                  <p>
                    Assign to:{" "}
                    <span className="name-circle blue-circle">D</span>
                    {fund?.assigned_managers?.map((index) => {
                      return (
                        <span className="name-circle blue-circle"  key={index?.id}>
                          {index?.name?.charAt(0)}
                        </span> 
                      );
                    })}
                  </p>
                  {/* :""
                    } */}
                  {/* <Button
                    variant="primary"
                    className="assign-btn-two"
                    onClick={() => setAssignShow(true)}
                  >
                    <img src={plus} />
                  </Button> */}
                </div>
              </div>
              <div className="template-edit-bx">
                {/* {UserRole == "fund-manager" ? (
                  <div className="choose-template">
                    <a href="#" onClick={handleclick}>
                      Choose template
                    </a>{" "}
                  </div>
                ) : (
                  " "
                )} */}
                <div className="tm-heading">
                  {/* {console.log("embedlonk",embedlink)}
                <a href={embedlink} onClick={()=>
                  {
                  // window.open(embedlink, '_blank')
                  window.location.reload(true)}}> Download link</a> */}

                  General Firm Information{" "}
                  
                  <a
                    onClick={() => Downloadtemplatebyid()}
                    href="javascript:void(0)"
                    className="qs-download"
                  >
                    <img src={download} />
                  </a>{" "}
                  {/* <button onClick={(e)=>download()} >ddd</button> */}
                  {/* <a href={downloadlink} download={pdfname} target="_blank">
                    {" "}
                    Download Here{" "}
                  </a> */}
                  {/* <a href={downloadlink} download>ftdfgddfdfbhhdhb</a> */}
                </div>

                {/* // working */}
                {/* {template?.content?.length > 0 &&
                  template?.content?.map((index) => {
                    return (
                      <div className="ques-ans" key={index?.id}>
                        <div className="ques-box">
                          <h6>{index?.question}</h6>
                          <a className="copy-ico">
                            <img src={copy} />
                          </a>
                        </div>
                        <div className="ans-box">
                          <strong>Ans:</strong> {index?.answers}
                        </div>
                      </div>
                    );
                  })} */}

                <TemplateView
                  questionAnswerArray={template?.content?.QuestionAns}
                />

                {/* <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      2.Does the Firm have any existing businesses that are not
                      related to Funds investment strategy?
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> NO
                  </div>
                </div>

                <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      3. Provide an organizational chart depicting the ownership
                      structure of the Firm including investment advisors and
                      any parent organization.
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> It is a long established fact that a
                    reader will be distracted by the readable content of a page
                    when looking at its layout. The point of using Lorem Ipsum
                    is that it has a more-or-less normal distribution of
                    letters, as opposed to using 'Content here.
                  </div>
                </div>

                <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      4. Provide a description of the Firm’s Fundraising plans
                      over the horizon of the next five years.
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> It is a long established fact that a
                    reader will be distracted by the readable content of a page
                    when looking at its layout. The point of using Lorem Ipsum
                    is that it has a more-or-less normal distribution of
                    letters, as opposed to using 'Content here.
                  </div>
                </div>

                <div className="ques-ans">
                  <div className="ques-box">
                    <h6>
                      5. Provide a brief overview of the Firms liquidity,
                      pro-forma budget and capitalization.
                    </h6>
                    <a className="copy-ico">
                      <img src={copy} />
                    </a>
                  </div>
                  <div className="ans-box">
                    <strong>Ans:</strong> Include any debt service requirements
                    and personal guarantees involved. Mention the personal
                    guarantees and the people. For the budget, provide an
                    annualized pro-forma budget covering the life of the Fund.
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
        <div className="right-bar">
          <RightHeader setIsLoading={setIsLoading} />
          <RightSidebarFunds
            funds={funds}
            id={id}
            setUpdateState={setUpdateState}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>

      {/* Assign popup */}
      {UserRole == "super-admin" || UserRole == "fund-manager" ? (
        <UserAssignModel
          AssignShow={AssignShow}
          setAssignShow={setAssignShow}
          fundid={id}
          setIsLoading={setIsLoading}
        />
      ) : (
        " "
      )}
      {/* Assign popup close */}

      {/* Share popup */}

      <Modal
        show={ShareShow}
        onHide={() => setShareShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Url
              </label>

              <input
                type="name"
                placeholder="Url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                ref={textAreaRef}
              />
              {/* <span className="copytext">{copySuccess}</span> */}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => {
              setShareShow(false);
            }}
          >
            {" "}
            Cancel{" "}
          </Button>

          <Button
            variant="primary"
            className="save-btn"
            onClick={(e) => {
              copyshareurl(e);
            }}
          >
            {copySuccess}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Share popup close */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>
          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide */}

      <Offcanvas
        show={showRight}
        className="right-bar-two"
        onHide={handleRightClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="right-bar">
            <RightHeader setIsLoading={setIsLoading} />
            <RightSidebarFunds
              funds={funds}
              id={id}
              setUpdateState={setUpdateState}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide close */}
    </div>
  );
};

export default TemplateDetail;
