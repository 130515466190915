import React from "react";
import loader from "./images/lploader.gif";

 const LoadingSpinner=()=> {
  return (
    <div className="loader">
        <img src={loader} />
    </div>
  );
}

export default LoadingSpinner;