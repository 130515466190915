import axios from "axios";
import jwtDecode from "jwt-decode";
const config = require(`../../environment/development.js`).config;
// const token = JSON.parse(localStorage.getItem("Token"));

// if(token)
// {
//   var headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };
// }

/* For CreateCompany */
export const CreateCompanyAction = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/companies`, payload);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get Companies */
export const getCompaniesAction = (e) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url =`${config.backEnd}/companies`;

      if (e) {
          url = `${url}?page=${e}`;
      }
      let response = await axios.get(url);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get Single Company by id */
export const getCompanybyIdAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/companies/${id}`);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* Delete Company by id */
export const deleteCompanybyIdAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.delete(
        `${config.backEnd}/companies/${id}/delete`
      );
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* For Company invite send by company id */
export const InviteSendByID = (id,payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/companies/${id}/invites/send`,
        payload
      );
      return resolve(response);
    } catch (error) {
      console.log("errorrrr", error);
      return reject(error);
    }
  });
};

/* For fund manager using invite registration */
export const ManagerInviteRegistration = (payload, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/register-manager/${token}`,
        payload
      );
      console.log(response, "response");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

/* Get Funds Company by id */
export const getFundsbyIdAction = (id,e) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      let response = await axios.get(`${config.backEnd}/companies/${id}/funds`);
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};

/* For update company */
export const UpdateCompanyAction = (id,payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/companies/${id}/update`,
        payload
      );
      console.log(response, "response");
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};


/* For Company Search */
export const CompanySearchAction = (name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
      `${config.backEnd}/companies?search=${name}`
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};


/* For Company Fund Search */
export const CompanyFundSearchAction = (id,name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
      `${config.backEnd}/companies/${id}/funds?search=${name}`
      );
      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
};


/* Get Companies for useradd dropdwon */
export const getUserAddCompaniesAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/get-all-companies`);
      return resolve(response);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};