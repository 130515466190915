import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import arrow from "./images/arrow.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import eye from "./images/eye.svg";
import usetting from "./images/user-settings.png";
import welcome from "./images/welcome-img.svg";
import fund from "./images/fund.png";
import company from "./images/company.png";
import investor from "./images/investor.png";
import clock from "./images/clock.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import { logoutAction } from "./util/actions/loginaction";
import {
  getFundsAction,
  getFundManagerlist,
  FundManagerlogAction,
} from "./util/actions/fundactions";
import moment from "moment";
import CreateCompany from "./Model/CreateCompany";
import CreateFundModel from "./Model/createFundModel";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import Spinner from "./spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCompaniesAction } from "./util/actions/companiesactions";
import {
  getInvestorsAction,
  investorlogAction,
} from "./util/actions/investoractions";
import {
  getInvitationAction,
  getRecentManagerLog,
  getRecentInvestorLog,
} from "./util/actions/allactions";
import Pagination from "react-js-pagination";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [fundmodalShow, setfundModalShow] = useState(false);
  const [fundmanager, setfundmanager] = useState("");
  const [investors, setInvestors] = useState("");
  const [companies, setCompanies] = useState("");
  const [invites, setInvites] = useState("");
  const [recentmanagerlog, setrecentmanagerlog] = useState("");
  const [recentinvestorlog, setrecentinvestorlog] = useState("");
  const [show, setShow] = useState(false);
  const [updatestate, setUpdateState] = useState(true);
  const [funds, setfunds] = useState("");
  const [showRight, setRightShow] = useState(false);
  toast.configure();
  const navigate = useNavigate();
  const handleRightClose = () => setRightShow(false);
  const handleRightShow = () => setRightShow(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const UserName = JSON.parse(localStorage.getItem("UserName"));

  useEffect(() => {
    if (UserRole == "super-admin" || UserRole == "fund-manager") {
      getFundManager();
      getInvestors();
      getrecentinvestorlog();
      getrecentmanagerlog();
      getInvites();
    }
    if (UserRole == "investor" || UserRole == "fund-manager") {
      getFunds();
    }
    if (UserRole == "super-admin") {
      getCompanies();
    }
  }, [updatestate]);

  /* Get fundmanger fist three */
  let fundmangerfirstname =
    fundmanager?.fund_managers?.length > 0 &&
    fundmanager?.fund_managers?.slice(0, 3);


  /* get Funds */
  const getFunds = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundsAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setfunds(response?.data?.data?.funds);
        setUpdateState(true);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      console.log(message);
      return false;
    }
  };
  /* get Fund Manager */
  const getFundManager = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundManagerlist();
      console.log("fundmanagermain", response?.data?.data);
      if (response.status == 200) {
        setIsLoading(false);
        setfundmanager(response?.data?.data);
        //  setUpdateState(false);
        
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      //    toast.error(message);
      return false;
    }
  };
  /* get Companies */
  const getCompanies = async (e) => {
    try {
      setIsLoading(true);
      let response = await getCompaniesAction();
      if (response.status == 200) {
        setIsLoading(false);
        setCompanies(response?.data?.data);
        setUpdateState(true);
        console.log("companyresponse",response?.data?.data?.companies);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      //   toast.error(message);
      return false;
    }
  };
  /* get Investors */
  const getInvestors = async (e) => {
    try {
      setIsLoading(true);
      let response = await getInvestorsAction();
      if (response.status == 200) {
        setIsLoading(false);
        setInvestors(response?.data?.data);
        console.log("investors", response?.data?.data);
        setUpdateState(true);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      // toast.error(message);
      return false;
    }
  };
  /* get Invites */
  const getInvites = async (e) => {
    try {
      setIsLoading(true);
      let response = await getInvitationAction();
      if (response.status == 200) {
        setIsLoading(false);
        setInvites(response?.data?.data?.invites);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      // toast.error(message);
      return false;
    }
  };
  /* get recent Manager Log */
  const getrecentmanagerlog = async (e) => {
    try {
      setIsLoading(true);
      let response = await getRecentManagerLog();
      if (response.status == 200) {
        setIsLoading(false);
        setrecentmanagerlog(response?.data?.data?.fund_managers);
        console.log("recentmanagerlog", response?.data?.data?.fund_managers);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      // toast.error(message);
      return false;
    }
  };
  /* get Recent Investors */
  const getrecentinvestorlog = async (e) => {
    try {
      setIsLoading(true);
      let response = await getRecentInvestorLog();
      if (response.status == 200) {
        setIsLoading(false);
        setrecentinvestorlog(response?.data?.data?.investors);
        console.log("investorlog", response?.data?.data?.investors);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      // toast.error(message);
      return false;
    }
  };
  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              {/* <div className="search-box">
                <input type="text" className="sr-bar" placeholder="Search" />
                <button>
                  <img src={search} />
                </button>
              </div> */}
            </div>

            {/* Mobile Header view */}
            <RightMobileHeader
              handleRightShow={handleRightShow}
              setIsLoading={setIsLoading}
            />
          </div>
          <div className="ds-heading">
            {" "}
            <h2>Dashboard</h2>{" "}
          </div>
          <div className="dashboard-box">
            {/* <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" />
              <button>
                <img src={search} />
              </button>
            </div> */}
            <div className="wl-box">
              <div className="text-box">
                <h3>Welcome {UserName}</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text.
                </p>
                {UserRole == "fund-manager" ? (
                  <Button
                    variant="primary"
                    className="create-fund-btn"
                    onClick={() => setfundModalShow(true)}
                  >
                    Create New Fund
                  </Button>
                ) : (
                  ""
                )}
                {UserRole == "super-admin" ? (
                  <Button
                    variant="primary"
                    className="create-fund-btn"
                    onClick={() => setModalShow(true)}
                  >
                    Create New Company
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <div className="wl-image">
                <img src={welcome} />
              </div>
            </div>
            {UserRole == "super-admin" || UserRole == "fund-manager" ? (
              <div className="explore-section">
                <div className="explore-heading">Explore</div>
                <div className="row">
                  <div className="col-lg-6 col-xl-4 explore-thumb">
                    <Link to="/fund-manager-log">
                      <div className="explore-box">
                        <div className="ex-top-bar">
                          <div className="icon-box">
                            <span className="bx-ico">
                              {" "}
                              <img src={fund} />{" "}
                            </span>
                          </div>
                          {/* <div className="ex-drop">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <span className="ico-drop">
                                <img src={dots} />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="/fund-manager-log">
                                <span className="ico-drop">
                                  <img src={eye} />
                                </span>{" "}
                                View
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
                        </div>

                        <h6>
                          <a href="javascript:void(0)">Fund Managers</a>
                        </h6>
                        <h3>
                          {fundmanager?.total < 10 &&
                          fundmanager?.total > 0
                            ? "0"
                            : " "}
                          {fundmanager?.total}
                        </h3>
                        <div className="users-bar">
                          <ul>
                            {fundmanager?.total > 0 &&
                              fundmanager?.fund_managers?.data?.map((index, item) => {
                                return (
                                  <>
                                    {item < 3 ? (
                                      <>
                                        {index?.avatar == "" ? (
                                          <li
                                            key={index?.id}
                                            className={`${
                                              item == 1
                                                ? "pink-circle"
                                                : "orange-circle"
                                            }  `}
                                            style={{ background: index?.color }}
                                          >
                                            {index?.name
                                              ?.charAt(0)
                                              ?.toUpperCase()}
                                          </li>
                                        ) : (
                                          <li>
                                            <img src={index?.avatar} />
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            {fundmanager?.total > 3 && (
                              <li>
                                +{" "}
                                {
                                  fundmanager?.fund_managers?.data?.slice(
                                    3,
                                    fundmanager?.total
                                  )?.length
                                }
                              </li>
                            )}
                          </ul>
                        </div>
                        {fundmanager?.total > 0 ? (
                          <div className="updated-time">
                            <span className="tm-icon">
                              <img src={clock} />
                            </span>{" "}
                            <span className="tm-text">
                              Last updated on{" "}
                              {moment(fundmanager?.last_updated).format(
                                "MMMM D, YYYY"
                              )}
                            </span>
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </Link>
                  </div>

                  <div className="col-lg-6 col-xl-4 explore-thumb">
                    <Link to="/investor-log">
                      <div className="explore-box">
                        <div className="ex-top-bar">
                          <div className="icon-box light-blue-one">
                            <span className="bx-ico">
                              {" "}
                              <img src={investor} />{" "}
                            </span>
                          </div>
                          <div className="ex-drop">
                            {/* <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <span className="ico-drop">
                                <img src={dots} />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="/investor-log">
                                <span className="ico-drop">
                                  <img src={eye} />
                                </span>{" "}
                                View
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown> */}
                          </div>
                        </div>
                        <h6>
                          <a href="javascript:void(0)">Total Investors</a>
                        </h6>
                        <h3>
                          {investors && investors?.total < 10
                            ? "0"
                            : " "}
                          {investors?.total > 0 &&
                            investors?.total}
                        </h3>
                        <div className="users-bar">
                          <ul>
                            {investors?.total > 0 &&
                              investors?.investors?.data?.map((index, item) => {
                                return (
                                  <>
                                    {item < 3 ? (
                                      <>
                                        {index?.avatar == "" ? (
                                          <li
                                            key={index?._id}
                                            className={`${
                                              item == 1
                                                ? "pink-circle"
                                                : "orange-circle"
                                            }  `}
                                            style={{ background: index?.color }}
                                          >
                                            {index?.name
                                              ?.charAt(0)
                                              ?.toUpperCase()}
                                          </li>
                                        ) : (
                                          <li>
                                            <img src={index?.avatar} />
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            {investors?.total > 3 && (
                              <li>
                                +{" "}
                                {
                                  investors?.investors?.data?.slice(
                                    3,
                                    investors?.total
                                  )?.length
                                }
                              </li>
                            )}
                          </ul>
                        </div>
                        {investors?.total > 0 ? (
                          <div className="updated-time">
                            <span className="tm-icon">
                              <img src={clock} />
                            </span>{" "}
                            <span className="tm-text">
                              Last updated on{" "}
                              {investors?.total > 0 &&
                                moment(investors?.last_updated_at).format(
                                  "MMMM D, YYYY"
                                )}
                            </span>
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </Link>
                  </div>
                  {UserRole == "super-admin" ? (
                    <div className="col-lg-6 col-xl-4 explore-thumb">
                      <Link to="/company-list">
                        <div className="explore-box">
                          <div className="ex-top-bar">
                            <div className="icon-box exlight-blue-one">
                              <span className="bx-ico">
                                {" "}
                                <img src={company} />{" "}
                              </span>
                            </div>
                            <div className="ex-drop">
                              {/* <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  <span className="ico-drop">
                                    <img src={dots} />
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item href="/company-list">
                                    <span className="ico-drop">
                                      <img src={eye} />
                                    </span>{" "}
                                    View
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown> */}
                            </div>
                          </div>

                          <h6>
                            <a href="#">Total Companies</a>
                          </h6>
                          <h3>
                            {companies?.total &&
                            companies?.total < 10 &&
                            companies?.total > 0
                              ? "0"
                              : " "}
                            {companies?.total}
                          </h3>
                          <div className="users-bar"></div>
                          {companies?.total > 0 ? (
                            <div className="updated-time">
                              <span className="tm-icon">
                                <img src={clock} />
                              </span>{" "}
                              <span className="tm-text">
                                Last updated on{" "}
                                {moment(
                                  companies?.last_updated
                                ).format("MMMM D, YYYY")}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {UserRole == "investor" ? (
              <div className="activity-table">
                <div className="tb-heading">
                  {" "}
                  <h3>Activity</h3>{" "}
                  <a href="/fund-listing">
                    view all{" "}
                    <span className="rt-ico">
                      <img src={arrow} />
                    </span>
                  </a>{" "}
                </div>

                <div className="data-activity">
                  <Table responsive="xl">
                    <thead>
                      <tr>
                        <th className="th-one">Fund Name</th>
                        <th>Invited by</th>
                        <th>Time</th>
                        <th>Date</th>
                        <th className="text-center">Status</th>
                        <th className="th-two text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {funds?.data?.length > 0 &&
                        funds?.data?.map((index) => {
                          return (
                            <tr key={index?._id}>
                              <td>{index?.name}</td>
                              <td>
                                <div className="inv-name">
                                  {" "}
                                  <span
                                    className="name-bx"
                                    style={{
                                      background: index?.invite_sender_color,
                                    }}
                                  >
                                    {index?.invite_sender_name
                                      .toUpperCase()
                                      .charAt(0)}
                                  </span>{" "}
                                  {index?.invite_sender_name}
                                </div>
                              </td>
                              <td>
                                {" "}
                                {moment(index?.created_at).format("hh:mm A")}
                              </td>
                              <td>
                                {" "}
                                {moment(index?.created_at).format(
                                  "MMMM D, YYYY"
                                )}
                              </td>
                              <td className="t-center">
                                <span className="status-new">New</span>
                              </td>
                              <td className="t-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {" "}
                                    <img src={dots} />{" "}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href={`/fund-details/${index?.fund_id}`}
                                    >
                                      <span className="ico-drop">
                                        <img src={eye} />
                                      </span>{" "}
                                      View
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                      {/* {funds?.length == 0 ? <tr>No Funds found</tr> : " "} */}
                    </tbody>
                  </Table>
                </div>
              </div>
            ) : (
              ""
            )}
             

            {UserRole == "fund-manager" ? (
              <div className="activity-table">
                <div className="tb-heading">
                  {" "}
                  <h3>Funds</h3>{" "}
                  <a href="/fund-management-list">
                    view all{" "}
                    <span className="rt-ico">
                      <img src={arrow} />
                    </span>
                  </a>{" "}
                </div>

                <div className="data-activity">
                  <Table responsive="xl">
                    <thead>
                      <tr>
                        <th className="th-one">Fund Name</th>
                        <th>Time</th>
                        <th>Date</th>
                        {/* <th className="text-center">Status</th> */}
                        <th className="th-two text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {funds?.total > 0 &&
                        funds?.data?.map((index) => {
                          return (
                            <tr key={index?._id}>
                              <td>{index?.name}</td>
                              <td>
                                {" "}
                                {moment(index?.created_at).format("hh:mm A")}
                              </td>
                              <td>
                                {" "}
                                {moment(index?.created_at).format(
                                  "MMMM D, YYYY"
                                )}
                              </td>
                              <td className="t-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {" "}
                                    <img src={dots} />{" "}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href={`/fund-management-details/${index?._id}`}
                                    >
                                      <span className="ico-drop">
                                        <img src={eye} />
                                      </span>{" "}
                                      View
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                      {/* {funds?.length == 0 ? <tr>No Funds found</tr> : " "} */}
                    </tbody>
                  </Table>
                </div>
              </div>
            ) : (
              ""
            )}

{
                   funds?.data?.length > 0 ?
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={funds?.current_page}
                        itemsCountPerPage={funds?.per_page}
                        totalItemsCount={funds?.total}
                        pageRangeDisplayed={3}
                        onChange={(e)=>getFunds(e)}
                        innerClass="pagination justify-content-end"
                        prevPageText="Prev"
                        nextPageText="Next"
                    /> : ""
                }
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
        <div className="right-bar">
          {/* desktop view */}
          <RightHeader setIsLoading={setIsLoading} />
          {/* Right Header */}
          {UserRole == "super-admin" || UserRole == "fund-manager" ? (
            <div className="invite-section">
              <div className="heading-rt">
                <h4>
                  Recent <br />
                  Investors List
                </h4>
                <a
                  onClick={(e) => navigate("/investor-log")}
                  href="javascript:void(0)"
                >
                  View all{" "}
                  <span className="arw-ico">
                    <img src={arrow} />
                  </span>
                </a>
              </div>

              {recentinvestorlog?.length > 0 &&
                recentinvestorlog?.slice(0, 2).map((index) => {
                  return (
                    <div className="recent-box" key={index?.id}>
                      {index?.avatar == "" ? (
                        <div
                          className="user-img"
                          style={{ background: index?.color }}
                        >
                          {index?.name?.charAt(0)?.toUpperCase()}
                        </div>
                      ) : (
                        <div className="user-img">
                          <img src={index?.avatar} />
                        </div>
                      )}
                      <div className="user-deatils">
                        <div className="name-bar">
                          <h6>
                            {index?.name?.charAt(0).toUpperCase() +
                              index?.name.slice(1)}
                          </h6>
                        </div>
                        <p className="email">{index?.email}</p>
                        <div className="user-log-time">
                          {" "}
                          <p>
                            <span>
                              <img src={clock} />
                            </span>{" "}
                            {moment(index?.joined_at).format("MMMM D, YYYY")}
                          </p>{" "}
                          <p>{moment(index?.joined_at).format("hh:mm A")}</p>{" "}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {recentinvestorlog?.length == 0 ? (
                <div className="recent-box">
                  <div className="user-deatils">
                    <p className="email">No investor found</p>
                  </div>
                </div>
              ) : (
                " "
              )}

              <div className="heading-rt">
                <h4>
                  Recent <br /> Fund Managers List
                </h4>
                <a
                  onClick={(e) => navigate("/fund-manager-log")}
                  href="javascript:void(0)"
                >
                  View all{" "}
                  <span className="arw-ico">
                    <img src={arrow} />
                  </span>
                </a>
              </div>
              {recentmanagerlog?.length > 0 &&
                recentmanagerlog?.slice(0, 2).map((index) => {
                  return (
                    <div className="recent-box" key={index?.id}>
                      {index?.avatar == "" ? (
                        <div
                          className="user-img"
                          style={{ background: index?.color }}
                        >
                          {index?.name?.charAt(0).toUpperCase()}
                        </div>
                      ) : (
                        <div className="user-img">
                          <img src={index?.avatar} />
                        </div>
                      )}
                      <div className="user-deatils">
                        <div className="name-bar">
                          <h6>
                            {index?.name?.charAt(0).toUpperCase() +
                              index?.name.slice(1)}
                          </h6>
                        </div>
                        <p className="email">{index?.email}</p>
                        <div className="user-log-time">
                          {" "}
                          <p>
                            <span>
                              <img src={clock} />
                            </span>{" "}
                            {moment(index?.joined_at).format("MMMM D, YYYY")}
                          </p>{" "}
                          <p>{moment(index?.joined_at).format("hh:mm A")}</p>{" "}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            " "
          )}
          {recentmanagerlog?.total == 0 ? (
            <div className="recent-box">
              <div className="user-deatils">
                <p className="email">No Fund Manager found</p>
              </div>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>

      <CreateFundModel
        fundmodalShow={fundmodalShow}
        setfundModalShow={setfundModalShow}
        setIsLoading={setIsLoading}
      />

      {/* right slide */}

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Offcanvas right</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">sdfdf df dsfds fdsf</div>
      </div>

      {/* right slide close */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide */}

      <Offcanvas
        show={showRight}
        className="right-bar-two"
        onHide={handleRightClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="right-bar">
            <RightHeader setIsLoading={setIsLoading} />
            {UserRole == "super-admin" || UserRole == "fund-manager" ? (
              <div className="invite-section">
                <div className="heading-rt">
                  <h4>
                    Recent <br />
                    Investors List
                  </h4>
                  <a
                    onClick={(e) => navigate("/investor-log")}
                    href="javascript:void(0)"
                  >
                    View all{" "}
                    <span className="arw-ico">
                      <img src={arrow} />
                    </span>
                  </a>
                </div>

                {recentinvestorlog?.length > 0 &&
                  recentinvestorlog?.slice(0, 2).map((index) => {
                    return (
                      <div className="recent-box" key={index?.id}>
                        {index?.avatar == "" ? (
                          <div
                            className="user-img"
                            style={{ background: index?.color }}
                          >
                            {index?.name?.charAt(0)?.toUpperCase()}
                          </div>
                        ) : (
                          <div className="user-img">
                            <img src={index?.avatar} />
                          </div>
                        )}
                        <div className="user-deatils">
                          <div className="name-bar">
                            <h6>
                              {index?.name?.charAt(0).toUpperCase() +
                                index?.name.slice(1)}
                            </h6>
                          </div>
                          <p className="email">{index?.email}</p>
                          <div className="user-log-time">
                            {" "}
                            <p>
                              <span>
                                <img src={clock} />
                              </span>{" "}
                              {moment(index?.joined_at).format("MMMM D, YYYY")}
                            </p>{" "}
                            <p>{moment(index?.joined_at).format("hh:mm A")}</p>{" "}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {recentinvestorlog?.length == 0 ? (
                  <div className="recent-box">
                    <div className="user-deatils">
                      <p className="email">No investor found</p>
                    </div>
                  </div>
                ) : (
                  " "
                )}
                <div className="heading-rt">
                  <h4>
                    Recent <br /> Fund Managers List
                  </h4>
                  <a onClick={(e) => navigate("/fund-manager-log")} href="#">
                    View all{" "}
                    <span className="arw-ico">
                      <img src={arrow} />
                    </span>
                  </a>
                </div>
                {recentmanagerlog?.length > 0 &&
                  recentmanagerlog?.slice(0, 2).map((index) => {
                    return (
                      <div className="recent-box" key={index?.id}>
                        {index?.avatar == "" ? (
                          <div
                            className="user-img"
                            style={{ background: index?.color }}
                          >
                            {index?.name?.charAt(0).toUpperCase()}
                          </div>
                        ) : (
                          <div className="user-img">
                            <img src={index?.avatar} />
                          </div>
                        )}
                        <div className="user-deatils">
                          <div className="name-bar">
                            <h6>
                              {index?.name?.charAt(0).toUpperCase() +
                                index?.name.slice(1)}
                            </h6>
                          </div>
                          <p className="email">{index?.email}</p>
                          <div className="user-log-time">
                            {" "}
                            <p>
                              <span>
                                <img src={clock} />
                              </span>{" "}
                              {moment(index?.joined_at).format("MMMM D, YYYY")}
                            </p>{" "}
                            <p>{moment(index?.joined_at).format("hh:mm A")}</p>{" "}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="recent-box">
              <div className="user-deatils">
                <p className="email">No Fund Manager found</p>
              </div>
            </div> 
            )}
          
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* right slide close */}

      {/* Create Company */}
      <CreateCompany
        modalShow={modalShow}
        setModalShow={setModalShow}
        setUpdateState={setUpdateState}
        setIsLoading={setIsLoading}
      />
      {/* End Company */}
    </div>
  );
};

export default Dashboard;
