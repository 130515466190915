import React, { useState } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InviteSendByID } from "../util/actions/companiesactions";

const AssignModel = ({ AssignShow, setAssignShow, id, setIsLoading }) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    id:id
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });
  toast.configure();

  console.log("iddddddd", id);

  /* Clear Model form */
  const clearModelform = () => {
    setValues({
      name: "",
      email: "",
    });
  };

  /* For cancel Click */
  const handleCancel = () => {
    setAssignShow(false);
    clearModelform();
  };

  /* For Assign fund manager mail send  */
  const handleSubmit = async (e) => {
    try {
      values.id = id;
   //   e.preventDefault();
      if (values.name === "" || values.email === "") {
        setErrors({
          ...errors,
          name: values?.name === "" ? "Please enter name" : errors.name,
          email: values?.email === "" ? "Please enter email" : errors.email,
        });
        return false;
      }
      let response = await InviteSendByID(id, values);
      console.log("res", response);
      setIsLoading(true);
      if (response.status === 200) {
        clearModelform(true);
        setIsLoading(false);
        toast.success("Email sent to user", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAssignShow(false);
        //setUpdateState(false);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
      let message = err
        ? err?.response?.data?.message
          : "Something went wrong during sent email.";
          setErrors({
            ...errors,
            name: message?.name?.length>0 ? message?.name.map((index)=>index) : " ",
            email:  message?.email?.length>0 ? message?.email.map((index)=>index): " "
          });

      return false;
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };

  return (
    <Modal
      show={AssignShow}
      onHide={() => setAssignShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>New Fund Manager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="create-popup">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Name
            </label>
            <input
              type="name"
              placeholder="Name"
              value={values.name}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  name: null,
                });
                setValues({ ...values, name: e.target.value });
              }}
              onKeyPress={handleKeypress}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email
            </label>
            <input
              className="email"
              placeholder="Email"
              value={values.email}
              onChange={(e) => {
                setValues({ ...values, email: e.target.value });
                setErrors({ ...errors, email: null });
              }}
              onKeyPress={handleKeypress}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={() => handleCancel()}
        >
          {" "}
          Cancel{" "}
        </Button>
        <Button variant="primary" className="save-btn" onClick={handleSubmit}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignModel;
