import React, { useState, useEffect } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { getdefaultsTemplatesAction } from "../util/actions/allactions";
import { importFundTemplateAction } from "../util/actions/fundactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const ChooseTemplate = ({
  chooseshow,
  setChooseshow,
  setUpdateState,
  updatestate,
  setIsLoading,
  importtemplate,
  setImporttemplate,
}) => {
  const [values, setValues] = useState({
    name: "",
    template_id: "",
  });
  const [errors, setErrors] = useState({
    name: "",
  });

  const [template, settemplate] = useState(false);

  const { id } = useParams();

  toast.configure();

  useEffect(() => {
    getdefaultsTemplates();
  }, []);

  console.log("templatetemplate", template);
  /* For Choose Template  */
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (values.name === "") {
        setErrors({
          ...errors,
          name: values?.name === "" ? "Please Select Template" : errors.name,
        });
        return false;
      }
      setIsLoading(true);
      let response = await importFundTemplateAction(id, values);
      console.log("importtemplate", response);
      if (response.status === 200) {
        setIsLoading(false);
        toast.success("Template Copied", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setImporttemplate(response?.data?.data?.template);
        setChooseshow(false);
        setUpdateState(true);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message?.name[0]
        : "Something went wrong during Fund Import";
      setIsLoading(false);
      setErrors({
        ...errors,
        name: message,
      });
      console.log("err", err?.message?.name[0]);
      return false;
    }
  };

  /* Get getdefaultsTemplates Response */
  const getdefaultsTemplates = async () => {
    try {
      setIsLoading(true);
      let response = await getdefaultsTemplatesAction();
      if (response.status == 200) {
        setIsLoading(false);
        console.log("Templates", response?.data?.data?.templates);
        settemplate(response?.data?.data?.templates);
        //setUpdateState(false);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong with funds";
      toast.error(message);
      return false;
    }
  };

  return (
    <Modal
      show={chooseshow}
      onHide={() => setChooseshow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Choose Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="create-popup">
          <div className="mb-3">
            <Form.Select
              aria-label="Default select example"
              className="form-control"
              id={values.template_id}
              value={values.name}
              onChange={(e) => {
                setValues({
                  ...values,
                  name: e.target.value,
                  template_id: e.target.value,
                });
                setErrors({ ...errors, name: null });
              }}
            >
              <option>Select Template</option>
              {template.length > 0 &&
                template.map((index) => {
                  return (
                    <option key={index?._id} value={index?._id} id={index?._id}>
                      {index?.name}
                    </option>
                  );
                })}
            </Form.Select>
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={() => {
            setChooseshow(false);
            setErrors({
              ...errors,
              name: null,
            });
          }}
        >
          {" "}
          Cancel{" "}
        </Button>
        <Button variant="primary" className="save-btn" onClick={handleSubmit}>
          Copy Template
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChooseTemplate;
