import React, { useState, useEffect } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserAddCompaniesAction } from "../util/actions/companiesactions";
import {
  getUserAddFundsAction,
  getUserAddTemplateAction,
  addFundInvestorAction,
} from "../util/actions/fundactions";

const CreateInvestor = ({
  newinvestorshow,
  setNewinvestorshow,
  setIsLoading,
  companies
}) => {
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  // const [companies, setCompanies] = useState("");
  const [funds, setFunds] = useState("");
  const [template, setTemplate] = useState("");
  const [values, setValues] = useState({
    name: "",
    email: "",
    company_id: "",
    fund_id: "",
    template_id: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    company_id: "",
    fund_id: "",
    template_id: "",
  });
  toast.configure();

  // useEffect(() => {
  //   if (UserRole == "super-admin") {
  //     getCompanies();
  //   }
  // }, []);

  /* Clear Model form */
  const clearModelform = () => {
    setValues({
      name: "",
      email: "",
      company_id: "",
      fund_id: "",
      template_id: "",
    });
  };

  /* For Cancel Click */
  const handleCancel = () => {
    setNewinvestorshow(false);
    clearModelform();
    setErrors({
      ...errors,
      name: null,
      email: null,
      company_id: null,
      fund_id: null,
      template_id: null,
    });
  };

 

  /* get Funds */
  const getFunds = async (e) => {
    try {
      setIsLoading(true);
      let response = await getUserAddFundsAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setFunds(response?.data?.data?.funds);
      }
    } catch (err) {
      setIsLoading(false);
      return false;
    }
  };

  /* get Templates */
  const getTemplates = async (e) => {
    try {
      setIsLoading(true);
      let response = await getUserAddTemplateAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setTemplate(response?.data?.data?.templates);
      }
    } catch (err) {
      setIsLoading(false);
      return false;
    }
  };

  /* For add Fund investor  */
  const handleSubmit = async (e) => {
    try {
      if (
        values?.name === "" ||
        values?.email === "" ||
        values?.company_id == "" ||
        values?.fund_id == "" ||
        values?.template_id == ""
      ) {
        setErrors({
          ...errors,
          name:
            values?.name?.trim() === "" ? "Please enter name" : errors?.name,
          email:
            values?.email?.trim() === "" ? "Please enter email" : errors?.email,
          company_id:
            values?.company_id == ""
              ? "Please select company"
              : errors?.company_id,
          fund_id:
            values?.fund_id == "" ? "Please select fund" : errors?.fund_id,
          template_id:
            values?.template_id == ""
              ? "Please select template"
              : errors?.template_id,
        });
        return false;
      }
      let response = await addFundInvestorAction(values);
      setIsLoading(true);
      if (response.status === 200) {
        clearModelform(true);
        setIsLoading(false);
        toast.success("Email sent Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNewinvestorshow(false);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during sent email.";
      setErrors({
        ...errors,
        name:
          message?.name?.length > 0 ? message?.name.map((index) => index) : " ",
        email:
          message?.email?.length > 0
            ? message?.email.map((index) => index)
            : " ",
        company_id:
          message?.company_id?.length > 0
            ? message?.company_id.map((index) => index)
            : " ",
        fund_id:
          message?.fund_id?.length > 0
            ? message?.fund_id.map((index) => index)
            : " ",
        template_id:
          message?.template_id?.length > 0
            ? message?.template_id.map((index) => index)
            : " ",
      });

      return false;
    }
  };

  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };

  return (
    <>
      <Modal
        show={newinvestorshow}
        onHide={() => setNewinvestorshow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>New Investor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input
                type="name"
                placeholder="Name"
                value={values.name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: null,
                  });
                  setValues({ ...values, name: e.target.value });
                }}
                onKeyPress={handleKeypress}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input
                className="email"
                placeholder="Email"
                value={values.email}
                onChange={(e) => {
                  setValues({ ...values, email: e.target.value });
                  setErrors({ ...errors, email: null });
                }}
                onKeyPress={handleKeypress}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Company
              </label>
              <select
                value={values?.company_id}
                id={values?.company_id}
                onChange={(e) => {
                  setValues({ ...values, company_id: e.target.value });
                  getFunds(e.target.value);
                  setErrors({
                    ...errors,
                    company_id: null,
                  });
                }}
                onKeyPress={handleKeypress}
              >
                <option value="select company">Select Company</option>
                {companies?.length > 0 &&
                  companies?.map((item, index) => {
                    return (
                      <option key={item?._id} value={item?._id} id={item?._id}>
                        {item?.name}{" "}
                      </option>
                    );
                  })}
              </select>
              {errors.company_id && (
                <span className="error">{errors.company_id}</span>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Funds
              </label>
              <select
                value={values?.fund_id}
                id={values?.fund_id}
                onChange={(e) => {
                  setValues({ ...values, fund_id: e.target.value });
                  getTemplates(e.target.value);
                  setErrors({
                    ...errors,
                    fund_id: null,
                  });
                }}
                onKeyPress={handleKeypress}
              >
                <option value="select fund">Select Fund</option>
                {funds?.length > 0 &&
                  funds?.map((item, index) => {
                    return (
                      <option key={item?._id} value={item?._id} id={item?._id}>
                        {item?.name}{" "}
                      </option>
                    );
                  })}
              </select>
              {errors.fund_id && (
                <span className="error">{errors.fund_id}</span>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Template
              </label>
              <select
                value={values?.template_id}
                id={values?.template_id}
                onChange={(e) => {
                  setValues({ ...values, template_id: e.target.value });
                  setErrors({
                    ...errors,
                    template_id: null,
                  });
                }}
                onKeyPress={handleKeypress}
              >
                <option>Select Template</option>
                {template?.length > 0 &&
                  template?.map((item, index) => {
                    return (
                      <option key={item?._id} value={item?._id} id={item?._id}>
                        {item?.name}{" "}
                      </option>
                    );
                  })}
              </select>
              {errors.template_id && (
                <span className="error">{errors.template_id}</span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={(e) => handleCancel(e)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button variant="primary" className="save-btn" onClick={handleSubmit}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateInvestor;
