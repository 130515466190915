import React, { useState, useEffect , useMemo} from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getUserAddCompaniesAction,
  InviteSendByID,
} from "../util/actions/companiesactions";

const CreateFundManager = ({
  newmangershow,
  setNewmangershow,
  setIsLoading,
  isLoading,
  companies
}) => {
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  // const [companies, setCompanies] = useState("");
  const [cid, setid] = useState();
  const [values, setValues] = useState({
    name: "",
    email: "",
    id: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    id: "",
  });
  toast.configure();

  // useEffect(() => {
  //   if (UserRole == "super-admin") {
  //     getCompanies();
  //   }
  // }, []);

  /* Clear Model form */
  const clearModelform = () => {
    setValues({
      name: "",
      email: "",
      id: "",
    });
  };

  /* For cancel Click */
  const handleCancel = () => {
    setNewmangershow(false);
    clearModelform();
    setErrors({
      ...errors,
      name: null,
      email: null,
      id: null,
    });
  };

  /* For Assign fund manager mail send  */
  const handleSubmit = async (e) => {
    try {
      if (values?.name === "" || values?.email === "" || values?.id == "") {
        setErrors({
          ...errors,
          name: values?.name?.trim() === "" ? "Please enter name" : errors?.name,
          email: values?.email?.trim() === "" ? "Please enter email" : errors?.email,
          id: values?.id == "" ? "Please select company" : errors?.id,
        });
        return false;
      }
      setIsLoading(true);
      let id = values?.id;
      let response = await InviteSendByID(id, values);
      if (response.status === 200) {
        clearModelform(true);
        setIsLoading(false);
        toast.success("Email sent to Fund Manager", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNewmangershow(false);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during sent email.";
      setErrors({
        ...errors,
        name:
          message?.name?.length > 0 ? message?.name.map((index) => index) : " ",
        email:
          message?.email?.length > 0
            ? message?.email.map((index) => index)
            : " ",
      });

      return false;
    }
  };

  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };

  // /* get Companies */
  // const getCompanies = async (e) => {
  //   try {
  //     setIsLoading(true);
  //     let response = await getUserAddCompaniesAction();
  //     if (response.status == 200) {
  //       setIsLoading(false);
  //       setCompanies(response?.data?.data?.companies);
  //     }
  //   } catch (err) {
  //     setIsLoading(false);
  //     let message =
  //       err && err?.message
  //         ? err?.message
  //         : "Something went wrong in companies.";
  //     return false;
  //   }
  // };

  return (
    <>
      <Modal
        show={newmangershow}
        onHide={() => setNewmangershow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>New Fund Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input
                type="name"
                placeholder="Name"
                value={values.name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: null,
                  });
                  setValues({ ...values, name: e.target.value });
                }}
                onKeyPress={handleKeypress}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input
                className="email"
                placeholder="Email"
                value={values.email}
                onChange={(e) => {
                  setValues({ ...values, email: e.target.value });
                  setErrors({ ...errors, email: null });
                }}
                onKeyPress={handleKeypress}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select Company
              </label>
              <select
                value={values?.id}
                id={values?.id}
                onChange={(e) => {
                  setValues({ ...values, id: e.target.value });
                  setErrors({
                    ...errors,
                    id: null,
                  });
                }}
                onKeyPress={handleKeypress}
              >
                <option value="select company">Select Company</option>
                {companies?.length > 0 &&
                  companies?.map((item, index) => {
                    return (
                      <option key={item?._id} value={item?._id} id={item?._id}>
                        {item?.name}{" "}
                      </option>
                    );
                  })}
              </select>
              {errors?.id && <span className="error">{errors?.id}</span>}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={(e) => handleCancel(e)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button variant="primary" className="save-btn" onClick={handleSubmit}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateFundManager;
