import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Splash from "./splash";
import SignIn from "./signin";
import InviteSignin from "./invite-signin";
import ForgotPassword from "./forgot-password.js";
import NewPassword from "./new-password.js";
import Dashboard from "./dashboard.js";
import FundListing from "./fund-listing.js";
import FundManagement from "./fund-management-list.js";
import FundDetails from "./fund-details.js";
import FundManagementDetails from "./fund-management-details.js";
import NewTemplates from "./import-templates.js";
import UserManagament from "./user-management";
import FundManagerLog from "./fund-manager-log";
import InvestorLog from "./investor-log";
import FundEdit from "./fund-edit";
import CompanyList from "./company-list";
import Templates from "./templates";
import CreateTemplate from "./create-template";
import CompanyDetail from "./company-detail";
import { getAuth, ProtectedRoute ,PublicRoute , InvestorRoute} from "./Routes/Routes";
import SignupFundmanager from "./signupfundmanager.js";
import Signup from "./signup.js";
import TemplateDetail from './Fund-template-detail';
import SignupInvestor from "./signupinvestor.js";
import AddInvestor from "./addinvestor.js";
import AdminTemplateDetail from './admintemplate-detail';
import JoinFund from './join-fund';
import Share from './share';
import Settings from './settings';
// import EditTemplate from './Edit-Template-Details(notUsing)';


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/splash" element={<Splash />}></Route>
      <Route exact path="/" element={<PublicRoute />}>
      <Route path="/" element={<SignIn />}></Route>
      <Route path="/invite-signin" element={<InviteSignin />}></Route>
      <Route path='*' exact={true} element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      
      </Route>
      <Route path="/new-password/:token" element={<NewPassword />}></Route>
      <Route path="/signupfundmanager/:token" element={<SignupFundmanager />}></Route>   
      <Route path="/signupinvestor/:token" element={<SignupInvestor />}></Route>
      <Route path="/addinvestor/:token" element={<AddInvestor />}></Route>
      <Route path="/signup/:token" element={<Signup />}></Route>
      <Route path="/join-fund/:token" element={<JoinFund />}></Route>
      <Route path="/share/:token" element={<Share />}></Route>
      
      <Route exact path="/" element={<ProtectedRoute />}>
        <Route exact path="/dashboard" element={<Dashboard />}></Route>
        <Route path='*' exact={true} element={<Dashboard />} />
      </Route>
      <Route exact path="/" element={<ProtectedRoute />}>
        <Route path="/fund-listing" element={<FundListing />}></Route>
        <Route path="/fund-management-list" element={<FundManagement />}></Route>
        <Route path="/fund-details/:id" element={<FundDetails />}></Route>
        <Route path="/fund-management-details/:id" element={<FundManagementDetails />}></Route>
        <Route path="/fund-template-detail/:id" element={<TemplateDetail />}></Route>
        <Route path="/import-templates" element={<NewTemplates />}></Route>
        <Route path="/user-management" element={<UserManagament />}></Route>
        <Route path="/fund-manager-log" element={<FundManagerLog />}></Route>
        <Route path="/investor-log" element={<InvestorLog />}></Route>
        <Route path="/fund-edit" element={<FundEdit />}></Route>
        <Route path="/company-list" element={<CompanyList />}></Route>
        <Route path="/templates" element={<Templates />}></Route>
        <Route path="/company-detail/:id" element={<CompanyDetail />}></Route>
        <Route path="/settings" element={<Settings />}></Route>
        <Route path="/admintemplate-detail/:id" element={<AdminTemplateDetail />}></Route>
        <Route path="/create-template" element={<CreateTemplate />}></Route>
        <Route path="/editTemplate-detail/:id" element={<CreateTemplate />}></Route>
        <Route path="/fund-management-create-Template/:fundId" element={<CreateTemplate />}></Route>
        <Route path="/fund-management-update-Template/:id/:fundId" element={<CreateTemplate />}></Route>
        
        {/* <Route path="/editTemplate-detail/:id" element={<EditTemplate />}></Route> */}
      </Route> 
     
    </Routes>
    </BrowserRouter>
  );
}

export default App;
