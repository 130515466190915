import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import signupimg from "./images/sign-up_right.svg";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordAction } from "./util/actions/loginaction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./spinner";

const NewPassword = () => {
  toast.configure();
  const [values, setValues] = useState({
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = React.useState({
    password: null,
    password_confirmation: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  /*  Get token  */
  var { token } = useParams();
  const navigate = useNavigate();
  {
    /* change password onclick*/
  }
  const handlechangepassword = async (e) => {
    try {
      e.preventDefault();
      if (
        values.password === "" ||
        values.password_confirmation === "" ||
        values.password !== values.password_confirmation
      ) {
        setErrors({
          ...errors,
          password:
            values.password === ""
              ? "Please enter new password"
              : errors.password,
          password_confirmation:
            values.password_confirmation === ""
              ? "Please enter confirm password"
              : values.password_confirmation !== values.password
              ? "Password does not match"
              : errors?.password_confirmation,
        });
        return false;
      }
      setIsLoading(true);
      let response = await resetPasswordAction(values, token);
      console.log("resetpassword", response);

      if (response.status === 200) {
        setIsLoading(false);
        toast.success("Password Reset Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/", { replace: true });
      }
      return false;
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message
        : "Something went wrong during reset";
      console.log("resetconsoleee", err?.response?.data?.message);
      setErrors({
        ...errors,
        password:
          message?.password?.length > 0
            ? message?.password.map((index) => index)
            : " ",
        password_confirmation:
          message?.password_confirmation?.length > 0
            ? message?.password_confirmation.map((index) => index)
            : " ",
      });
      return;
    }
  };
  return (
    <div className="splashmain">
      {isLoading ? <Spinner /> : ""}
      <div className="container">
        <div className="row align-items-center h-100">
          <div className="col-md-6">
            <div className="login-formleft">
              <div className="form-heading">
                <span>Choose New Password</span>
              </div>
              <form className="loginfromm">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter new password"
                    value={values.password}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        password: null,
                      });
                      setValues({ ...values, password: e.target.value });
                    }}
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Confirm new password"
                    value={values.password_confirmation}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        password_confirmation: null,
                      });
                      setValues({
                        ...values,
                        password_confirmation: e.target.value,
                      });
                    }}
                  />
                  {errors.password_confirmation && (
                    <span className="error">
                      {errors.password_confirmation}
                    </span>
                  )}
                </div>

                <a onClick={handlechangepassword} className="blue-btn w-100">
                  Update Password
                </a>
                {/* <a href='#' className="light-btn w-100 mt-3">Back</a> */}
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-picbx">
              <img src={signupimg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
