import React, { useState , useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import signupimg from "./images/sign-up_right.svg";
import { useNavigate, useParams } from "react-router-dom";
import { loginAction , ValidateCheckFundmangerAction} from "./util/actions/loginaction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ManagerInviteRegistration } from "./util/actions/companiesactions";
import Spinner from "./spinner";

const SignupFundmanager = () => {
  const [isReadOnly, setisReadOnly] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  toast.configure();
  const { token } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    ValiadteUser();
  }, []);
  const ValiadteUser = async () => {
    try {
      setIsLoading(true);
      let response = await ValidateCheckFundmangerAction(token);
      console.log("loginres", response);

      if (response.status == 200) {
        setIsLoading(false);
        setisReadOnly(true);
        setValues({
          ...values,
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
        });
        return;
      }
    } catch (err) {
      // console.log("sadasd", err.response.data.message);
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        values.name === "" ||
        values.email === "" ||
        values.password === "" ||
        values.password_confirmation === ""
      ) {
        setErrors({
          ...errors,
          name: values.name === "" ? "Please enter name" : errors.name,
          email:
            values.email === ""
              ? "Please enter username or email"
              : errors.email,
          password:
            values.password === ""
              ? "Please enter password"
              : values.password.length < 8
              ? "Password must be 8 Charcter"
              : errors.password,
          password_confirmation:
            values.password_confirmation === ""
              ? "Please enter confirm password"
              : values.password_confirmation !== values.password
              ? "Password does not match"
              : errors?.password_confirmation,
        });
        return false;
      }
      setIsLoading(true);
      let response = await ManagerInviteRegistration(values, token);
      console.log("registrationres", response);
      if (response.status == 200) {
        setIsLoading(false);
        toast.success("Register Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/");
        return;
      }
    } catch (err) {
      setIsLoading(false);
      let message =  err
      ? err?.response?.data?.message
        : "Something went wrong during reset";
      console.log("resetconsoleee", err?.response?.data?.message);
      setErrors({
        ...errors,
        name:
          message?.name?.length > 0 && message?.name[0],
        email:
          message?.email?.length > 0
            && message?.email[0],
        password:
          message?.password?.length > 0
            && message?.password[0],
        password_confirmation:
          message?.password_confirmation?.length > 0
            && message?.password_confirmation[0],
      });
    }
  };

  return (
    <div className="splashmain">
      {isLoading ? <Spinner /> : ""}
      <div className="container">
        <div className="row align-items-center h-100">
          <div className="col-md-6">
            <div className="login-formleft">
              <div className="form-heading">
                <span>Sign Up</span>
              </div>
              <form className="loginfromm">
                <div className="mb-3">
                  <label htmlFor="exampleInputName" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputname"
                    aria-describedby="emailHelp"
                    placeholder="Enter Name "
                    value={values.name}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        name: null,
                      });
                      setValues({ ...values, name: e.target.value });
                    }}
                    readOnly={isReadOnly}
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email address"
                    value={values.email}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        email: null,
                      });
                      setValues({ ...values, email: e.target.value });
                      
                    }}
                    readOnly={isReadOnly}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter password"
                    value={values.password}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        password: null,
                      });
                      setValues({ ...values, password: e.target.value });
                    }}
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter Confirm password"
                    value={values.password_confirmation}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        password_confirmation: null,
                      });
                      setValues({
                        ...values,
                        password_confirmation: e.target.value,
                      });
                    }}
                  />
                  {errors.password_confirmation && (
                    <span className="error">
                      {errors.password_confirmation}
                    </span>
                  )}
                </div>

                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="blue-btn w-100"
                >
                  Sign Up
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-picbx">
              <img src={signupimg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFundmanager;
