import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ValidateCheckAction } from "./util/actions/loginaction";
import Spinner from "./spinner";
import swal from "sweetalert";

const JoinFund = () => {
  var { token } = useParams();
  const navigate = useNavigate();
  toast.configure();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ValiadteUser();
  }, []);
  const ValiadteUser = async () => {
    try {
      setIsLoading(true);
      let response = await ValidateCheckAction(token);
      console.log("loginres", response);

      if (response.status == 200) {
        setIsLoading(false);
        swal({
          title: "Congratulations!",
          text: "you have joined the fund successfully.",
          type: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard");
        });
        return;
      }
    } catch (err) {
      // console.log("sadasd", err.response.data.message);
      setIsLoading(false);
    }
  };
  return (
    <div className="App">
      <div className="splashmain">
        {isLoading ? <Spinner /> : ""}
        <div className="logobx">
          <img src={logo} />
        </div>
      </div>
    </div>
  );
};

export default JoinFund;
