import React, { useState } from "react";
import "../App.css";
import "../style.scss";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DeleteUserAction,
  DeleteTemplateAction,
} from "../util/actions/allactions";
import { useParams } from "react-router-dom";

const DeleteModel = ({
  DeleteShow,
  setDeleteShow,
  id,
  cid,
  setUpdateState,
  DeleteFund,
  Companyid,
  setIsLoading,
  isLoading,
}) => {
  const DeletebuttonFunction = (e) => {
    // setIsLoading(true);
    DeleteFund(e);
  };
  console.log("Companyid", Companyid);
  /* For Delete Fund  */
  const [disable, setdisable] = useState(true);
  const DeleteUser = async () => {
    try {
      setIsLoading(true);
      let response = await DeleteUserAction(id);
      if (response.status === 200) {
        setIsLoading(false);
        console.log("delete", response);
        toast.success("Deleted", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setUpdateState(true);
      setDeleteShow(false);
    } catch (err) {
      setIsLoading(false);
      let message = err
        ? err?.response?.data?.message?.name[0]
        : "Something went wrong during delete";
      toast.error(message);

      return false;
    }
  };
  return (
    <>
      <Modal
        show={DeleteShow}
        onHide={() => setDeleteShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="create-popup">
            <div className="mb-3">
              <p>Are you sure want to delete</p>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={() => setDeleteShow(false)}
          >
            {" "}
            Cancel{" "}
          </Button>

          {console.log("id", id, "companyid", Companyid, "cid", cid)}
          {Companyid || cid ? (
            <Button
              variant="primary"
              className="save-btn"
              onClick={(e) => DeletebuttonFunction(e)}
              // disabled={true}
            >
              Delete
            </Button>
          ) : (
            <Button
              variant="primary"
              className="save-btn user"
              onClick={(e) => DeleteUser(e)}
            >
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModel;
