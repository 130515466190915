import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import './App.css';
import logo from "./images/logo.svg";

const Splash =()=>{
return (
    <div className="App">
    <div className='splashmain'>
           <div className='logobx'>
              <img src={logo}/>
           </div>
    </div>
</div>
)

};

export default Splash
