import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import dashico from "./images/dashicon.svg";
import reportico from "./images/report.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import help from "./images/help.svg";
import notify from "./images/notify.svg";
import settings from "./images/settings.svg";
import logout from "./images/logout.svg";
import arrow from "./images/arrow.svg";
import user from "./images/user-img.png";
import dots from "./images/dots.svg";
import search from "./images/search.svg";
import clock from "./images/clock.svg";
import download from "./images/download.svg";
import ufund from "./images/user-fund.png";
import usetting from "./images/user-settings.png";
import back from "./images/back-ico.svg";
import edit from "./images/edit-ico.svg";
import archive from "./images/archive.svg";
import drop from "./images/drop.svg";
import duplicate from "./images/duplicate.svg";
import trash from "./images/trash.svg";
import assign from "./images/assign.svg";
import raccess from "./images/r-access.svg";
import template from "./images/template.svg";
import plus from "./images/plus.svg";
import {
  Dropdown,
  Table,
  Modal,
  Form,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import {
  UserManagementAction,
  UsersSearchAction,
} from "./util/actions/allactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import Spinner from "./spinner";
import DeleteModel from "./Model/DeleteModel";
import CreateFundManager from "./Model/createfundmanger";
import AssignAccessModel from "./Model/AssignAccessModel";
import RemoveAccessModel from "./Model/RemoveAccessModel";
import CreateInvestor from "./Model/createinvestor";
import Pagination from "react-js-pagination";
import { getFundsInfoAction } from "./util/actions/fundactions";
import { getUserAddCompaniesAction } from "./util/actions/companiesactions";

const UserManagament = () => {
  const navigate = useNavigate();
  toast.configure();
  const UserRole = JSON.parse(localStorage.getItem("UserRole"));
  const [fundmanager, setfundmanager] = useState("");
  const [newmangershow, setNewmangershow] = useState(false);
  const [newinvestorshow, setNewinvestorshow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [AssignShow, setAssignShow] = useState(false);
  const [RemoveShow, setRemoveShow] = useState(false);
  const [DeleteShow, setDeleteShow] = useState(false);
  const [updatestate, setUpdateState] = useState(false);
  const [companies, setCompanies] = useState("");
  // const [investorcompanies, setInvestorCompanies] = useState("");

  const [id, setid] = useState("");
  const [funds, setFunds] = useState("");
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (UserRole == "super-admin" || UserRole == "fund-manager") {
      Userslist();
    }
  }, [updatestate]);

  // useEffect(() => {
  //   if (UserRole == "super-admin") {
  //     getManagerCompanies();
  //   }
  // }, []);

  /* Set Delete Model */
  const setDeleteModel = (e, id) => {
    setDeleteShow(true);
    setid(id);
  };

  /* Set Assign Access Model */
  const setAssignAccessModel = (e, id) => {
    setid(id);
    setAssignShow(true);
    getFunds(id);
  };

  /* Set Remove Access Model */
  const setRemoveAccessModel = (e, id) => {
    setRemoveShow(true);
    setid(id);
    getFunds(id);
  };

  /* get Funds */
  const getFunds = async (e) => {
    try {
      setIsLoading(true);
      let response = await getFundsInfoAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setFunds(response?.data?.data?.funds);
      }
    } catch (err) {
      setIsLoading(false);
      return false;
    }
  };

  /* get users log */
  const Userslist = async (e) => {
    try {
      setIsLoading(true);
      let response = await UserManagementAction(e);
      if (response.status == 200) {
        setIsLoading(false);
        setUsers(response?.data?.data?.users);
        console.log("response", response?.data?.data?.users?.data);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      toast.error(message);
      return false;
    }
  };

  /* For Search */
  const inputHandler = async (e) => {
    try {
      var searchval = e.target.value;
      let response = await UsersSearchAction(searchval);
      if (response.status == 200) {
        setUsers(response?.data?.data?.users);
      }
    } catch {
      return false;
    }
  };

  /* get Companies */
  const getCompanies = async (e) => {
    try {
      setIsLoading(true);
      let response = await getUserAddCompaniesAction();
      if (response.status == 200) {
        setIsLoading(false);
        setCompanies(response?.data?.data?.companies);
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err?.message
          ? err?.message
          : "Something went wrong in companies.";
      return false;
    }
  };

  //  /* get Companies */
  //  const getInvestorCompanies = async (e) => {
  //   try {
  //     setIsLoading(true);
  //     let response = await getUserAddCompaniesAction();
  //     if (response.status == 200) {
  //       setIsLoading(false);
  //       setInvestorCompanies(response?.data?.data?.companies);
  //     }
  //   } catch (err) {
  //     setIsLoading(false);
  //     return false;
  //   }
  // };
  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content ds-content-two">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar import-temp-top">
            <div className="sr-lft">
              <Button className="left-menu-btn" onClick={handleShow}>
                <span className="m-line"></span>
              </Button>
              <div className="search-box">
                <input
                  type="text"
                  className="sr-bar"
                  placeholder="Search"
                  onChange={(e) => inputHandler(e)}
                />
                <button>
                  <img src={search} />
                </button>
              </div>
            </div>

            <RightHeader setIsLoading={setIsLoading} />
            <RightMobileHeader setIsLoading={setIsLoading} />
          </div>
          <div className="ds-heading">
            {" "}
            <h2> User Managament</h2>
            {UserRole == "super-admin" ? (
              <div className="right-bx">
                <a
                  className="share-btn"
                  onClick={() => {
                    return (
                      setNewmangershow(true),
                      UserRole == "super-admin" ? getCompanies() : " "
                    );
                  }}
                  href="javascript:void(0)"
                >
                  <span>
                    <img src={plus} />
                  </span>{" "}
                  New Fund Manager
                </a>
                <a
                  className="share-btn"
                  // onClick={() => setNewinvestorshow(true)}
                  onClick={() => {
                    return (
                      setNewinvestorshow(true),
                      UserRole == "super-admin" ? getCompanies() : " "
                    );
                  }}
                  href="javascript:void(0)"
                >
                  <span>
                    <img src={plus} />
                  </span>{" "}
                  New Investor
                </a>
              </div>
            ) : (
              " "
            )}
          </div>
          <div className="dashboard-box inner-pages-bx">
            <div className="search-box srch-mobile">
              <input
                type="text"
                className="sr-bar"
                placeholder="Search"
                onChange={(e) => inputHandler(e)}
              />
              <button>
                <img src={search} />
              </button>
            </div>

            <div className="fund-listing-main">
              <div className="data-activity">
                <Table responsive="xl">
                  <thead>
                    <tr>
                      <th className="th-one">Profile</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>User Role</th>
                      <th>Company</th>
                      <th>Date</th>
                      {UserRole == "super-admin" ? (
                        <th className="th-two text-center">Action</th>
                      ) : (
                        " "
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {users?.data?.length > 0 &&
                      users?.data?.map((index) => {
                        return (
                          <tr key={index?._id}>
                            <td>
                              {index?.avatar ? (
                                <div className="inv-name-image">
                                  <img src={index?.avatar} />
                                </div>
                              ) : (
                                <div className="inv-name">
                                  {" "}
                                  <span
                                    className="name-bx "
                                    style={{ background: index?.color }}
                                  >
                                    {" "}
                                    {index?.name?.charAt(0)?.toUpperCase()}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              {index?.name?.charAt(0).toUpperCase() +
                                index?.name.slice(1)}
                            </td>
                            <td>{index?.email}</td>
                            <td>
                              {index?.user_role == "Fund Manager"
                                ? "Fund Manager"
                                : "Investor"}
                            </td>
                            <td>
                              {index?.company_name ? index?.company_name : " "}
                            </td>
                            <td>
                              <div className="ac-date">
                                {moment(index?.created_at).format(
                                  "MMMM D, YYYY"
                                )}
                              </div>
                            </td>
                            {UserRole == "super-admin" ? (
                              <td className="t-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {" "}
                                    <img src={dots} />{" "}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {index?.user_role !== "investor" ? (
                                      <>
                                        {UserRole == "super-admin" ||
                                        UserRole == "fund-manager" ? (
                                          index?.user_role !==
                                          "Fund Investor" ? (
                                            <>
                                              <Dropdown.Item
                                                href="javascript:void(0)"
                                                onClick={(e) =>
                                                  setAssignAccessModel(
                                                    e,
                                                    index?._id
                                                  )
                                                }
                                              >
                                                <span className="ico-drop">
                                                  <img src={assign} />
                                                </span>{" "}
                                                Assign Access
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="javascript:void(0)"
                                                onClick={(e) =>
                                                  setRemoveAccessModel(
                                                    e,
                                                    index?._id
                                                  )
                                                }
                                              >
                                                <span className="ico-drop">
                                                  <img src={raccess} />
                                                </span>{" "}
                                                Remove Access
                                              </Dropdown.Item>
                                            </>
                                          ) : (
                                            " "
                                          )
                                        ) : (
                                          " "
                                        )}
                                      </>
                                    ) : (
                                      " "
                                    )}

                                    {UserRole == "super-admin" ? (
                                      <Dropdown.Item
                                        onClick={(e) =>
                                          setDeleteModel(e, index?._id)
                                        }
                                      >
                                        <span className="ico-drop">
                                          <img src={trash} />
                                        </span>{" "}
                                        Delete
                                      </Dropdown.Item>
                                    ) : (
                                      " "
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            ) : (
                              " "
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {users?.total == 0 ? (
                  <div className="nofound">No data found</div>
                ) : (
                  " "
                )}
                {users.total > 0 ? (
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={users?.current_page}
                    itemsCountPerPage={users.per_page}
                    totalItemsCount={users.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => Userslist(e)}
                    innerClass="pagination justify-content-end"
                    prevPageText="Prev"
                    nextPageText="Next"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
      </div>

      {/* create fund manager */}

      <CreateFundManager
        newmangershow={newmangershow}
        setNewmangershow={setNewmangershow}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        companies={companies}
      />
      {/* create fund manager close */}

      {/* create Investor */}

      <CreateInvestor
        newinvestorshow={newinvestorshow}
        setNewinvestorshow={setNewinvestorshow}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        companies={companies}
      />
      {/* create Investor close */}

      {/* Assign popup */}

      <AssignAccessModel
        AssignShow={AssignShow}
        setAssignShow={setAssignShow}
        isLoading={isLoading}
        id={id}
        funds={funds}
        setIsLoading={setIsLoading}
      />

      {/* Assign popup close */}

      {/* Remove popup */}
      <RemoveAccessModel
        RemoveShow={RemoveShow}
        setRemoveShow={setRemoveShow}
        isLoading={isLoading}
        id={id}
        funds={funds}
        setIsLoading={setIsLoading}
      />

      {/* Assign popup close */}

      {/* Delete popup */}

      {/* Delete popup */}

      <DeleteModel
        DeleteShow={DeleteShow}
        setDeleteShow={setDeleteShow}
        id={id}
        setUpdateState={setUpdateState}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />

      {/* Delete popup close */}

      {/* Delete popup close */}

      {/* left-sidebar */}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </Offcanvas.Body>
      </Offcanvas>

      {/* left-sidebar close */}
    </div>
  );
};

export default UserManagament;
