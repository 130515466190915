import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const defaultPosition = toast.POSITION.BOTTOM_CENTER;

toast.configure();
export const showToast = ( type = "success", msg, autoClose = 500, className = "primaryColor", position = defaultPosition ) => {
    if (type === "success") {
      toast.success(msg, {
        autoClose: autoClose === null ? 500 : autoClose,
        className: className === null ? "successColor" : className,
        position: position,
      });
    } else if (type === "error") {
      toast.error(msg, {
        autoClose: autoClose === null ? 500 : autoClose,
        className: className === null ? "dangerColor" : className,
        position: position,
      });
    }
  };

export default showToast;
