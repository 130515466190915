import React, { useEffect, useState } from "react";
import { Link, useParams ,useNavigate} from "react-router-dom";
import "./App.css";
import lefttop from "./images/l-top.svg";
import leftbottom from "./images/l-bottom.svg";
import rttop from "./images/r-top.svg";
import rtbtm from "./images/r-btm.svg";
import search from "./images/search.svg";
import TemplateView from "./components/TemplateView_new";
import Spinner from "./spinner";
import Sidebar from "./sidebar";
import "react-toastify/dist/ReactToastify.css";
import RightHeader from "./components/rightHeader";
import RightMobileHeader from "./components/rightMobileHeader";
import { getTemplateQuestionAnswer } from "./util/actions/allactions";
import back from "./images/back-ico.svg";

const CreateTemplate = () => {
  const [questionAnswerArray, setQuestionAnswerArray] = useState([]);
  const [templatename, setemplatename] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  console.log(id, "iddddddddddddddddd");
const navigate=useNavigate()
  useEffect(async () => {
    try {
      setIsLoading(true);
      const templateData = await getTemplateQuestionAnswer(id);
      let data = templateData?.data?.data?.template?.content;
      console.log(data, "specific Data");
      if (data) {
        data && setQuestionAnswerArray(data.QuestionAns);
        setemplatename(templateData?.data?.data?.template?.name);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, []);
  return (
    <div className="dashboard-main">
      {isLoading ? <Spinner /> : ""}
      <div className="dash-container">
        <div className="left-bar">
          {/* sidee bar */}

          <Sidebar></Sidebar>

          {/* sidebar end */}
        </div>
        <div className="dashboard-content ds-content-two">
          <div className="left-top">
            <img src={lefttop} />
          </div>
          <div className="left-bottom">
            <img src={leftbottom} />
          </div>

          <div className="search-bar import-temp-top">
            <div className="sr-lft"></div>
            <RightHeader setIsLoading={setIsLoading}/>
            <RightMobileHeader setIsLoading={setIsLoading}/>
          </div>

          <div className="ds-heading">
              <h2> <a className="back-btn" onClick={(e)=>  navigate('/templates') &&  e.preventDefault() }>
                  <img src={back} />
                </a>{" "}{templatename}</h2>
              </div>

          <div className="dashboard-box edit-temp-bx inner-pages-bx">
            {/* <div className="search-box srch-mobile">
              <input type="text" className="sr-bar" placeholder="Search" />
              <button>
                <img src={search} />
              </button>
            </div> */}
            <div className="create-template-bx">
              <div className="create-box-in template-dv">
                <div className="tamplate-name">
                  {" "}
                 
                </div>
                <TemplateView questionAnswerArray={questionAnswerArray} />
              </div>
            </div>
          </div>
          <div className="right-top">
            <img src={rttop} />
          </div>
          <div className="right-bottom">
            <img src={rtbtm} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTemplate;
